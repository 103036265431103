import { Slide, toast } from "react-toastify";
import { EnviaDatosAServicio } from "../../Funciones/Funciones";
import classes from "./LlaveItem.module.css";

function LlaveItem(props) {
  const elimina = async (ev) => {
    ev.preventDefault();
    if (window.confirm("Seguro que quieres eliminar esta llave?")) {
      const envio = await EnviaDatosAServicio(props.llave, "BorrarLlave");
      if (envio.ERROR === "") {
        toast("Llave eliminada", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setRecargar(true);
      } else {
        props.setTextoModal("Error al eliminar:\n" + envio.DESCERROR);
        props.setMostrandoError(true);
        props.setMuestraDialogoOK(true);
        //alert("Error al eliminar: " + envio.DESCERROR);
      }
    }
  };

  return (
    <div
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.llave.Estatus !== "OK"
            ? "#c36770"
            : props.llave.Nombre === null
            ? "#0ff"
            : "#fff",
      }}
    >
      <img
        className={classes.fotoEstud}
        src={
          "https://www.copilco.net/expedientes/documentos/" +
          props.llave.IDEst +
          "_foto.jpg"
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "https://www.copilco.net/expedientes/documentos/defaultllave.jpg";
        }}
        alt={props.llave.Llave}
      />
      <div>
        <label className={classes.nombre}>{props.llave.Llave}</label>
        <label className={classes.otros}>
          Estatus:{" "}
          {props.llave.Estatus === "NOSIRVE" ? "NO SIRVE" : props.llave.Estatus}
        </label>
        <label className={classes.otros}>
          Asignada a:{" "}
          {props.llave.Nombre === null ? "NO ASIGNADA" : props.llave.Nombre}
        </label>
        <label className={classes.otros}>
          {props.llave.Observaciones === ""
            ? "SIN OBSERVACIONES"
            : "Observaciones: " + props.llave.Observaciones}
        </label>
        <div className={classes.divBotones}>
          <button id={props.llave.IDLlave} onClick={props.editaLlave}>
            {props.nivel === 1 ? (
              <i id={props.llave.IDLlave} className="fas fa-file-alt"></i>
            ) : (
              <i id={props.llave.IDLlave} className="fas fa-eye"></i>
            )}
          </button>
          {props.nivel === 1
            ? props.llave.Nombre === null && (
                <button id={props.llave.IDLlave} onClick={elimina}>
                  <i id={props.llave.IDLlave} className="fas fa-trash-alt"></i>
                </button>
              )
            : null}
        </div>
      </div>
    </div>
  );
}

export default LlaveItem;
