import classes from "./EgresosLista.module.css";

import EgresoItem from "./EgresoItem";

function EgresosLista(props) {
  return (
    <ul className={classes.list}>
      {props.egresosLoaded.length === 0 ? (
        <h1>No hay egresos que mostrar</h1>
      ) : (
        props.egresosLoaded.map((egreso) => (
          <EgresoItem
            setRecargar={props.setRecargar}
            key={egreso.IDEgr}
            egreso={egreso}
            eliminaEgreso={props.eliminaEgreso}
            editaEgreso={props.editaEgreso}
            setDialogoOK={props.setDialogoOK}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
            nivel={props.nivel}
          />
        ))
      )}
    </ul>
  );
}

export default EgresosLista;
