import { useEffect, useState } from "react";
import useToken from "../login/useToken";
import { EnviaDatosANuevoServicio } from "../../Funciones/Funciones";
import classes from "./EgresosSelecciona.module.css";
import EgresoItem from "./EgresoItem";
import { Slide, toast } from "react-toastify";

function SeleccionaPago(props) {
  const [egresosLoaded, setEgresosLoaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken().token;
  const idCasa = token.casaSel;
  const idUsu = token.Id;

  function setRespSel(resp) {
    //console.log(resp);
    if (resp.Error !== "SI") {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.setMostrarSeleccionaPago(false);
      props.setRecargar(true);
    } else {
      props.setDialogoOK({
        texto: "Error al guardar: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  }

  function selecciona(pagoSel) {
    //console.log(pagoSel);
    const datos = {
      IDRec: pagoSel.IDRec,
      IDEgr: pagoSel.IDEgr,
      FechaProg: pagoSel.FechaProg,
    };
    EnviaDatosANuevoServicio(
      "AsignaRecurrente",
      datos,
      setRespSel,
      setIsLoading,
      null,
      idUsu
    );
  }

  function CancelaSeleccion() {
    props.setMostrarSeleccionaPago(false);
  }

  function setRespServ(resp) {
    if (resp.ERROR === "") {
      setEgresosLoaded(resp.PosiblesEgresos);
    } else {
      setIsLoading(false);
      console.log("ERROR", resp.DESCERROR);
    }
  }
  useEffect(() => {
    if (idCasa > 0) {
      setIsLoading(true);
      const datos = {
        IDCasa: idCasa,
        Recurrente: props.recurrente,
      };
      EnviaDatosANuevoServicio(
        "ListadoPosiblesPagosDeRecurrente",
        datos,
        setRespServ,
        setIsLoading,
        null,
        idUsu
      );
    } else {
      setIsLoading(false);
    }
  }, [idCasa, idUsu, props.recurrente]);

  /**************************************************************************************/
  /************************************ render ******************************************/
  /**************************************************************************************/

  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        <button className={classes.botCancela} onClick={CancelaSeleccion}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1>Pagos posibles para</h1>
      <h1>{props.recurrente.Nombre}</h1>
      <section className={classes.contSelect}>
        {egresosLoaded.length > 0 ? (
          egresosLoaded.map((egreso) => (
            <EgresoItem
              setRecargar={props.setRecargar}
              key={egreso.IDEgr}
              egreso={egreso}
              eliminaEgreso={props.eliminaEgreso}
              editaEgreso={props.editaEgreso}
              setDialogoOK={props.setDialogoOK}
              setMuestraDialogoOK={props.setMuestraDialogoOK}
              nivel={props.nivel}
              tipo={props.tipo}
              selecciona={selecciona}
            />
          ))
        ) : (
          <h1>No se encontraron pagos posibles</h1>
        )}
      </section>
    </section>
  );
}

export default SeleccionaPago;
