import { FormateaCurrency } from "../../Funciones/Funciones";
import classes from "./ResumenItem.module.css";

function ItemResumenFormaPago(props) {
  function clickeado(ev) {
    ev.preventDefault();
    props.muestraDetalle(props.tipo);
  }

  function calculaTotal() {
    var total = 0;
    for (var i = 0; i < props.flujo.length; i++) {
      total = total + parseFloat(props.flujo[i].Monto);
    }
    return total;
  }
  //console.log(props);
  return (
    <div className={classes.back} id={"fondoItem"} onClick={clickeado}>
      <div>
        <label className={classes.nombre}>{props.tipo}</label>
        {props.flujo.length === 0 ? <label className={classes.otros}>Sin {props.tipo}</label> : null}
        {props.flujo.sort((a,b) => a.Observaciones > b.Observaciones ? 1 : -1).map((flujo, index) => {
          return (
            <label key={index} className={classes.otros}>
              {flujo.Observaciones}: {FormateaCurrency.format(flujo.Monto)}
            </label>
          );
        })}
        <label className={classes.otrosNegrita}> Total: {FormateaCurrency.format(calculaTotal())}</label>
      </div>
    </div>
  );
}

export default ItemResumenFormaPago;
