/*hasta abajo esta la modificacion que había hecho para cambiar la forma en que se capturan*/

import {
  NumeroAMes,
  YYYYMMDeAñoMes,
  EnviaDatosAServicio,
  FechaParaInputDate,
  FechaMMMDeString,
} from "../../Funciones/Funciones";
import { useEffect, useRef, useState } from "react";
import useToken from "../login/useToken";
import classes from "./IngresoNuevo.module.css";
import ReciboPago from "./ReciboPago";
import { addMonths } from "date-fns";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NumericFormat } from "react-number-format";
import Select from "react-select";

function NuevoIngreso(props) {
  const formaPagoRef = useRef();
  const fechaPagRef = useRef();
  const conceptoRef = useRef();
  //const montoRef = useRef();
  const mesQuePagaRef = useRef();
  const observacionesRef = useRef();
  //const nombreRef = useRef();

  const habRef = useRef();
  const quienRef = useRef();
  const conceptoTextoRef = useRef();
  const [mesQPagaForzoso, setMesQPagaForzoso] = useState(
    props.edicionPago ? props.edicionPago.Concepto === "Renta" : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formasPago, setFormasPago] = useState([]);
  const [habitaciones, setHabitaciones] = useState([]);
  const [inquilinos, setInquilinos] = useState([]);
  const [ultPag, setUltPag] = useState([]);
  const [adeudos, setAdeudos] = useState([]);
  const [muestraRecibo, setMuestraRecibo] = useState(false);
  const [datosRecibo, setDatosRecibo] = useState([]);
  const [muestraQuien, setMuestraQuien] = useState(false);
  const [nombreSel, setNombreSel] = useState();
  const [idEst1, setIdEst1] = useState(
    props.edicionPago !== undefined
      ? props.edicionPago.IDEst
      : props.pagoNuevo
      ? props.pagoNuevo.IDEst
      : 0
  );
  const [montoR, setMontoR] = useState(
    props.edicionPago !== undefined ? props.edicionPago.Monto : MontoPago()
  );
  const token = useToken().token;
  const idCasa = token.casaSel;

  function observacionesDeObservaciones(observ) {
    const nomb1 = observ.substring(3);
    const nomb2 = nomb1.substring(nomb1.indexOf("&") + 3);

    return nomb2;
  }

  function nombreDeObservaciones(observ) {
    const nomb1 = observ.substring(3);
    const nomb2 = nomb1.substring(0, nomb1.indexOf("&"));

    return nomb2;
  }

  function CambioConcepto() {
    console.log(props.edicionPago);
    if (conceptoRef.current.value === "Renta") {
      setMesQPagaForzoso(true);
      mesQuePagaRef.current.value =
        props.edicionPago !== undefined
          ? props.edicionPago.MesQPag === "error" ||
            props.edicionPago.MesQPag === "-"
            ? MesQuePaga()
            : FechaMMMDeString(props.edicionPago.MesQPag)
          : MesQuePaga();
      setMontoR(
        props.edicionPago !== undefined ? props.edicionPago.Monto : MontoPago()
      );
    } else {
      setMesQPagaForzoso(false);
      mesQuePagaRef.current.value = "";
      setMontoR(0);
    }
  }
  function MesQuePaga() {
    if (ultPag === undefined || ultPag === null) return "";
    if (ultPag.length === 0) return "";
    var PosiblesMeses = "";
    console.log("MQP", nombreSel);
    if (adeudos !== undefined) {
      if (adeudos.length > 0 && nombreSel !== undefined) {
        const adeudo = adeudos.filter((ade) => ade.IDEst === nombreSel.value);
        //console.log("adeudo2", adeudo);
        if (adeudo.length === 1) PosiblesMeses = adeudo[0].MesQPag;
      } else if (adeudos.MesQPag !== undefined) PosiblesMeses = adeudos.MesQPag;
    }
    if (PosiblesMeses === "") {
      //console.log("ultpag", ultPag, ultPag.length > 0);
      var ultimoPagoMesQPag;
      if (ultPag.length > 0 && nombreSel !== undefined) {
        ultimoPagoMesQPag = ultPag.filter(
          (up) => up.IDEst === nombreSel.value
        )[0].MesQPag;
      } else {
        if (ultPag.MesQPag === undefined) {
          const inq = inquilinos.find((inqt) => inqt.IDEst === idEst1);
          //console.log("IniciaCont ", inquilinos, idEst1, inq);
          var FechaT = new Date(
            parseInt(inq.IniciaCont.substr(0, 4)),
            parseInt(inq.IniciaCont.substr(4, 2)) - 1,
            1,
            0,
            0,
            0
          );
          FechaT = addMonths(FechaT, -1);
          ultimoPagoMesQPag =
            FechaT.getFullYear().toString() +
            ((FechaT.getMonth() + 1).toString().length === 1
              ? "0" + (FechaT.getMonth() + 1)
              : FechaT.getMonth() + 1);
        } else {
          ultimoPagoMesQPag = ultPag.MesQPag;
        }
      }
      //console.log("ultimoPagoMesQPag", ultimoPagoMesQPag, habitaciones);
      var Fecha = new Date(
        parseInt(ultimoPagoMesQPag.substr(0, 4)),
        parseInt(ultimoPagoMesQPag.substr(4, 2)) - 1,
        1,
        0,
        0,
        0
      );
      Fecha = addMonths(Fecha, 1);
      PosiblesMeses =
        Fecha.getFullYear().toString() +
        ((Fecha.getMonth() + 1).toString().length === 1
          ? "0" + (Fecha.getMonth() + 1)
          : Fecha.getMonth() + 1);
    }
    //console.log("PosiblesMeses", PosiblesMeses);
    var mes = "";
    if (PosiblesMeses.indexOf(",") >= 0) {
      var temp = PosiblesMeses.split(",");
      if (temp[0].indexOf("(") >= 0) {
        mes = temp[0];
      }
    } else {
      mes = PosiblesMeses;
    }
    if (mes.indexOf("(") > -1) mes = mes.substring(0, mes.indexOf("(")).trim();
    var FechaF = NumeroAMes(mes.substr(4, 2)) + " " + mes.substr(0, 4);
    return FechaF;
  }

  function nombreSeleccionado(seleccionado) {
    console.log(seleccionado.value === "-1");
    setNombreSel(seleccionado);
    habRef.current.value = "";
    formaPagoRef.current.value = "0";
    conceptoRef.current.value = "0";
    conceptoTextoRef.current.value = "";
    mesQuePagaRef.current.value = "";
    if (seleccionado.value === "-1") {
      //montoRef.current.value = "";
      setIdEst1("-1");
      setMontoR(0);
      setMuestraQuien(true);
    } else if (seleccionado.value !== "0") {
      setMuestraQuien(false);
      setIdEst1(seleccionado.value);
      const IDHab = inquilinos.filter(
        (inquilino) => inquilino.IDEst === seleccionado.value
      )[0].IDHab;
      const Habitacion = habitaciones.filter((hab) => hab.IDHab === IDHab);
      //montoRef.current.value = MontoPago();
      setMontoR(MontoPago());
      if (habRef.current !== null)
        habRef.current.value = Habitacion[0].Habitacion;
    }
  }

  function MontoPago() {
    if (nombreSel === undefined) {
      if (idEst1 === "0" || idEst1 === 0) {
        return 0;
      }
      if (inquilinos.length > 0) {
        const inq = inquilinos.find((inqt) => inqt.IDEst === idEst1);
        console.log(nombreSel, inquilinos, idEst1 === 0, idEst1, inq);
        setNombreSel({ value: inq.IDEst, label: inq.Nombre });
      }
    }
    console.log("MP", idEst1, nombreSel);
    var PosiblesPagos = "";
    if (adeudos !== undefined) {
      if (adeudos.length > 0 && nombreSel !== undefined) {
        const adeudo = adeudos.filter((ade) => ade.IDEst === nombreSel.value);
        if (adeudo.length > 0) {
          PosiblesPagos = adeudo[0].MesQPag;
        }
      }
      if (adeudos.MesQPag !== undefined) {
        PosiblesPagos = adeudos.MesQPag;
      }
    }
    console.log(PosiblesPagos);
    if (PosiblesPagos === "") {
      console.log("inquilinos lenght = ", inquilinos.length);
      if (inquilinos.length === undefined) {
        PosiblesPagos = inquilinos.Precio;
      } else {
        //console.log("inq ", idEst1, inquilinos);
        const inqt = inquilinos.filter((inq) => inq.IDEst === idEst1);
        inqt.length > 0 && idEst1 !== "-1"
          ? (PosiblesPagos = inqt[0].Precio)
          : (PosiblesPagos = "");
      }
    }
    console.log(PosiblesPagos);
    var debe = "";
    if (PosiblesPagos.indexOf(",") >= 0) {
      var temp = PosiblesPagos.split(",");
      if (temp[0].indexOf("(") >= 0) {
        debe = temp[0];
      }
    } else {
      debe = PosiblesPagos;
    }
    if (debe.indexOf("(") > -1) {
      debe = debe.substring(debe.indexOf("(") + 1);
      debe = debe.substring(0, debe.indexOf(")"));
    }
    //if (parseFloat(debe) > 0) debe = FormateaCurrency.format(debe);
    //else debe = "$0.00";
    return debe;
  }

  //console.log("props", props);
  useEffect(() => {
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "DatosAltaIngreso",
        IDCasa: idCasa,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("data INGNVO", data);
        if (data.ERROR !== "SI") {
          var formaspago = [];
          var Habitaciones = [];
          var Inquilinos = [];
          var UltPag = [];
          var Adeudos = [];
          for (const key in data) {
            //console.log("key ", key, data[key]);
            const datos = {
              ...data[key],
            };
            if (key === "FormasPago") {
              formaspago = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Habitaciones") {
              Habitaciones = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Inquilinos") {
              Inquilinos = Object.keys(datos).map((key) => datos[key]);
              if (props.pagoOtros !== undefined) {
                Inquilinos.unshift({
                  Datos: { IDEst: "-1", Nombre: "Alguien mas" },
                });
              }
            } else if (key === "UltimoPago") {
              UltPag = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Adeudos") {
              Adeudos = Object.keys(datos).map((key) => datos[key]);
            }
          }
          //console.log("formaspago", formaspago);
          //console.log("Habitaciones", Habitaciones);
          //console.log("Inquilinos", Inquilinos);
          //console.log("SigIdIng", SigIDIng);
          //console.log("UltPag", UltPag);
          //console.log("Adeudos", Adeudos);
          /*if (props.pagoOtros !== undefined) {
            const inqt = { IDEst: "-1", Nombre: "Alguien mas" };
            const alguienMasAgregado = { value: "-1", label: "Alguien mas" };
            Inquilinos.unshift(alguienMasAgregado);
          }*/
          /*
          setHabitaciones(props.pagoOtros ? Habitaciones : Habitaciones[0]);
          setInquilinos(
            props.pagoOtros
              ? Inquilinos.map((inquilino) => inquilino.Datos)
              : Inquilinos[0].Datos
          );
          setUltPag(props.pagoOtros ? UltPag : UltPag[0]);
          setAdeudos(props.pagoOtros ? Adeudos : Adeudos[0]);
          */
          setHabitaciones(Habitaciones);
          setInquilinos(Inquilinos.map((inquilino) => inquilino.Datos));
          setUltPag(UltPag);
          setAdeudos(Adeudos);
          //console.log(props.edicionPago);
          if (props.edicionPago && props.edicionPago.IDEst === "0") {
            setMuestraQuien(true);
          }

          setFormasPago(formaspago);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("ERROR", data.ERROR);
        }
      });
  }, [idCasa, props.pagoOtros, props.edicionPago]);

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      //console.log("mesQuePagaRef", mesQuePagaRef.current.value);
      //console.log("nombreRef", nombreRef);
      const ingresoN = {
        IDIng: props.edicionPago !== undefined ? props.edicionPago.IDIng : "",
        IDEst: idEst1 !== 0 ? idEst1 : nombreSel.value,
        Habitacion: habRef.current.value,
        IDForPag: formaPagoRef.current.value,
        FechaPag: fechaPagRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Concepto:
          conceptoTextoRef.current.value === ""
            ? conceptoRef.current.value
            : conceptoTextoRef.current.value,
        Monto: montoR, // DejaSoloNumerosDeCurrency(montoRef.current.value),
        MesQPag:
          mesQuePagaRef.current.value === "-"
            ? mesQuePagaRef.current.value
            : !muestraQuien
            ? YYYYMMDeAñoMes(mesQuePagaRef.current.value)
            : "0",
        Observaciones:
          quienRef.current === undefined || quienRef.current === null
            ? observacionesRef.current.value
            : "&&&" +
              quienRef.current.value +
              "&&&" +
              observacionesRef.current.value,
        IDCasa: idCasa,
      };
      //console.log("ingresoN", ingresoN);
      const envio = await EnviaDatosAServicio(ingresoN, "NuevoIngreso");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        var Nombre = inquilinos.Nombre;
        if (Nombre === undefined) {
          if (muestraQuien) Nombre = quienRef.current.value;
          else
            Nombre = inquilinos.filter((inq) => inq.IDEst === ingresoN.IDEst)[0]
              .Nombre;
        }
        var DiaCorte = inquilinos.DiaCorte;
        if (DiaCorte === undefined) {
          //console.log("ingreson.idest", ingresoN.IDEst);
          if (
            ingresoN.IDEst === 0 ||
            ingresoN.IDEst === "0" ||
            ingresoN.IDEst === -1 ||
            ingresoN.IDEst === "-1"
          ) {
            DiaCorte = "0";
          } else {
            DiaCorte = inquilinos.filter(
              (inq) => inq.IDEst === ingresoN.IDEst
            )[0].DiaCorte;
          }
        }
        ingresoN.IDIng = envio.INSERT_ID;
        ingresoN.Nombre = Nombre;
        ingresoN.DiaCorte = DiaCorte;
        if (ingresoN.Concepto.toUpperCase() === "OTROS") {
          ingresoN.Concepto = observacionesDeObservaciones(
            ingresoN.Observaciones
          );
        }
        //console.log("ingresoN", ingresoN);
        //console.log("last_ID", envio.INSERT_ID);
        setDatosRecibo(ingresoN);
        setMuestraRecibo(true);
        //props.PagoCompletado();
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (props.pagoOtros && nombreSel.value === "0") {
      respuesta = respuesta + "Selecciona quien paga\n";
    } else {
      if (muestraQuien && quienRef.current.value === "") {
        respuesta = respuesta + "Nombre de quien paga\n";
      }
      if (formaPagoRef.current.value === "0") {
        respuesta = respuesta + "Forma de Pago\n";
      }
      if (!muestraQuien && conceptoRef.current.value === "0") {
        respuesta = respuesta + "Concepto\n";
      }
      if (muestraQuien && conceptoTextoRef.current.value === "") {
        respuesta = respuesta + "Concepto\n";
      }
      if (montoR === 0 || montoR === undefined) {
        respuesta = respuesta + "Monto\n";
      }
      /*if (montoRef.current.value === "") {
        respuesta = respuesta + "Monto\n";
      } else if (
        montoRef.current.value.match(
          "^[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:.[0-9]{2})?$"
        ) === null
      ) {
        respuesta = respuesta + "Monto\n";
      }*/
      if (
        mesQPagaForzoso &&
        (mesQuePagaRef.current.value === "" ||
          mesQuePagaRef.current.value.toString().toUpperCase() === "ERR -")
      ) {
        respuesta = respuesta + "Mes que paga\n";
      }
    }

    return respuesta; // respuesta;
  }

  function TerminaRecibo(ev) {
    if (ev !== undefined) {
      ev.preventDefault();
    }
    setMuestraRecibo(false);
    props.setMostrarNuevo(false);
    props.PagoCompletado();
  }
  function CancelaNuevo(ev) {
    ev.preventDefault();
    props.setMostrarNuevo(false);
  }

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  //console.log("props IN",props,props.pagoNuevo.Adeudo === undefined ? "blank" : "not blank");
  //console.log(idEst1, inquilinos);
  //console.log(inquilinos.filter((inq) => inq.IDEst === idEst1)[0].Nombre);
  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  //console.log("idest1", idEst1);
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button className={classes.botTop} onClick={CancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      {muestraRecibo && (
        <ReciboPago
          datosRecibo={datosRecibo}
          TerminaRecibo={TerminaRecibo}
          setDialogoOK={props.setDialogoOK}
          setMuestraDialogoOK={props.setMuestraDialogoOK}
        />
      )}
      <h1 className={classes.h1loc}>
        {props.edicionPago ? "Edicion Pago" : "Pago NUEVO"}
      </h1>
      <form>
        <div className={classes.divSelect}>
          <label className={classes.labelP}>Nombre</label>
          {props.pagoOtros ? (
            <Select
              className={classes.textareaNE1}
              options={inquilinos.map((inquilino) => {
                return {
                  value: inquilino.IDEst,
                  label: inquilino.Nombre,
                };
              })}
              onChange={(val) => nombreSeleccionado(val)}
            />
          ) : idEst1 === 0 || idEst1 === "0" ? (
            <input
              readOnly
              type="text"
              defaultValue={"Alguien mas"}
              className={classes.textareaNE}
            ></input>
          ) : (
            <input
              readOnly
              type="text"
              defaultValue={
                inquilinos.length > 0
                  ? inquilinos.filter((inq) => inq.IDEst === idEst1)[0].Nombre
                  : null
              }
              className={classes.textareaNE}
            ></input>
          )}
        </div>
        {muestraQuien && (
          <div>
            <label className={classes.labelP}>Quien?</label>
            <input
              type="text"
              ref={quienRef}
              defaultValue={
                props.edicionPago
                  ? nombreDeObservaciones(props.edicionPago.Observaciones)
                  : ""
              }
              placeholder="Nombre de quien pago"
              className={classes.textareaNE}
            ></input>
          </div>
        )}
        <div hidden={muestraQuien || idEst1 === "0" || idEst1 === 0}>
          <label className={classes.labelP}>Habitacion</label>
          <input
            readOnly
            type="text"
            ref={habRef}
            defaultValue={
              habitaciones.length > 0 && idEst1 > 0
                ? habitaciones.filter(
                    (hab) =>
                      hab.IDHab ===
                      inquilinos.filter((inq) => inq.IDEst === idEst1)[0].IDHab
                  )[0].Habitacion
                : null
            }
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Forma de Pago</label>
          <select
            className={classes.textareaNE}
            required
            id="FormaPago"
            ref={formaPagoRef}
            defaultValue={
              props.edicionPago !== undefined ? props.edicionPago.IDForPag : "0"
            }
          >
            <option key="0" value={"0"}>
              Selecciona una opcion
            </option>
            {formasPago.map((formapago, indice) => {
              return (
                <option key={formapago.IDForPag} value={formapago.IDForPag}>
                  {formapago.FormaPago}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Fecha de pago</label>
          <input
            required
            id="fechaPag"
            ref={fechaPagRef}
            type="date"
            defaultValue={
              props.edicionPago !== undefined
                ? FechaParaInputDate(props.edicionPago.FechaPag)
                : new Date().toISOString().substr(0, 10)
            }
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Concepto</label>
          <select
            hidden={muestraQuien || idEst1 === "0" || idEst1 === 0}
            className={classes.textareaNE}
            required
            id="Concepto"
            ref={conceptoRef}
            defaultValue={
              props.edicionPago !== undefined ? props.edicionPago.Concepto : "0"
            }
            onChange={CambioConcepto}
          >
            <option key="0" value={"0"}>
              Selecciona una opcion
            </option>
            <option key="1" value={"Renta"}>
              Renta
            </option>
            <option key="2" value={"Deposito en garantia"}>
              Depósito en garantía
            </option>
            <option key="3" value={"Duplicado llaves"}>
              Duplicado llaves
            </option>
          </select>
          <input
            readOnly
            hidden={!muestraQuien && !(idEst1 === "0" || idEst1 === 0)}
            type="text"
            ref={conceptoTextoRef}
            value={muestraQuien ? "Otros" : ""}
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Monto</label>
          <NumericFormat
            maxLength="8"
            id="Monto"
            type="text"
            className={classes.textareaNE}
            placeholder="$0.00"
            defaultValue={
              props.edicionPago !== undefined
                ? props.edicionPago.Monto
                : MontoPago()
            }
            prefix={"$"}
            value={montoR}
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            onValueChange={(values) => {
              setMontoR(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        <div hidden={muestraQuien || idEst1 === "0" || idEst1 === 0}>
          <label className={classes.labelP}>Mes Que Paga</label>
          <input
            required
            readOnly
            type="text"
            defaultValue={
              mesQPagaForzoso
                ? props.edicionPago !== undefined
                  ? FechaMMMDeString(props.edicionPago.MesQPag)
                  : MesQuePaga() === "err -"
                  ? "N/A"
                  : MesQuePaga()
                : "-"
            }
            className={classes.textareaNE}
            id="MesQuePagaInput"
            ref={mesQuePagaRef}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Observaciones</label>
          <input
            type="text"
            className={classes.textareaNE}
            id="observacionesInput"
            defaultValue={
              props.edicionPago
                ? observacionesDeObservaciones(props.edicionPago.Observaciones)
                : ""
            }
            ref={observacionesRef}
          ></input>
        </div>
        <div className={classes.divBotones}>
          {/*<button className={classes.botones} onClick={CancelaNuevo}>
            Cancelar
          </button>
          <button className={classes.botones}>Guardar </button>*/}
        </div>
      </form>
    </section>
  );
}

export default NuevoIngreso;

/*
import {
  FormateaCurrency,
  NumeroAMes,
  FuncionValidaDatos,
  DejaSoloNumerosDeCurrency,
  YYYYMMDeAñoMes,
  EnviaDatosAServicio,
  FechaParaInputDate,
  FechaMMMDeString,
  NumeroAMoneda,
  EnviaDatosANuevoServicio,
} from "../../Funciones/Funciones";
import { useEffect, useRef, useState } from "react";
import useToken from "../login/useToken";
import classes from "./IngresoNuevo.module.css";
import ReciboPago from "./ReciboPago";
import { addMonths } from "date-fns";

function validaMonto(event) {
  FuncionValidaDatos(event, "currency");
}

function NuevoIngreso(props) {
  const formaPagoRef = useRef();
  const fechaPagRef = useRef();
  const conceptoRef = useRef();
  const montoRef = useRef();
  const mesQuePagaRef = useRef();
  const observacionesRef = useRef();
  const nombreRef = useRef();
  const habRef = useRef();
  const quienRef = useRef();
  const conceptoTextoRef = useRef();
  const [mesQPagaForzoso, setMesQPagaForzoso] = useState(
    props.edicionPago ? props.edicionPago.Concepto === "Renta" : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formasPago, setFormasPago] = useState([]);
  const [habitaciones, setHabitaciones] = useState([]);
  const [inquilinos, setInquilinos] = useState([]);
  const [ultPag, setUltPag] = useState([]);
  const [adeudos, setAdeudos] = useState([]);
  const [muestraRecibo, setMuestraRecibo] = useState(false);
  const [datosRecibo, setDatosRecibo] = useState([]);
  const [muestraQuien, setMuestraQuien] = useState(false);
  const token = useToken().token;
  const idCasa = token.casaSel;
  var idEst =
    props.edicionPago !== undefined
      ? props.edicionPago.IDEst
      : props.pagoNuevo
      ? props.pagoNuevo.IDEst
      : 0;

  console.log(props, token);
  //console.log("cambio concepto", mesQPagaForzoso);
  function CambioConcepto() {
    if (conceptoRef.current.value === "Renta") {
      setMesQPagaForzoso(true);
      mesQuePagaRef.current.value =
        props.edicionPago !== undefined
          ? FechaMMMDeString(props.edicionPago.MesQPag)
          : MesQuePaga();
    } else {
      setMesQPagaForzoso(false);
      mesQuePagaRef.current.value = "";
    }
  }
  function MesQuePaga() {
    if (ultPag === undefined || ultPag === null) return "";
    if (ultPag.length === 0) return "";
    var PosiblesMeses = "";
    if (adeudos !== undefined) {
      if (adeudos.length > 0 && nombreRef.current !== null) {
        const adeudo = adeudos.filter(
          (ade) => ade.IDEst === nombreRef.current.value
        );
        //console.log("adeudo2", adeudo);
        if (adeudo.length === 1) PosiblesMeses = adeudo[0].MesQPag;
      } else if (adeudos.MesQPag !== undefined) PosiblesMeses = adeudos.MesQPag;
    }
    if (PosiblesMeses === "") {
      //console.log("ultpag", ultPag, ultPag.length > 0);
      var ultimoPagoMesQPag;
      if (ultPag.length > 0 && nombreRef.current !== null) {
        ultimoPagoMesQPag = ultPag.filter(
          (up) => up.IDEst === nombreRef.current.value
        )[0].MesQPag;
      } else {
        if (ultPag.MesQPag === undefined) {
          //console.log("IniciaCont ", inquilinos.IniciaCont);
          var FechaT = new Date(
            parseInt(inquilinos.IniciaCont.substr(0, 4)),
            parseInt(inquilinos.IniciaCont.substr(4, 2)) - 1,
            1,
            0,
            0,
            0
          );
          FechaT = addMonths(FechaT, -1);
          ultimoPagoMesQPag =
            FechaT.getFullYear().toString() +
            ((FechaT.getMonth() + 1).toString().length === 1
              ? "0" + (FechaT.getMonth() + 1)
              : FechaT.getMonth() + 1);
        } else {
          ultimoPagoMesQPag = ultPag.MesQPag;
        }
      }
      //console.log("ultimoPagoMesQPag", ultimoPagoMesQPag, habitaciones);
      var Fecha = new Date(
        parseInt(ultimoPagoMesQPag.substr(0, 4)),
        parseInt(ultimoPagoMesQPag.substr(4, 2)) - 1,
        1,
        0,
        0,
        0
      );
      Fecha = addMonths(Fecha, 1);
      PosiblesMeses =
        Fecha.getFullYear().toString() +
        ((Fecha.getMonth() + 1).toString().length === 1
          ? "0" + (Fecha.getMonth() + 1)
          : Fecha.getMonth() + 1);
    }
    //console.log("PosiblesMeses", PosiblesMeses);
    var mes = "";
    if (PosiblesMeses.indexOf(",") >= 0) {
      var temp = PosiblesMeses.split(",");
      if (temp[0].indexOf("(") >= 0) {
        mes = temp[0];
      }
    } else {
      mes = PosiblesMeses;
    }
    if (mes.indexOf("(") > -1) mes = mes.substring(0, mes.indexOf("(")).trim();
    var FechaF = NumeroAMes(mes.substr(4, 2)) + " " + mes.substr(0, 4);
    return FechaF;
  }

  function nombreSeleccionado() {
    formaPagoRef.current.value = "0";
    conceptoRef.current.value = "0";
    conceptoTextoRef.current.value = "";
    mesQuePagaRef.current.value = "";
    if (nombreRef.current.value === "-1") {
      montoRef.current.value = "";
      setMuestraQuien(true);
    } else if (nombreRef.current.value !== "0") {
      setMuestraQuien(false);
      idEst = nombreRef.current.value;
      const IDHab = inquilinos.filter(
        (inquilino) => inquilino.IDEst === nombreRef.current.value
      )[0].IDHab;
      const Habitacion = habitaciones.filter((hab) => hab.IDHab === IDHab);
      montoRef.current.value = MontoPago();
      if (habRef.current !== null)
        habRef.current.value = Habitacion[0].Habitacion;
    }
  }

  function MontoPago() {
    var PosiblesPagos = "";
    if (adeudos !== undefined) {
      if (adeudos.length > 0 && nombreRef.current !== null) {
        const adeudo = adeudos.filter(
          (ade) => ade.IDEst === nombreRef.current.value
        );
        if (adeudo.length > 0) {
          PosiblesPagos = adeudo[0].MesQPag;
        }
      }
      if (adeudos.MesQPag !== undefined) {
        PosiblesPagos = adeudos.MesQPag;
      }
    }
    if (PosiblesPagos === "") {
      if (inquilinos.length === undefined) {
        PosiblesPagos = inquilinos.Precio;
      } else {
        const inqt = inquilinos.filter((inq) => inq.IDEst === idEst);
        inqt.length > 0
          ? (PosiblesPagos = inqt[0].Precio)
          : (PosiblesPagos = "");
      }
    }
    //console.log(PosiblesPagos);
    var debe = "";
    if (PosiblesPagos.indexOf(",") >= 0) {
      var temp = PosiblesPagos.split(",");
      if (temp[0].indexOf("(") >= 0) {
        debe = temp[0];
      }
    } else {
      debe = PosiblesPagos;
    }
    if (debe.indexOf("(") > -1) {
      debe = debe.substring(debe.indexOf("(") + 1);
      debe = debe.substring(0, debe.indexOf(")"));
    }
    if (parseFloat(debe) > 0) debe = FormateaCurrency.format(debe);
    else debe = "$0.00";
    return debe;
  }

  function RespServ(respServ) {
    console.log(respServ );
  }

  //console.log("props", props);
  useEffect(() => {
    setIsLoading(true);
    const datos = { IDCasa: idCasa, IDEst: idEst };

    EnviaDatosANuevoServicio(
      "DatosAltaIngreso",
      datos,
      RespServ,
      setIsLoading,
      null,
      token.Id
    );
    /*
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "DatosAltaIngreso",
        IDCasa: idCasa,
        IDEst: idEst,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log("data INGNVO", data);
        if (data.ERROR !== "SI") {
          var formaspago = [];
          var Habitaciones = [];
          var Inquilinos = [];
          var UltPag = [];
          var Adeudos = [];
          for (const key in data) {
            //console.log("key ", key, data[key]);
            const datos = {
              ...data[key],
            };
            if (key === "FormasPago") {
              formaspago = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Habitaciones") {
              Habitaciones = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Inquilinos") {
              Inquilinos = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "UltimoPago") {
              UltPag = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Adeudos") {
              Adeudos = Object.keys(datos).map((key) => datos[key]);
            }
          }
          //console.log("formaspago", formaspago);
          //console.log("Habitaciones", Habitaciones);
          //console.log("Inquilinos", Inquilinos);
          //console.log("SigIdIng", SigIDIng);
          //console.log("UltPag", UltPag);
          //console.log("Adeudos", Adeudos);
          setHabitaciones(props.pagoOtros ? Habitaciones : Habitaciones[0]);
          setInquilinos(
            props.pagoOtros
              ? Inquilinos.map((inquilino) => inquilino.Datos)
              : Inquilinos[0].Datos
          );
          setUltPag(props.pagoOtros ? UltPag : UltPag[0]);
          setAdeudos(props.pagoOtros ? Adeudos : Adeudos[0]);
          setFormasPago(formaspago);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("ERROR", data.ERROR);
        }
      });
    }, [idCasa, idEst, props.pagoOtros]);

    const submitHandler = async (ev) => {
      ev.preventDefault();
      const DatosValidos = ValidaDatos();
      if (DatosValidos === "") {
        //console.log("mesQuePagaRef", mesQuePagaRef.current.value);
        //console.log("nombreRef", nombreRef);
        const ingresoN = {
          IDIng: props.edicionPago !== undefined ? props.edicionPago.IDIng : "",
          IDEst: idEst !== 0 ? idEst : nombreRef.current.value,
          Habitacion: habRef.current.value,
          IDForPag: formaPagoRef.current.value,
          FechaPag: fechaPagRef.current.value
            .toString()
            .toUpperCase()
            .replace(/-/gi, ""),
          Concepto:
            conceptoTextoRef.current.value === ""
              ? conceptoRef.current.value
              : conceptoTextoRef.current.value,
          Monto: DejaSoloNumerosDeCurrency(montoRef.current.value),
          MesQPag:
            mesQuePagaRef.current.value === "-"
              ? mesQuePagaRef.current.value
              : !muestraQuien
              ? YYYYMMDeAñoMes(mesQuePagaRef.current.value)
              : "0",
          Observaciones:
            quienRef.current === undefined
              ? observacionesRef.current.value
              : "&&&" +
                quienRef.current.value +
                "&&&" +
                observacionesRef.current.value,
          IDCasa: idCasa,
        };
        //console.log("ingresoN", ingresoN);
        const envio = await EnviaDatosAServicio(ingresoN, "NuevoIngreso");
        if (envio.ERROR === "") {
          props.setDialogoOK({
            texto: "Guardado OK",
            mostrandoError: false,
            recargar: false,
            cerrarVentana: "",
          });
          props.setMuestraDialogoOK(true);
          //alert("Guardado OK");
          var Nombre = inquilinos.Nombre;
          if (Nombre === undefined) {
            if (muestraQuien) Nombre = quienRef.current.value;
            else
              Nombre = inquilinos.filter((inq) => inq.IDEst === ingresoN.IDEst)[0]
                .Nombre;
          }
          var DiaCorte = inquilinos.DiaCorte;
          if (DiaCorte === undefined) {
            if (nombreRef.current.value === "-1") DiaCorte = "0";
            else
              DiaCorte = inquilinos.filter(
                (inq) => inq.IDEst === ingresoN.IDEst
              )[0].DiaCorte;
          }
          ingresoN.IDIng = envio.INSERT_ID;
          ingresoN.Nombre = Nombre;
          ingresoN.DiaCorte = DiaCorte;
          //console.log("ingresoN", ingresoN);
          //console.log("last_ID", envio.INSERT_ID);
          setDatosRecibo(ingresoN);
          setMuestraRecibo(true);
          //props.PagoCompletado();
        } else {
          props.setDialogoOK({
            texto: "Error al guardar: " + envio.DESCERROR,
            mostrandoError: true,
            recargar: false,
            cerrarVentana: "",
          });
          props.setMuestraDialogoOK(true);
          //alert("Error al guardar: " + envio.DESCERROR);
        }
      } else {
        props.setDialogoOK({
          texto: "Revisa los siguientes datos\n" + DatosValidos,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Revisa los siguientes datos\n" + DatosValidos);
      }
    };
  
    function ValidaDatos() {
      var respuesta = "";
  
      if (props.pagoOtros && nombreRef.current.value === "0") {
        respuesta = respuesta + "Selecciona quien paga\n";
      } else {
        if (muestraQuien && quienRef.current.value === "") {
          respuesta = respuesta + "Nombre de quien paga\n";
        }
        if (formaPagoRef.current.value === "0") {
          respuesta = respuesta + "Forma de Pago\n";
        }
        if (!muestraQuien && conceptoRef.current.value === "0") {
          respuesta = respuesta + "Concepto\n";
        }
        if (muestraQuien && conceptoTextoRef.current.value === "") {
          respuesta = respuesta + "Concepto\n";
        }
        if (montoRef.current.value === "") {
          respuesta = respuesta + "Monto\n";
        } else if (
          montoRef.current.value.match(
            "^[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:.[0-9]{2})?$"
          ) === null
        ) {
          respuesta = respuesta + "Monto\n";
        }
        if (
          mesQPagaForzoso &&
          (mesQuePagaRef.current.value === "" ||
            mesQuePagaRef.current.value.toString().toUpperCase() === "ERR -")
        ) {
          respuesta = respuesta + "Mes que paga\n";
        }
      }
  
      return respuesta; // respuesta;
    }
  
    function TerminaRecibo(ev) {
      ev.preventDefault();
      setMuestraRecibo(false);
      props.setMostrarNuevo(false);
      props.PagoCompletado();
    }
    function CancelaNuevo(ev) {
      ev.preventDefault();
      props.setMostrarNuevo(false);
    }
  
    //console.log("props IN",props,props.pagoNuevo.Adeudo === undefined ? "blank" : "not blank");
    if (isLoading) {
      return (
        <section className={classes.modal}>
          <h1>
            <i className="fas fa-spinner fa-spin"></i>
          </h1>
        </section>
      );
    }
    //console.log("inquilinos", inquilinos);
    return (
      <section className={classes.modal}>
        {muestraRecibo && (
          <ReciboPago
            datosRecibo={datosRecibo}
            TerminaRecibo={TerminaRecibo}
            setDialogoOK={props.setDialogoOK}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
          />
        )}
        <h1>
          {props.edicionPago
            ? props.nivel === 1
              ? "Edicion Pago"
              : "Ver Detalle"
            : "Pago NUEVO"}
        </h1>
        <form onSubmit={submitHandler}>
          <div>
            <label className={classes.labelP}>Nombre</label>
            {props.pagoOtros ? (
              <select
                className={classes.textareaNE}
                required
                ref={nombreRef}
                onChange={nombreSeleccionado}
              >
                <option key="0" value={"0"}>
                  Selecciona quien paga
                </option>
                <option key="-1" value={"-1"}>
                  Alguien mas
                </option>
                {inquilinos.map((inquilino) => {
                  return (
                    <option key={inquilino.IDEst} value={inquilino.IDEst}>
                      {inquilino.Nombre}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                readOnly
                type="text"
                defaultValue={inquilinos.Nombre}
                className={classes.textareaNE}
              ></input>
            )}
          </div>
          {muestraQuien && (
            <div>
              <label className={classes.labelP}>Quien?</label>
              <input
                type="text"
                ref={quienRef}
                placeholder="Nombre de quien pago"
                className={classes.textareaNE}
              ></input>
            </div>
          )}
          <div hidden={muestraQuien}>
            <label className={classes.labelP}>Habitacion</label>
            <input
              readOnly
              type="text"
              ref={habRef}
              defaultValue={habitaciones.Habitacion}
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Fecha de pago</label>
            <input
              required
              id="fechaPag"
              ref={fechaPagRef}
              type="date"
              defaultValue={
                props.edicionPago !== undefined
                  ? FechaParaInputDate(props.edicionPago.FechaPag)
                  : new Date().toISOString().substr(0, 10)
              }
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Forma de Pago</label>
            <select
              className={classes.textareaNE}
              required
              id="FormaPago"
              ref={formaPagoRef}
              defaultValue={
                props.edicionPago !== undefined ? props.edicionPago.IDForPag : "0"
              }
            >
              <option key="0" value={"0"}>
                Selecciona una opcion
              </option>
              {formasPago.map((formapago, indice) => {
                return (
                  <option key={formapago.IDForPag} value={formapago.IDForPag}>
                    {formapago.FormaPago}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label className={classes.labelP}>Concepto</label>
            <select
              hidden={muestraQuien}
              className={classes.textareaNE}
              required
              id="Concepto"
              ref={conceptoRef}
              defaultValue={
                props.edicionPago !== undefined ? props.edicionPago.Concepto : "0"
              }
              onChange={CambioConcepto}
            >
              <option key="0" value={"0"}>
                Selecciona una opcion
              </option>
              <option key="1" value={"Renta"}>
                Renta
              </option>
              <option key="2" value={"Deposito en garantia"}>
                Depósito en garantía
              </option>
              <option key="3" value={"Duplicado llaves"}>
                Duplicado llaves
              </option>
            </select>
            <input
              hidden={!muestraQuien}
              type="text"
              ref={conceptoTextoRef}
              placeholder="motivo del pago"
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Renta</label>
            <input
              readOnly
              type="text"
              className={classes.textareaNE}
              value={NumeroAMoneda(props.pagoNuevo.Precio)}
              onPaste={validaMonto}
              onKeyPress={validaMonto}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Desc. Pago en Efe.</label>
            <input
              required
              type="text"
              defaultValue={
                props.edicionPago !== undefined
                  ? props.edicionPago.Monto
                  : MontoPago()
              }
              className={classes.textareaNE}
              id="Monto"
              ref={montoRef}
              onPaste={validaMonto}
              onKeyPress={validaMonto}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Monto</label>
            <input
              required
              type="text"
              defaultValue={
                props.edicionPago !== undefined
                  ? props.edicionPago.Monto
                  : MontoPago()
              }
              className={classes.textareaNE}
              id="Monto"
              ref={montoRef}
              onPaste={validaMonto}
              onKeyPress={validaMonto}
            ></input>
          </div>
          <div hidden={muestraQuien}>
            <label className={classes.labelP}>Mes Que Paga</label>
            <input
              required
              readOnly
              type="text"
              defaultValue={
                mesQPagaForzoso
                  ? props.edicionPago !== undefined
                    ? FechaMMMDeString(props.edicionPago.MesQPag)
                    : MesQuePaga()
                  : "-"
              }
              className={classes.textareaNE}
              id="MesQuePagaInput"
              ref={mesQuePagaRef}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Observaciones</label>
            <input
              type="text"
              className={classes.textareaNE}
              id="observacionesInput"
              ref={observacionesRef}
            ></input>
          </div>
          <div className={classes.divBotones}>
            <button className={classes.botones} onClick={CancelaNuevo}>
              {props.nivel === 1 ? "Cancelar" : "Cerrar"}
            </button>
            {props.nivel === 1 ? (
              <button className={classes.botones}>Guardar </button>
            ) : null}
          </div>
        </form>
      </section>
    );
  }
  
  export default NuevoIngreso;
*/
