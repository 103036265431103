import { useState, useEffect } from "react";
import OKModal from "../components/dialogos/dialogoOK";
import Backdrop from "../components/layout/backdrop";
import EdicionLlave from "../components/llaves/LlaveEdicion";
import NuevaLlave from "../components/llaves/LlaveNueva";
import MenuFiltraLlaves from "../components/llaves/LlavesFiltra";
import LlavesLista from "../components/llaves/LlavesLista";
import MenuOrdenaLlaves from "../components/llaves/LlavesOrdena";
import classes from "./TodasLasLlaves.module.css";

function TodasLasLlavesPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [llavesLoaded, setLlavesLoaded] = useState([]);
  const [posFiltroLlaves, setPosFiltroLlaves] = useState([]);
  const [muestraOrdenaLlav, setMuestraOrdenaLlav] = useState(false);
  const [ordenaLlaves, setOrdenaLlaves] = useState({
    llaveAZ: true,
    llaveZA: false,
    estatusAZ: false,
    estatusZA: false,
    asignadaAZ: false,
    asignadaZA: false,
  });
  const [muestraFiltroLlav, setMuestraFiltroLlav] = useState(false);
  const [filtroLlaves, setFiltroLlaves] = useState({
    OK: true,
    perdida: false,
    noSirve: false,
    asignada: true,
    noAsignada: true,
    asignadaA: "",
    llave: "",
    observ: "",
  });
  const [muestraNuevaLlave, setMuestraNuevaLlave] = useState(false);
  const [datosEdicionLlave, setDatosEdicionLlave] = useState([]);
  const [muestraEdicionLlave, setMuestraEdicionLlave] = useState(false);
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [textoModal, setTextoModal] = useState("Guardado OK");
  const [mostrandoError, setMostrandoError] = useState(false);

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!mostrandoError) {
      setMuestraEdicionLlave(false);
      llaveCompleta();
    }
  }

  function editaLlave(ev) {
    ev.preventDefault();
    const llav = llavesLoaded.filter(
      (llavi) => llavi.IDLlave === ev.target.id
    )[0];
    setDatosEdicionLlave(llav);
    setMuestraEdicionLlave(true);
  }

  function llaveCompleta() {
    setMuestraNuevaLlave(false);
    setRecargar(true);
  }

  function queLlavesMostrar(llave) {
    if (llave.Llave.toUpperCase().indexOf(filtroLlaves.llave.toUpperCase()) < 0)
      return false;
    if (
      llave.Observaciones.toUpperCase().indexOf(
        filtroLlaves.observ.toUpperCase()
      ) < 0
    )
      return false;
    if (llave.Estatus === "OK" && !filtroLlaves.OK) return false;
    if (llave.Estatus === "PERDIDA" && !filtroLlaves.perdida) return false;
    if (llave.Estatus === "NOSIRVE" && !filtroLlaves.noSirve) return false;
    if (llave.Nombre !== null && !filtroLlaves.asignada) return false;
    if (llave.Nombre === null && !filtroLlaves.noAsignada) return false;
    if (llave.Nombre === null) return filtroLlaves.asignadaA === "";
    if (
      llave.Nombre.toUpperCase().indexOf(filtroLlaves.asignadaA.toUpperCase()) <
      0
    )
      return false;
    return true;
  }

  function FiltraLlaves(ev) {
    setPosFiltroLlaves({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraFiltroLlav(true);
  }

  function reordenaLlaves(a, b) {
    //console.log("ordenaLlaves", ordenaLlaves);
    if (ordenaLlaves.llaveAZ) return a.Llave > b.Llave ? 1 : -1;
    if (ordenaLlaves.llaveZA) return a.Llave < b.Llave ? 1 : -1;
    if (ordenaLlaves.estatusAZ) return a.Estatus > b.Estatus ? 1 : -1;
    if (ordenaLlaves.estatusZA) return a.Estatus < b.Estatus ? 1 : -1;
    if (ordenaLlaves.asignadaAZ)
      return (a.Nombre !== null ? a.Nombre.toUpperCase() : "ZZZ") >
        (b.Nombre !== null ? b.Nombre.toUpperCase() : "ZZZ")
        ? 1
        : -1;
    if (ordenaLlaves.asignadaZA)
      return (a.Nombre !== null ? a.Nombre.toUpperCase() : "AAA") <
        (b.Nombre !== null ? b.Nombre.toUpperCase() : "AAA")
        ? 1
        : -1;
  }

  function OrdenaLlaves(ev) {
    setPosFiltroLlaves({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraOrdenaLlav(true);
  }

  useEffect(() => {
    setMuestraDialogoOK(false);
    setMuestraEdicionLlave(false);
    setMuestraFiltroLlav(false);
    setMuestraNuevaLlave(false);
    setMuestraOrdenaLlav(false);
    if (props.casaSel > 0) {
      setRecargar(false);
      setIsLoading(true);
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "resumenLlaves",
          IDCasa: props.casaSel,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var llaves = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "RLlaves") {
                llaves = Object.keys(datos).map((key) => datos[key]);
              }
            }
            //console.log("llaves", llaves);
            setLlavesLoaded(llaves);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.DESCERROR);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [props.casaSel, recargar]);

  /***************************************************************************************/
  /*********************************** RENDER ********************************************/
  /***************************************************************************************/
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.centrarElem}>
      <div className={classes.TituloBoton}>
        <div className="TitEst">
          <h1>Llaves</h1>
        </div>
        <div className="contBotones">
          {1 > 0 ? (
            <button className="botAgregaEst" onClick={OrdenaLlaves}>
              <i className="fas fa-sort-amount-up-alt"></i>
            </button>
          ) : null}
          <button className="botAgregaEst" onClick={FiltraLlaves}>
            <i className="fas fa-filter"></i>
          </button>
          {props.nivel === 1 ? (
            <button className="botAgregaEst" onClick={setMuestraNuevaLlave}>
              <i className="fas fa-plus-square"></i>
            </button>
          ) : null}
        </div>
      </div>
      <LlavesLista
        nivel={props.nivel}
        editaLlave={editaLlave}
        setRecargar={setRecargar}
        llavesLoaded={llavesLoaded
          .sort((a, b) => reordenaLlaves(a, b))
          .filter((llave) => queLlavesMostrar(llave))}
        setTextoModal={setTextoModal}
        setMuestraDialogoOK={setMuestraDialogoOK}
        setMostrandoError={setMostrandoError}
      />
      {muestraOrdenaLlav && (
        <MenuOrdenaLlaves
          posicionOrdenaLlaves={posFiltroLlaves}
          setOrdenaLlaves={setOrdenaLlaves}
          ordenaLlaves={ordenaLlaves}
          setMuestraOrdenaLlav={setMuestraOrdenaLlav}
        />
      )}
      {muestraFiltroLlav && (
        <MenuFiltraLlaves
          posicionFiltroLlaves={posFiltroLlaves}
          setFiltroLlaves={setFiltroLlaves}
          filtroLlaves={filtroLlaves}
          setMuestraFiltroLlav={setMuestraFiltroLlav}
        />
      )}
      {muestraNuevaLlave && (
        <NuevaLlave
          setMostrarNuevo={setMuestraNuevaLlave}
          llaveCompleta={llaveCompleta}
          setTextoModal={setTextoModal}
          setMuestraDialogoOK={setMuestraDialogoOK}
          setMostrandoError={setMostrandoError}
          nivel={props.nivel}
        />
      )}
      {muestraEdicionLlave && (
        <EdicionLlave
          llaveCompleta={llaveCompleta}
          setMostrarLlave={setMuestraEdicionLlave}
          llave={datosEdicionLlave}
          setTextoModal={setTextoModal}
          setMuestraDialogoOK={setMuestraDialogoOK}
          setMostrandoError={setMostrandoError}
          nivel={props.nivel}
        />
      )}
      {muestraEdicionLlave && <Backdrop />}
      {muestraFiltroLlav && <Backdrop recarga={setMuestraFiltroLlav} />}
      {muestraOrdenaLlav && <Backdrop recarga={setMuestraOrdenaLlav} />}
      {muestraNuevaLlave && <Backdrop recarga={setMuestraNuevaLlave} />}
      {muestraDialogoOK && <OKModal texto={textoModal} oculta={palomeaOK} />}
      {muestraDialogoOK && <Backdrop zindex={49} />}
    </section>
  );
}

export default TodasLasLlavesPage;
