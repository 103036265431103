import React, { useState } from "react";
import useToken from "../login/useToken";
import CasaItem from "./CasaItem";
import classes from "./CasasLista.module.css";

function ListaCasas(props) {
  const [loadedCasas, setLoadedCasas] = useState([]);
  const { token, setToken } = useToken();

  function casaSeleccionada(IDCasa, NombreCasa) {
    if (IDCasa !== 0) {
      props.setCasaSel(IDCasa);
      props.setNombreCasa(NombreCasa);
      props.setNivel(estableceNivel(IDCasa));
      token["casaSel"] = IDCasa;
      token["nombreCasaSel"] = NombreCasa;
      token["nivel"] = estableceNivel(IDCasa);
      setToken(token);
      props.setMuestraListaCasas(false);
      //props.setCasaSeleccionada(event.target.value);
    }
    console.log("seleccionado", token);
  }

  function estableceNivel(IDCasa) {
    var niv = 0;
    const casNiv = JSON.parse(token.cas_y_niv);
    Object.keys(casNiv).forEach((key) => {
      if (casNiv[key].IDCasa === IDCasa) {
        niv = casNiv[key].Nivel;
      }
    });
    return niv;
  }

  if (loadedCasas.length === 0) {
    const casNiv = JSON.parse(token.cas_y_niv);
    const arr = [];
    Object.keys(casNiv).forEach((key) =>
      arr.push({ id: key, value: casNiv[key] })
    );
    //console.log(arr);
    setLoadedCasas(arr);
    //setCasasCargadas(true);
  }

  //console.log("casaSel", casaSel);
  //console.log(props);
  //console.log(token);
  return (
    <section className={classes.modal}>
      <h1>Selecciona la casa</h1>
      {loadedCasas.length > 0 ? (
        loadedCasas.map((casa) => (
          <CasaItem
            key={casa.id}
            casa={casa.value}
            seleccionada={token?.casaSel === casa.value.IDCasa}
            casaSeleccionada={casaSeleccionada}
          />
        ))
      ) : (
        <h1>No hay casas en BD</h1>
      )}
    </section>
  );
}

export default ListaCasas;
