import { useRef, useEffect, useState } from "react";
import Select from "react-select";
import {
  FormateaCurrency,
  EnviaDatosAServicio,
  EnviaFotoAServicio,
  EnviaDatosANuevoServicio,
} from "../../Funciones/Funciones";
import classes from "./EstudianteNuevo.module.css";
import useToken from "../login/useToken";
import Backdrop from "../layout/backdrop";
import MuestraCamara from "../../camara/camaraItem";
import { Slide, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

function NuevoEstudiante(props) {
  const nombreInputRef = useRef();
  const direccionInputRef = useRef();
  const numIntInputRef = useRef();
  const coloniaInputRef = useRef();
  const municipioInputRef = useRef();
  const estadoInputRef = useRef();
  const [cpInput, setCPInput] = useState();
  const paisInputRef = useRef();
  const [telFijoInput, setTelFijoInput] = useState();
  const [telMovilInput, setTelMovilInput] = useState();
  const eMailInputRef = useRef();
  const fechaNacInputRef = useRef();
  const facultadInputRef = useRef();
  const carreraInputRef = useRef();
  const [semestreInput, setSemestreInput] = useState();
  const otrosEstInputRef = useRef();
  const identificacionInputRef = useRef();
  const credEstInputRef = useRef();
  const compDomInputRef = useRef();
  const reglamentoInputRef = useRef();
  const fechaIngInputRef = useRef();
  const venceConInputRef = useRef();
  const [diaCorteInput, setDiaCorteInput] = useState();
  const parentesco1InputRef = useRef();
  const nombre1InputRef = useRef();
  const direccion1InputRef = useRef();
  const [telMovil1Input, setTelMovil1Input] = useState();
  const eMail1InputRef = useRef();
  const parentesco2InputRef = useRef();
  const nombre2InputRef = useRef();
  const direccion2InputRef = useRef();
  const eMail2InputRef = useRef();
  const [telMovil2Input, setTelMovil2Input] = useState();
  const habitacionInputRef = useRef();
  const [llaveSeleccionada, setLlaveSeleccionada] = useState();
  const fotoEstudRef = useRef();
  const [porcPagoTardioInput, setPorcPagoTardioInput] = useState();
  const [diasPagoTardioInput, setDiasPagoTardioInput] = useState();
  const [porcPagoHabitacionInput, setPorcPagoHabitacionInput] = useState(100);
  const [descPagEfeInput, setDescPagEfeInput] = useState();
  const nombrefotoInputRef = useRef();
  const [isLoading, setIsLoading] = useState([]);
  const [loadedHabitaciones, setLoadedHabitaciones] = useState([]);
  const [loadedLlaves, setLoadedLlaves] = useState([]);
  const [muestraCamara, setMuestraCamara] = useState(false);
  const [preview, setPreview] = useState(
    "https://www.copilco.net/expedientes/documentos/default.jpg"
  );
  const { token } = useToken();
  const idCasa = token.casaSel;
  const idUsu = token.Id;
  const [muestraBotones, setMuestraBotones] = useState(true);
  const [IDCasaT, setIDCasaT] = useState();

  //console.log("habitaciones12", loadedHabitaciones);
  //console.log("extras12", props.extras);
  //console.log("llaves12", loadedLlaves);
  //console.log("token", token);

  function IDHabDeExtras() {
    const IDHabJ = JSON.parse(props.extras);
    return IDHabJ.IDHab;
  }

  function FechaIngDeExtras() {
    const FechaIJ = JSON.parse(props.extras);
    return FechaIJ.fechaI.toString().toUpperCase().replace(/-/gi, "");
  }

  function VenceContDeExtras() {
    const VenceCJ = JSON.parse(props.extras);
    return VenceCJ.fechaV.toString().toUpperCase().replace(/-/gi, "");
  }

  function DiaCorteDeExtras() {
    const DiaCJ = JSON.parse(props.extras);
    return DiaCJ.DiaC;
  }

  function CancelaNuevo(event) {
    props.setMostrarNuevo(false);
  }

  function respServ(resp) {
    //console.log(resp);
    const llavesT = Object.keys(resp.Llaves).map((key) => resp.Llaves[key]);
    const habitacionesT = Object.keys(resp.HabDisp).map(
      (key) => resp.HabDisp[key]
    );
    setIsLoading(false);
    setLoadedLlaves(llavesT);
    setLoadedHabitaciones(habitacionesT);
    setDescPagEfeInput(resp.ajustes.DefDescPagEfe);
    setDiasPagoTardioInput(resp.ajustes.DefDiasPagoExt);
    setPorcPagoTardioInput(resp.ajustes.DefPorcPagoExt);
  }

  useEffect(() => {
    setIsLoading(true);
    const casNiv = JSON.parse(token.cas_y_niv);
    if (casNiv.length === 1) {
      //console.log("tiene solo una casa, seleccionando", casNiv[0].IDCasa);
    }
    const datos = {
      IDCasa:
        idCasa !== null
          ? idCasa
          : casNiv.length === 1
          ? casNiv[0].IDCasa
          : null,
    };
    setIDCasaT(datos.IDCasa);

    EnviaDatosANuevoServicio(
      "InicioAltaInquilino",
      datos,
      respServ,
      setIsLoading,
      null,
      idUsu
    );
    /*
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({ accion: "InicioAltaInquilino", IDCasa: idCasa }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var llaves = [];
        var habitaciones = [];
        //console.log("data ", data);
        if (data.ERROR !== "SI") {
          for (const key in data) {
            //console.log("key ", key, data[key]);
            const datos = {
              ...data[key],
            };
            if (key === "Llaves") {
              llaves = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "HabDisp") {
              habitaciones = Object.keys(datos).map((key) => datos[key]);
            }
          }
          console.log("habitaciones", habitaciones);
          console.log("llaves", llaves);
          //console.log("datosResumen ", datosResumen, datosResumen.ERROR[0]);
          setIsLoading(false);
          setLoadedLlaves(llaves);
          setLoadedHabitaciones(habitaciones);
        } else {
          setIsLoading(false);
          console.log("ERROR", data);
          //alert(data.DESCERROR);
        }
      });*/
  }, [idCasa, token, idUsu]);

  const cambiaFoto = async (foto) => {
    if (fotoEstudRef.current) {
      setPreview(foto);
    }
  };

  const submitHandler = async (ev) => {
    ev.preventDefault();
    setMuestraBotones(false);
    setIsLoading(true);
    const datosOK = validaDatos();
    if (datosOK === "") {
      //console.log("Datos OK");
      const estudiante = {
        Adeudo: "",
        Nombre: nombreInputRef.current.value.toString(),
        CalleNum: direccionInputRef.current.value.toString(),
        NumInt: numIntInputRef.current.value.toString(),
        Colonia: coloniaInputRef.current.value.toString(),
        Municipio: municipioInputRef.current.value.toString(),
        Estado: estadoInputRef.current.value.toString(),
        CP: cpInput,
        Pais: paisInputRef.current.value.toString(),
        TelFijo: telFijoInput,
        TelMovil: telMovilInput,
        Email: eMailInputRef.current.value,
        FechaNac: fechaNacInputRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Facultad: facultadInputRef.current.value.toString(),
        Carrera: carreraInputRef.current.value.toString(),
        Semestre: semestreInput,
        OtroMotivo: otrosEstInputRef.current.value.toString(),
        Ident: identificacionInputRef.current.value.toString().toUpperCase(),
        CredEst: credEstInputRef.current.value.toString().toUpperCase(),
        CompDom: compDomInputRef.current.value.toString().toUpperCase(),
        Reglam: reglamentoInputRef.current.value.toString().toUpperCase(),
        FechaIng: props.esInvitado
          ? FechaIngDeExtras()
          : fechaIngInputRef.current.value
              .toString()
              .toUpperCase()
              .replace(/-/gi, ""),
        IniciaCont: props.esInvitado
          ? FechaIngDeExtras()
          : fechaIngInputRef.current.value
              .toString()
              .toUpperCase()
              .replace(/-/gi, ""),
        VenceCont: props.esInvitado
          ? VenceContDeExtras()
          : venceConInputRef.current.value
              .toString()
              .toUpperCase()
              .replace(/-/gi, ""),
        DiaCorte: props.esInvitado ? DiaCorteDeExtras() : diaCorteInput,
        Contac1: parentesco1InputRef.current.value.toString(),
        NombreC1: nombre1InputRef.current.value.toString(),
        DireccC1: direccion1InputRef.current.value.toString(),
        MailC1: eMail1InputRef.current.value,
        TelMobiC1: telMovil1Input,
        Contac2: parentesco2InputRef.current.value.toString(),
        NombreC2: nombre2InputRef.current.value.toString(),
        DireccC2: direccion2InputRef.current.value.toString(),
        MailC2: eMail2InputRef.current.value.toString(),
        TelMobiC2: telMovil2Input,
        IDHab: props.esInvitado
          ? IDHabDeExtras()
          : habitacionInputRef.current.value.toString(),
        NombreFoto:
          nombrefotoInputRef.current === undefined
            ? nombreInputRef.current.value.toString()
            : nombrefotoInputRef.current.value.toString(),
        PorcPagoExtemp: porcPagoTardioInput,
        DiasPagoExtemp: diasPagoTardioInput,
        PorcPago: porcPagoHabitacionInput,
        DescPagEfe: descPagEfeInput,
        IDLlave: props.esInvitado ? "0" : llaveSeleccionada,
        key: 100000,
      };
      console.log("estud enviado", estudiante);
      const envio = { foto: fotoEstudRef.current, resultado: "" };
      envio.resultado = await EnviaDatosAServicio(estudiante, "NuevoInquilino");
      //console.log("recibido", envio);
      if (envio.resultado.ERROR === "") {
        //console.log("sin error");
        //console.log(envio);
        estudiante.key = envio.resultado.NuevoID;
        //si se tomó foto
        //console.log(envio.foto);
        if (envio.foto) {
          //console.log("si hay foto", envio.foto);
          if (
            envio.foto.src !==
            "https://www.copilco.net/expedientes/documentos/default.jpg"
          ) {
            //console.log("enviando foto");
            const envioFoto = await EnviaFotoAServicio(
              envio.foto.src,
              envio.resultado.NuevoID + "_foto.jpg",
              "cargaFotoDocumentos"
            );
            if (envioFoto.ERROR !== "") {
              props.setDialogoOK({
                texto: "Error al guardar: " + envioFoto.DESCERROR,
                mostrandoError: true,
                recargar: false,
                cerrarVentana: "",
              });
              props.setMuestraDialogoOK(true);
              //alert("Error al guardar: " + envioFoto.DESCERROR);
              return;
            }
          }
        }
        //const newEstudiantes = Object.assign(...props.estudiantes, estudiante);
        //console.log("nuevos estudiantes", newEstudiantes, props.estudiantes)
        if (!props.esInvitado) {
          toast("Guardado OK", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            transition: Slide,
          });
          props.setMostrarNuevo(false);
          if (!props.esInvitado) {
            props.setRecargar(true);
          }
          /*
          props.setDialogoOK({
            texto: "Guardado OK",
            mostrandoError: false,
            recargar: true,
            cerrarVentana: "NuevoEstudiante",
          });
          props.setMuestraDialogoOK(true);*/
          /*console.log(estudiante);
          props.setLoadedEstudiantes((prevEstudiantes) => [
            ...prevEstudiantes,
            estudiante,
          ]);*/
          //props.setMostrarNuevo(false);
        } else {
          alert("Guardado OK");
          props.terminaInvitado();
        }

        //agregar nuevo a estudiantes
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + datosOK,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + datosOK);
    }
    setMuestraBotones(true);
    setIsLoading(false);
  };

  function validaDatos() {
    var respuesta = "";

    if (nombreInputRef.current.value === "") {
      respuesta = respuesta + "Nombre\n";
    }
    if (direccionInputRef.current.value === "") {
      respuesta = respuesta + "Dirección\n";
    }
    if (coloniaInputRef.current.value === "") {
      respuesta = respuesta + "Colonia\n";
    }
    if (municipioInputRef.current.value === "") {
      respuesta = respuesta + "Municipio\n";
    }
    if (estadoInputRef.current.value === "") {
      respuesta = respuesta + "Estado\n";
    }
    if (cpInput === "") {
      respuesta = respuesta + "Código postal\n";
    }
    if (paisInputRef.current.value === "") {
      respuesta = respuesta + "País\n";
    }
    if (telFijoInput === "") {
      respuesta = respuesta + "Teléfono Fijo\n";
    }
    if (telMovilInput === "") {
      respuesta = respuesta + "Teléfono móvil\n";
    }
    if (eMailInputRef.current.value === "") {
      respuesta = respuesta + "e-mail\n";
    }
    if (fechaNacInputRef.current.value === "") {
      respuesta = respuesta + "Fecha de nacimiento\n";
    }
    if (
      facultadInputRef.current.value === "" &&
      otrosEstInputRef.current.value === ""
    ) {
      respuesta = respuesta + "Facultad u Otros estudios\n";
    }
    if (
      carreraInputRef.current.value === "" &&
      facultadInputRef.current.value !== ""
    ) {
      respuesta = respuesta + "Carrera\n";
    }
    if (semestreInput === "" && facultadInputRef.current.value !== "") {
      respuesta = respuesta + "Semestre\n";
    }
    if (identificacionInputRef.current.value === "0") {
      respuesta = respuesta + "Identificación\n";
    }
    if (credEstInputRef.current.value === "0") {
      respuesta = respuesta + "Credencial de estudios\n";
    }
    if (compDomInputRef.current.value === "0") {
      respuesta = respuesta + "Comprobante de domicilio\n";
    }
    if (reglamentoInputRef.current.value === "0") {
      respuesta = respuesta + "Reglamento\n";
    }
    if (!props.esInvitado) {
      if (fechaIngInputRef.current.value === "") {
        respuesta = respuesta + "Fecha de ingreso\n";
      }
      if (venceConInputRef.current.value === "") {
        respuesta = respuesta + "Fecha que vence contrato\n";
      }
      if (diaCorteInput === "" || diaCorteInput === undefined) {
        respuesta = respuesta + "Dia de corte\n";
      } else if (parseInt(diaCorteInput) < 1 || parseInt(diaCorteInput) > 31) {
        respuesta = respuesta + "El día de corte debe estar entre 1 y 31\n";
      }
    }
    if (parentesco1InputRef.current.value === "") {
      respuesta = respuesta + "Parentesco del contacto 1\n";
    }
    if (nombre1InputRef.current.value === "") {
      respuesta = respuesta + "Nombre del contacto 1\n";
    }
    if (direccion1InputRef.current.value === "") {
      respuesta = respuesta + "Dirección del contacto 1\n";
    }
    if (telMovil1Input === "") {
      respuesta = respuesta + "Teléfono movil del contacto 1\n";
    }
    if (
      parentesco2InputRef.current.value === "" &&
      (nombre2InputRef.current.value !== "" ||
        (telMovil2Input !== "" && telMovil2Input !== undefined))
    ) {
      //console.log(nombre2InputRef.current.value, telMovil2Input);
      respuesta = respuesta + "Parentesco del contacto 2\n";
    }
    if (
      nombre2InputRef.current.value === "" &&
      (parentesco2InputRef.current.value !== "" ||
        (telMovil2Input !== "" && telMovil2Input !== undefined))
    ) {
      respuesta = respuesta + "Nombre del contacto 2\n";
    }
    if (
      telMovil2Input === "" &&
      (parentesco2InputRef.current.value !== "" ||
        nombre2InputRef.current.value !== "")
    ) {
      respuesta = respuesta + "Teléfono movil del contacto 2\n";
    }
    if (!props.esInvitado) {
      if (habitacionInputRef.current.value === "0") {
        respuesta = respuesta + "Habitación\n";
      }
      if (
        porcPagoTardioInput === undefined ||
        (porcPagoTardioInput === 0 &&
          diasPagoTardioInput !== 0 &&
          diasPagoTardioInput !== undefined)
      ) {
        respuesta = respuesta + "% por pago tardío\n";
      }
      if (porcPagoTardioInput > 20) {
        respuesta = respuesta + "% por pago tardío no puede ser mayor a 20%\n";
      }
      if (
        diasPagoTardioInput === undefined ||
        (diasPagoTardioInput === 0 &&
          porcPagoTardioInput !== 0 &&
          porcPagoTardioInput !== undefined)
      ) {
        respuesta = respuesta + "Días para pago tardío\n";
      }
      if (diasPagoTardioInput > 30) {
        respuesta =
          respuesta + "Días para pago tardío no puede ser mayor a 30\n";
      }
      if (
        porcPagoHabitacionInput === undefined ||
        porcPagoHabitacionInput === 0
      ) {
        respuesta = respuesta + "% pago habitación\n";
      }
      if (porcPagoHabitacionInput > 100) {
        respuesta = respuesta + "% pago habitación no puede ser mayor a 100\n";
      }
      if (descPagEfeInput < 0) {
        respuesta =
          respuesta + "Desc pago en Efectivo no puede ser menor a cero\n";
      }
      if (descPagEfeInput > props.estudiantes.Precio * 0.1) {
        respuesta =
          respuesta +
          "Desc pago en Efectivo no puede ser mayor a " +
          props.estudiantes.Precio * 0.1 +
          "\n";
      }
      if (nombrefotoInputRef.current.value === "") {
        nombrefotoInputRef.current.value = nombreInputRef.current.value;
      }
    }
    return respuesta;
  }

  /***********************************************************************/
  /************************ RENDER ***************************************/
  /***********************************************************************/
  //console.log(props.nivel);
  if (isLoading) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  //console.log("nivel", props.nivel, "es invitado", props.esInvitado);
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {muestraBotones && (props.nivel === 1 || props.esInvitado) && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        {muestraBotones && (
          <button onClick={CancelaNuevo}>
            <i className="fas fa-times"></i>
          </button>
        )}
      </div>
      <h1 className={classes.h1loc}>NUEVO Estudiante</h1>
      <form onSubmit={submitHandler}>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Datos Generales
          </label>
        </div>
        {props.esInvitado && idCasa === "1" && (
          <div>
            <input
              id="privacidad"
              type="checkbox"
              className={classes.check}
            ></input>
            <label htmlFor="privacidad" className={classes.check}>
              Estoy de acuerdo y acepto el aviso de privacidad que leí en el
              contrato
            </label>
          </div>
        )}
        <div>
          <label className={classes.labelP}>Nombre completo</label>
          <input
            id="nombre"
            ref={nombreInputRef}
            type="text"
            placeholder="Nombre, Ap Pat, Ap Mat"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Calle y # Ext.</label>
          <input
            id="direccion"
            ref={direccionInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}># Int</label>
          <input
            id="numInt"
            ref={numIntInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Colonia</label>
          <input
            id="colonia"
            ref={coloniaInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Alcaldia/Municipio</label>
          <input
            id="municipio"
            ref={municipioInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Estado</label>
          <input
            id="estado"
            ref={estadoInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Código Postal</label>
          <NumericFormat
            maxLength="5"
            id="cp"
            type="text"
            className={classes.textareaNE}
            placeholder="00000"
            onValueChange={(values) => {
              setCPInput(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>País</label>
          <input
            id="pais"
            ref={paisInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>teléfono fijo</label>
          <NumericFormat
            maxLength="20"
            id="telFijo"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            onValueChange={(values) => {
              setTelFijoInput(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>teléfono movil</label>
          <NumericFormat
            maxLength="20"
            id="telMovil"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            onValueChange={(values) => {
              setTelMovilInput(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>e-mail</label>
          <input
            id="eMail"
            ref={eMailInputRef}
            type="email"
            className={classes.textareaNE + " " + classes.email}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Fecha Nacimiento</label>
          <input
            id="fechaNac"
            ref={fechaNacInputRef}
            type="date"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Razón Estancia
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Facultad</label>
          <input
            id="facultad"
            ref={facultadInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Carrera</label>
          <input
            id="carrera"
            ref={carreraInputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Semestre</label>
          <NumericFormat
            required
            maxLength="2"
            id="semestre"
            type="text"
            className={classes.textareaNE}
            placeholder="1"
            onValueChange={(values) => {
              setSemestreInput(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>Otros Estudios</label>
          <input
            id="otrosEst"
            ref={otrosEstInputRef}
            type="text"
            className={classes.textareaNE}
            placeholder="Cursando actualmente"
          ></input>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Documentación
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Identificación</label>
          <select
            id="identificacion"
            ref={identificacionInputRef}
            className={classes.textareaNE}
          >
            <option key="0" value={"0"}>
              {props.esInvitado
                ? "¿Podrás proporcionar copia?"
                : "¿Proporcionó copia?"}
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Cred. Estudiante</label>
          <select
            id="credEst"
            ref={credEstInputRef}
            className={classes.textareaNE}
          >
            <option key="0" value={"0"}>
              {props.esInvitado
                ? "¿Podrás proporcionar copia?"
                : "¿Proporcionó copia?"}
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Comp. Domicilio</label>
          <select
            id="compDom"
            ref={compDomInputRef}
            className={classes.textareaNE}
          >
            <option key="0" value={"0"}>
              {props.esInvitado
                ? "¿Podrás proporcionar copia?"
                : "¿Proporcionó copia?"}
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Reglamento</label>
          <select
            id="reglamento"
            ref={reglamentoInputRef}
            className={classes.textareaNE}
          >
            <option key="0" value={"0"}>
              {props.esInvitado ? "¿Lo firmarás?" : "¿Lo firmó?"}
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        {!props.esInvitado && (
          <div>
            <label className={classes.tituloP + " " + classes.labelP}>
              Fechas
            </label>
          </div>
        )}
        {!props.esInvitado && (
          <div>
            <label className={classes.labelP}>Fecha Ingreso</label>
            <input
              id="fechaIng"
              ref={fechaIngInputRef}
              type="date"
              className={classes.textareaNE}
            ></input>
          </div>
        )}
        {!props.esInvitado && (
          <div>
            <label className={classes.labelP}>Vence Contrato</label>
            <input
              id="venceCon"
              ref={venceConInputRef}
              type="date"
              className={classes.textareaNE}
            ></input>
          </div>
        )}
        {!props.esInvitado && (
          <div>
            <label className={classes.labelP}>Día de corte</label>
            <NumericFormat
              required
              maxLength="2"
              id="diaCorte"
              type="text"
              className={classes.textareaNE}
              placeholder="1"
              onValueChange={(values) => {
                setDiaCorteInput(values.floatValue);
              }}
            ></NumericFormat>
          </div>
        )}
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Contacto 1
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Parentesco</label>
          <input
            id="parentesco1"
            ref={parentesco1InputRef}
            type="text"
            className={classes.textareaNE}
            placeholder="Madre/Padre/Hermano/Tio"
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Nombre</label>
          <input
            id="nombre1"
            ref={nombre1InputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Direccion</label>
          <input
            id="direccion1"
            ref={direccion1InputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>e-mail</label>
          <input
            id="eMail1"
            type="email"
            ref={eMail1InputRef}
            className={classes.textareaNE + " " + classes.email}
            placeholder="correo@correo.com"
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Teléfono móvil</label>
          <NumericFormat
            maxLength="20"
            id="telMovil1"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            onValueChange={(values) => {
              setTelMovil1Input(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Contacto 2
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Parentesco</label>
          <input
            id="parentesco2"
            ref={parentesco2InputRef}
            type="text"
            className={classes.textareaNE}
            placeholder="Madre/Padre/Hermano/Tio"
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Nombre</label>
          <input
            id="nombre2"
            ref={nombre2InputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Direccion</label>
          <input
            id="direccion2"
            ref={direccion2InputRef}
            type="text"
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>e-mail</label>
          <input
            id="eMail2"
            type="email"
            ref={eMail2InputRef}
            className={classes.textareaNE + " " + classes.email}
            placeholder="correo@correo.com"
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Teléfono móvil</label>
          <NumericFormat
            maxLength="20"
            id="telMovil2"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            onValueChange={(values) => {
              setTelMovil2Input(values.floatValue);
            }}
          ></NumericFormat>
        </div>
        {!props.esInvitado && (
          <div>
            <label className={classes.tituloP + " " + classes.labelP}>
              Habitación
            </label>
          </div>
        )}
        {!props.esInvitado && (
          <div>
            <select
              id="habitacion"
              ref={habitacionInputRef}
              className={classes.textareaNE}
            >
              <option key="0" value={"0"}>
                selecciona habitación
              </option>
              {loadedHabitaciones.map((habitacion, indice) => {
                return (
                  <option key={habitacion.IDHab} value={habitacion.IDHab}>
                    {habitacion.Habitacion} (
                    {FormateaCurrency.format(habitacion.Precio)})
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {!props.esInvitado && (
          <div>
            <label className={classes.tituloP + " " + classes.labelP}>
              Llave
            </label>
          </div>
        )}
        {!props.esInvitado && (
          <section>
            <div className={classes.contenedorSelect}>
              <Select
                isMulti={true}
                value={llaveSeleccionada}
                onChange={setLlaveSeleccionada}
                className={classes.textareaNE}
                options={loadedLlaves.map((llave) => {
                  return { value: llave.IDLlave, label: llave.Llave };
                })}
              />
            </div>
            <div>
              <label className={classes.tituloP + " " + classes.labelP}>
                Otros
              </label>
            </div>
            <div>
              <label className={classes.labelP}>% por pago tardío</label>
              <NumericFormat
                maxLength="4"
                id="porcPagoTardio"
                type="text"
                className={classes.textareaNE}
                placeholder="5%"
                suffix={"%"}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                decimalScale={0}
                defaultValue={porcPagoTardioInput}
                onValueChange={(values) => {
                  setPorcPagoTardioInput(values.floatValue);
                }}
              ></NumericFormat>
            </div>
            <div>
              <label className={classes.labelP}>Días para pago tardío</label>
              <NumericFormat
                maxLength="10"
                id="diasPagoTardio"
                type="text"
                className={classes.textareaNE}
                placeholder="5 días"
                suffix={" día(s)"}
                decimalScale={0}
                defaultValue={diasPagoTardioInput}
                onValueChange={(values) => {
                  setDiasPagoTardioInput(values.floatValue);
                }}
              ></NumericFormat>
            </div>
            <div>
              <label className={classes.labelP}>% pago habitación</label>
              <NumericFormat
                maxLength="4"
                id="porcPagoHab"
                type="text"
                className={classes.textareaNE}
                placeholder="100%"
                suffix={"%"}
                decimalScale={2}
                defaultValue={porcPagoHabitacionInput}
                onValueChange={(values) => {
                  setPorcPagoHabitacionInput(values.floatValue);
                }}
              ></NumericFormat>
            </div>
            <div>
              <label className={classes.labelP}>Desc pago en Efectivo</label>
              <NumericFormat
                maxLength="8"
                id="descPagoEfe"
                type="text"
                className={classes.textareaNE}
                placeholder="$100.00"
                prefix={"$"}
                decimalScale={2}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                defaultValue={descPagEfeInput}
                onValueChange={(values) => {
                  setDescPagEfeInput(values.floatValue);
                }}
              ></NumericFormat>
            </div>
            <div>
              <label className={classes.labelP}>Nombre planilla fotos</label>
              <input
                id="nombreFoto"
                ref={nombrefotoInputRef}
                type="text"
                className={classes.textareaNE}
                placeholder="Nombre, Ap Paterno"
                defaultValue={
                  nombreInputRef.current !== undefined &&
                  nombreInputRef.current !== null
                    ? nombreInputRef.current.value.toString()
                    : ""
                }
                required
              ></input>
            </div>
          </section>
        )}

        {IDCasaT === 1 ? null : (
          <label className={classes.tituloP + " " + classes.labelP}>
            Foto (Opcional)
          </label>
        )}
        {IDCasaT === 1 ? null : (
          <div className={classes.divFoto}>
            <img
              ref={fotoEstudRef}
              className={classes.fotoEstud}
              src={preview}
              alt={"Fotos Estudiante"}
            />
            <button
              onClick={(ev) => {
                ev.preventDefault();
                setMuestraCamara(true);
              }}
            >
              <i className="fas fa-camera"></i>
            </button>
          </div>
        )}
        {muestraCamara && (
          <MuestraCamara
            cambiaFoto={(foto) => cambiaFoto(foto)}
            setMuestraCamara={setMuestraCamara}
          />
        )}
        {muestraCamara && <Backdrop zindex={19} recarga={setMuestraCamara} />}
      </form>
    </section>
  );
}

export default NuevoEstudiante;
