import { addDays, addMonths } from "date-fns";
import { useState, useEffect, useRef } from "react";
import Backdrop from "../components/layout/backdrop";
import ResumenDepGarLista from "../components/resumenes/ResumenDepGar";
import ListaResumenes from "../components/resumenes/ResumenesLista";
import ResumenIngEgrLista from "../components/resumenes/ResumenIngEgAgrupados";
import { FechaParaInputDate, YYYYMMDDdeDate } from "../Funciones/Funciones";
import classes from "./TodosLosResumenes.module.css";
import CuentaEfectivo from "../components/resumenes/CuentaEfectivo";

function TodosLosResumenesPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [flujos, setFlujos] = useState([]);
  const [flujosXFecha, setFlujosXFecha] = useState([]);
  const [muestraResumenDepGar, setMuestraResumenDepGar] = useState(false);
  const [balance, setBalance] = useState([]);
  const [recargar, setRecargar] = useState(false);
  const [filtro, setFiltro] = useState({
    Otros: false,
    Renta: true,
    DepGar: false,
    Efectivo: true,
    Transferencia: true,
    Nombre: "",
    Habitacion: "",
    FechaIni: FechaInicio(new Date(), "carga"),
    FechaFin: FechaFin(new Date(), "carga"),
  });
  const [muestraResumenIngEgr, setMuestraResumenIngEgr] = useState(false);
  const [tituloIngEgr, setTituloIngEgr] = useState("");
  const fechaIniRef = useRef();
  const fechaFinRef = useRef();

  function cambioFecha() {
    const nvoFiltro = {
      ...filtro,
      ...{
        FechaIni: YYYYMMDDdeDate(
          new Date(fechaIniRef.current.value + " 0:0:0")
        ),
        FechaFin: YYYYMMDDdeDate(
          new Date(fechaFinRef.current.value + " 0:0:0")
        ),
      },
    };
    //console.log("nvofiltro", nvoFiltro);
    setFiltro(nvoFiltro);
  }

  function FechaInicio(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addDays(fecha, -(fecha.getDate() - 1));
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    return temp;
  }
  function FechaFin(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addMonths(fecha, 1);
      fecha = addDays(fecha, -fecha.getDate());
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    return temp;
  }

  function muestraDetalle(titulo) {
    if (titulo === "Depósitos en garantía") {
      setMuestraResumenDepGar(true);
    } else if (titulo === "Ingresos" || titulo === "Egresos") {
      setTituloIngEgr(titulo);
      setMuestraResumenIngEgr(true);
    } else {
      alert("En desarrollo");
    }
  }

  useEffect(() => {
    setMuestraResumenDepGar(false);
    setMuestraResumenIngEgr(false);
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "ResumenFlujos",
        IDCasa: props.casaSel,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var depGar = [];
        var ingresos = [];
        var egresos = [];
        for (const key in data) {
          const datos = {
            ...data[key],
          };
          if (key === "DepGar") {
            depGar = Object.keys(datos).map((key) => datos[key]);
          }
          if (key === "Egresos") {
            egresos = Object.keys(datos).map((key) => datos[key]);
          }
          if (key === "Ingresos") {
            ingresos = Object.keys(datos).map((key) => datos[key]);
          }
        }
        var totEgresos = {};
        for (const egrT of egresos) {
          if (!(egrT.Observaciones in totEgresos)) {
            totEgresos[egrT.Observaciones] = "0";
          }
          totEgresos[egrT.Observaciones] =
            parseFloat(totEgresos[egrT.Observaciones]) + parseFloat(egrT.Monto);
        }
        var totIngresos = {};
        for (const ingT of ingresos) {
          if (!(ingT.Observaciones in totIngresos)) {
            totIngresos[ingT.Observaciones] = "0";
          }
          totIngresos[ingT.Observaciones] =
            parseFloat(totIngresos[ingT.Observaciones]) +
            parseFloat(ingT.Monto);
        }
        var totales = {};
        totales = Object.keys(totIngresos).map((key) => {
          var temp = {
            titulo: key,
            ingresos: totIngresos[key],
            egresos: totEgresos[key] ? totEgresos[key] : 0,
          };
          return temp;
        });
        for (const key in totEgresos) {
          const element = totEgresos[key];
          for (const total of totales) {
            var esta = false;
            if (total.titulo === key) {
              esta = true;
              break;
            }
          }
          if (!esta) {
            var temp = {};
            temp = { titulo: key, ingresos: 0, egresos: element };
            totales.push(temp);
          }
        }
        totales.unshift({ titulo: "TOTAL", ingresos: 0, egresos: 0 });
        for (const key in totales) {
          const element = totales[key];
          totales[0].ingresos =
            parseFloat(totales[0].ingresos) + parseFloat(element.ingresos);
          totales[0].egresos =
            parseFloat(totales[0].egresos) + parseFloat(element.egresos);
        }
        totales[0].ingresos =
          parseFloat(totales[0].ingresos) - parseFloat(depGar[0].Monto);
        totales.push({
          titulo: "Depósitos en garantía",
          ingresos: parseFloat(depGar[0].Monto),
          egresos: 0,
        });
        setFlujos(totales);
        //console.log(totales);
        //console.log("DepGar", depGar);
        //console.log("totEgresos", totEgresos);
        //console.log("totIngresos", totIngresos);
        //console.log(filtro.FechaIni);
        fetch("https://www.copilco.net/inc/funciones.React.php", {
          method: "POST",
          body: JSON.stringify({
            accion: "ResumenPorFecha",
            IDCasa: props.casaSel,
            FechaIni: filtro.FechaIni,
            FechaFin: filtro.FechaFin,
          }),
          headers: {
            "content-type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            //console.log(data);
            var ingresos = [];
            var egresos = [];
            var depGar = [];
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "Egresos") {
                egresos = Object.keys(datos).map((key) => datos[key]);
              }
              if (key === "Ingresos") {
                ingresos = Object.keys(datos).map((key) => datos[key]);
              }
              if (key === "DepGar") {
                depGar = Object.keys(datos).map((key) => datos[key]);
              }
            }
            const ingresosTot = [];
            for (var i = 0; i < ingresos.length; i++) {
              //console.log(i, ingresos[i]);
              var esta = false;
              for (var j = 0; j < ingresosTot.length; j++) {
                if (
                  ingresosTot[j].Observaciones === ingresos[i].Observaciones
                ) {
                  esta = true;
                  ingresosTot[j].Monto =
                    parseFloat(ingresosTot[j].Monto) +
                    parseFloat(ingresos[i].Monto);
                  break;
                }
              }
              if (!esta) {
                ingresosTot.push(ingresos[i]);
              }
            }
            //console.log("ingresosTot", ingresosTot);
            const egresosTot = [];
            for (var k = 0; k < egresos.length; k++) {
              var esta1 = false;
              for (var m = 0; m < egresosTot.length; m++) {
                if (egresosTot[m].Observaciones === egresos[k].Observaciones) {
                  esta1 = true;
                  egresosTot[m].Monto =
                    parseFloat(egresosTot[m].Monto) +
                    parseFloat(egresos[k].Monto);
                  break;
                }
              }
              if (!esta1) {
                egresosTot.push(egresos[k]);
              }
            }
            //creamos el balance
            const balance = [];
            //agregamos los ingresos al balance
            for (var n = 0; n < ingresosTot.length; n++) {
              balance.push({
                Observaciones: ingresosTot[n].Observaciones,
                Monto: ingresosTot[n].Monto,
              });
            }
            //console.log("balance", balance);
            //agregamos egresos al balance
            for (var p = 0; p < egresosTot.length; p++) {
              var esta2 = false;
              for (var q = 0; q < balance.length; q++) {
                //console.log(balance[q], egresosTot[p]);
                if (balance[q].Observaciones === egresosTot[p].Observaciones) {
                  esta2 = true;
                  const temp =
                    parseFloat(balance[q].Monto) -
                    parseFloat(egresosTot[p].Monto);
                  //console.log(balance[q].Monto, egresosTot[p].Monto, temp);
                  //console.log(temp);
                  balance[q].Monto = temp;

                  break;
                }
              }
              if (!esta2) {
                balance.push({
                  Observaciones: egresosTot[p].Observaciones,
                  Cuenta: 5,
                  Monto: "-" + egresosTot[p].Monto,
                });
              }
            }
            if (depGar[0].Monto !== null) {
              balance.push({
                Observaciones: "Dep Garantía",
                Cuenta: 5,
                Monto: "-" + depGar[0].Monto,
              });
            }
            //console.log("balance", balance);
            //console.log("egresosTot", egresosTot);
            //console.log("ingresos", ingresos);
            //console.log("egresos", egresos);
            //console.log("depGar", depGar);
            const ingEg = [];
            ingEg.push(ingresosTot);
            ingEg.push(egresosTot);
            const bal = [];
            bal.push(balance);
            setFlujosXFecha(ingEg);
            setBalance(bal);
          });

        setIsLoading(false);
      });
  }, [props.casaSel, filtro.FechaFin, filtro.FechaIni, recargar]);

  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.centrarElem}>
      <div className={classes.TituloBoton}>
        <div className="TitEst">
          <h1>Resumen Flujos</h1>
        </div>
      </div>
      <ListaResumenes
        flujos={flujos}
        fechas={false}
        muestraDetalle={muestraDetalle}
      />
      <div>
        <div className="TitEst">
          <h1>Egresos/Ingresos por Forma de Pago</h1>
        </div>
        <label className={classes.rangoFechas}>
          <input
            required
            type="date"
            ref={fechaIniRef}
            defaultValue={FechaParaInputDate(filtro.FechaIni)}
            onChange={cambioFecha}
          ></input>{" "}
          al{" "}
          <input
            required
            type="date"
            ref={fechaFinRef}
            defaultValue={FechaParaInputDate(filtro.FechaFin)}
            onChange={cambioFecha}
          ></input>
        </label>
      </div>
      <ListaResumenes
        flujos={flujosXFecha}
        fechas={true}
        tipo="FormaPago"
        muestraDetalle={muestraDetalle}
      />
      <ListaResumenes
        flujos={balance}
        fechas={true}
        tipo="balance"
        muestraDetalle={muestraDetalle}
      />
      <h1>Efectivo vs app</h1>
      <CuentaEfectivo
        casaSel={props.casaSel}
        nivel={props.nivel}
        recargar={recargar}
        setRecargar={setRecargar}
      />
      {muestraResumenDepGar && (
        <ResumenDepGarLista setMuestraResumenDepGar={setMuestraResumenDepGar} />
      )}
      {muestraResumenDepGar && <Backdrop recarga={setMuestraResumenDepGar} />}
      {muestraResumenIngEgr && (
        <ResumenIngEgrLista
          setMuestraResumenIngEgr={setMuestraResumenIngEgr}
          titulo={tituloIngEgr}
          fechaIni={filtro.FechaIni}
          fechaFin={filtro.FechaFin}
        />
      )}
      {muestraResumenIngEgr && <Backdrop recarga={setMuestraResumenIngEgr} />}
    </section>
  );
}

export default TodosLosResumenesPage;
