import PendienteItem from "./PendienteItem";
import classes from "./PendientesLista.module.css";

function ListaPendientes(props) {
  return (
    <div>
      <ul className={classes.list}>
        {props.pendientes.map((pendiente, indice) => (
          <PendienteItem
            key={pendiente.IDPend}
            indice={indice + 1}
            numpends={props.pendientes.length}
            pendiente={pendiente}
            editaPendiente={props.editaPendiente}
            agregaAntes={props.agregaAntes}
            subePrioridad={props.subePrioridad}
            bajaPrioridad={props.bajaPrioridad}
            setDialogoOK={props.setDialogoOK}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
            cambiaPrioridad={props.cambiaPrioridad}
            nivel={props.nivel}
            guardaDescripcion={props.guardaDescripcion}
          />
        ))}
      </ul>
    </div>
  );
}

export default ListaPendientes;
