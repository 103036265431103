import { useEffect, useRef, useState } from "react";
import { EnviaDatosAServicio } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./CeCoEdita.module.css";
import Select from "react-select";
import { Slide, toast } from "react-toastify";

function EditaCeCo(props) {
  const CeCoSelRef = useRef();
  const CeCoRef = useRef();
  const observRef = useRef();
  const IDCeCoRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [ceCosLoaded, setCeCosLoaded] = useState([]);
  const { token } = useToken();
  const idCasa = token.casaSel;

  useEffect(() => {
    if (idCasa > 0) {
      setIsLoading(true);
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "cargaCecos",
          IDCasa: idCasa,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var ceCos = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "RCeCos") {
                ceCos = Object.keys(datos).map((key) => datos[key]);
              }
            }
            const ceCoT = ceCos.map((ceco) => {
              return {
                value: ceco.IDCeCo,
                label: ceco.CeCo,
                observ: ceco.Observaciones === null ? "" : ceco.Observaciones,
              };
            });
            console.log("ceCos", ceCos);
            console.log("ceCoT", ceCoT);
            setCeCosLoaded(ceCoT);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.DESCERROR);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [idCasa]);

  const GuardaCeco = async (ev) => {
    ev.preventDefault();
    if (IDCeCoRef.current.value === "") {
      props.setDialogoOK({
        texto: "por favor selecciona el Centro de Costos a modificar",
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("por favor selecciona el Centro de Costos a modificar");
    } else if (CeCoRef.current.value === "") {
      props.setDialogoOK({
        texto: "por favor llena el nombre del Centro de Costos",
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("por favor llena el nombre del Centro de Costos");
    } else {
      const CeCoN = {
        IDCeCo: IDCeCoRef.current.value,
        IDCasa: idCasa,
        CeCo: CeCoRef.current.value,
        Observaciones: observRef.current.value,
      };
      console.log("CeCoN", CeCoN);
      const envio = await EnviaDatosAServicio(CeCoN, "editaCeCo");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setMuestraEditaCeCo(false);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    }
  };

  function cancelaNuevo(ev) {
    ev.preventDefault();
    props.setMuestraEditaCeCo(false);
  }

  function cambioCeCo(selectedOption) {
    IDCeCoRef.current.value = selectedOption.value;
    CeCoRef.current.value = selectedOption.label;
    observRef.current.value = selectedOption.observ;
  }

  if (isLoading) {
    //console.log("cargando");
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      {props.nivel === 1 ? <h1>Cambia CeCo</h1> : <h1>Ver CeCos</h1>}
      <div className={classes.contSelect}>
        {props.nivel === 1 ? (
          <label className={classes.labelP}>Modificar:</label>
        ) : (
          <label className={classes.labelP}>CeCos:</label>
        )}
        <Select
          ref={CeCoSelRef}
          className={classes.textareaNE1}
          options={ceCosLoaded}
          onChange={cambioCeCo}
        />
      </div>
      <hr className={classes.linea}></hr>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>IDCeCo:</label>
        <input
          required
          readOnly
          type="text"
          ref={IDCeCoRef}
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>Centro Costos:</label>
        <input
          required
          type="text"
          ref={CeCoRef}
          placeholder="Nombre CeCo"
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>Observaciones:</label>
        <input
          required
          type="text"
          ref={observRef}
          placeholder="Algun comentario?"
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.divBotones}>
        {props.nivel === 1 ? (
          <button className={classes.botones} onClick={(ev) => GuardaCeco(ev)}>
            <i className="fas fa-save"></i>
          </button>
        ) : null}
        <button className={classes.botones} onClick={cancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </section>
  );
}

export default EditaCeCo;
