import { useLayoutEffect, useRef, useState } from "react";
import classes from "./EgresosOrdena.module.css";

function MenuOrdenaEgresos(props) {
  const targetRef = useRef();
  const fechaAZRef = useRef();
  const fechaZARef = useRef();
  const montoAZRef = useRef();
  const montoZARef = useRef();
  const ceCoAZRef = useRef();
  const ceCoZARef = useRef();
  const proveedorAZRef = useRef();
  const proveedorZARef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionOrdenaEgresos.x - targetRef.current.offsetWidth,
        y: props.posicionOrdenaEgresos.y,
      });
    }
  }, [props.posicionOrdenaEgresos]);

  function cambioFiltro(ev) {
    props.setMuestraOrdenaEgr(false);
    fechaAZRef.current.checked = ev.target.id === "fechaAZ";
    fechaZARef.current.checked = ev.target.id === "fechaZA";
    montoAZRef.current.checked = ev.target.id === "montoAZ";
    montoZARef.current.checked = ev.target.id === "montoZA";
    ceCoAZRef.current.checked = ev.target.id === "ceCoAZ";
    ceCoZARef.current.checked = ev.target.id === "ceCoZA";
    proveedorAZRef.current.checked = ev.target.id === "proveedorAZ";
    proveedorZARef.current.checked = ev.target.id === "proveedorZA";

    props.setOrdenaEgresos({
      fechaAZ: fechaAZRef.current.checked,
      fechaZA: fechaZARef.current.checked,
      montoAZ: montoAZRef.current.checked,
      montoZA: montoZARef.current.checked,
      ceCoAZ: ceCoAZRef.current.checked,
      ceCoZA: ceCoZARef.current.checked,
      proveedorAZ: proveedorAZRef.current.checked,
      proveedorZA: proveedorZARef.current.checked,
    });
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posicionOrdenaEgresos.y : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionOrdenaEgresos.x : dimensions.x,
      }}
    >
      <label>
        <input
          type="radio"
          id="fechaAZ"
          ref={fechaAZRef}
          defaultChecked={props.ordenaEgresos.fechaAZ}
          onChange={cambioFiltro}
        ></input>
        Fecha <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="fechaZA"
          ref={fechaZARef}
          defaultChecked={props.ordenaEgresos.fechaZA}
          onChange={cambioFiltro}
        ></input>
        Fecha <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="montoAZ"
          ref={montoAZRef}
          defaultChecked={props.ordenaEgresos.montoAZ}
          onChange={cambioFiltro}
        ></input>
        Monto <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="montoZA"
          ref={montoZARef}
          defaultChecked={props.ordenaEgresos.montoZA}
          onChange={cambioFiltro}
        ></input>
        Monto <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="ceCoAZ"
          ref={ceCoAZRef}
          defaultChecked={props.ordenaEgresos.ceCoAZ}
          onChange={cambioFiltro}
        ></input>
        CeCo <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="ceCoZA"
          ref={ceCoZARef}
          defaultChecked={props.ordenaEgresos.ceCoZA}
          onChange={cambioFiltro}
        ></input>
        CeCo <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="proveedorAZ"
          ref={proveedorAZRef}
          defaultChecked={props.ordenaEgresos.proveedorAZ}
          onChange={cambioFiltro}
        ></input>
        Proveedor <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="proveedorZA"
          ref={proveedorZARef}
          defaultChecked={props.ordenaEgresos.proveedorZA}
          onChange={cambioFiltro}
        ></input>
        Proveedor <i className="fas fa-sort-amount-up"></i>
      </label>
    </div>
  );
}

export default MenuOrdenaEgresos;
