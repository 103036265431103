import { Slide, toast } from "react-toastify";
import {
  EnviaDatosAServicio,
  FechaMMMDeString,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import classes from "./IngresoItem.module.css";

function IngresoItem(props) {
  const elimina = async (ev) => {
    ev.preventDefault();
    if (window.confirm("Seguro que quieres eliminar este ingreso?")) {
      const envio = await EnviaDatosAServicio(props.ingreso, "borrarPago");
      if (envio.ERROR === "") {
        toast("Ingreso eliminado", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setRecargar(true);
        /*
        props.setDialogoOK({
          texto: "Ingreso eliminado",
          mostrandoError: false,
          recargar: true,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);*/
        //props.setRecargar(true);
        //alert("Ingreso eliminado");
        //props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al eliminar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al eliminar: " + envio.DESCERROR);
      }
    }
  };

  function QuitaAmpers(observ) {
    if (observ) {
      while (observ.indexOf("&&&") >= 0) {
        observ = observ.substr(observ.indexOf("&&&") + 3);
      }

      return observ;
    }
    return "";
  }

  /******************************************************************************************** */
  /***************************************** RENDER ******************************************* */
  /******************************************************************************************** */

  //if (props.ingreso.Concepto !== "Renta") console.log("props", props);
  return (
    <div
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.ingreso.Concepto === "Renta"
            ? "#fff"
            : props.ingreso.Concepto === "Deposito en garantia"
            ? "#4af"
            : props.ingreso.Concepto === "Duplicado llaves"
            ? "#46f"
            : "#1fc",
      }}
    >
      <label className={classes.nombre}>{props.ingreso.Concepto}</label>
      <label className={classes.nombre}>{props.ingreso.Estudiante}</label>
      <label className={classes.otros}>
        Fecha pago: {FechaMMMDeString(props.ingreso.FechaPag)}
      </label>
      <label className={classes.otros}>
        Forma pago: {props.ingreso.FormaPago}
      </label>
      {props.ingreso.IDHab === "0" ? null : (
        <label className={classes.otros}>
          Habitación: {props.ingreso.Habitacion}
        </label>
      )}
      {props.ingreso.MesQPag !== "0" &&
      props.ingreso.MesQPag !== "error" &&
      props.ingreso.MesQPag !== "-" ? (
        <label className={classes.otros}>
          Mes Q Paga: {FechaMMMDeString(props.ingreso.MesQPag)}
        </label>
      ) : null}
      <label className={classes.otros}>
        Monto: {FormateaCurrency.format(props.ingreso.Monto)}
      </label>
      <label className={classes.otros}>
        {QuitaAmpers(props.ingreso.Observaciones)}
      </label>
      <div className={classes.divBotones}>
        <button id={props.ingreso.IDIng} onClick={props.editaPago}>
          {props.nivel === 1 ? (
            <i id={props.ingreso.IDIng} className="fas fa-edit"></i>
          ) : (
            <i id={props.ingreso.IDIng} className="fas fa-eye"></i>
          )}
        </button>
        {props.nivel === 1 ? (
          <button id={props.ingreso.IDIng} onClick={elimina}>
            <i id={props.ingreso.IDIng} className="fas fa-trash-alt"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default IngresoItem;
