import classes from "./Burger.module.css";
import "../../../FontAwesome/css-fa/all.css";

const Burger = (props) => {
  
  function cambiaEstado() {
    props.setOpened();
  }
  return (
      <label className={classes.burguericon} onClick={cambiaEstado}>
        {<i className='fa fa-bars'></i>} 
      
      </label>
  );
};

export default Burger;
