/*

*/
import { NumericFormat } from "react-number-format";
import classes from "./CuentaEfectivo.module.css";
import { useEffect, useState } from "react";
import {
  EnviaDatosANuevoServicio,
  FechaMMMDeString,
} from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import { Slide, toast } from "react-toastify";
import OKModal from "../dialogos/dialogoOK";
import Backdrop from "../layout/backdrop";

function CuentaEfectivo(props) {
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [i1000Input, seti1000Input] = useState(0);
  const [tot1000, setTot1000] = useState(0);
  const [i500Input, seti500Input] = useState(0);
  const [tot500, setTot500] = useState(0);
  const [i200Input, seti200Input] = useState(0);
  const [tot200, setTot200] = useState(0);
  const [i100Input, seti100Input] = useState(0);
  const [tot100, setTot100] = useState(0);
  const [i50Input, seti50Input] = useState(0);
  const [tot50, setTot50] = useState(0);
  const [i20Input, seti20Input] = useState(0);
  const [tot20, setTot20] = useState(0);
  const [i10Input, seti10Input] = useState(0);
  const [tot10, setTot10] = useState(0);
  const [i5Input, seti5Input] = useState(0);
  const [tot5, setTot5] = useState(0);
  const [i2Input, seti2Input] = useState(0);
  const [tot2, setTot2] = useState(0);
  const [i1Input, seti1Input] = useState(0);
  const [tot1, setTot1] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalApp, setTotalApp] = useState(0);
  const [diferencia, setDiferencia] = useState(0);
  const [ultFecha, setUltFecha] = useState("");
  const [isLoading, setIsLoading] = useState([]);
  const { token } = useToken();
  const idUsu = token.Id;

  function palomeaOK() {
    setMuestraDialogoOK(false);
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    guardaDatos();
  };

  function calculaTotal(Origen, valor) {
    const año = "" + new Date().getFullYear();
    const mes = "" + (new Date().getMonth() + 1);
    const dia = "" + new Date().getDate();
    const fecha =
      año +
      "" +
      (mes.length === 1 ? "0" : "") +
      mes +
      (dia.length === 1 ? "0" : "") +
      dia;
    setUltFecha(fecha);
    //console.log(valor);
    if (valor === null || valor === undefined) {
      valor = 0;
    }
    var Totalloc = 0;
    if (Origen === "1000") {
      setTot1000(valor * 1000);
      seti1000Input(valor);
      Totalloc += valor * 1000;
    } else {
      Totalloc += tot1000;
    }
    //console.log("1000", Totalloc);
    if (Origen === "500") {
      setTot500(valor * 500);
      seti500Input(valor);
      Totalloc += valor * 500;
    } else {
      Totalloc += tot500;
    }
    //console.log("500", Totalloc);
    if (Origen === "200") {
      setTot200(valor * 200);
      seti200Input(valor);
      Totalloc += valor * 200;
    } else {
      Totalloc += tot200;
    }
    //console.log("200", Totalloc);
    if (Origen === "100") {
      setTot100(valor * 100);
      seti100Input(valor);
      Totalloc += valor * 100;
    } else {
      Totalloc += tot100;
    }
    //console.log("100", Totalloc);
    if (Origen === "50") {
      setTot50(valor * 50);
      seti50Input(valor);
      Totalloc += valor * 50;
    } else {
      Totalloc += tot50;
    }
    //console.log("50", Totalloc);
    if (Origen === "20") {
      setTot20(valor * 20);
      seti20Input(valor);
      Totalloc += valor * 20;
    } else {
      Totalloc += tot20;
    }
    //console.log("20", Totalloc);
    if (Origen === "10") {
      setTot10(valor * 10);
      seti10Input(valor);
      Totalloc += valor * 10;
    } else {
      Totalloc += tot10;
    }
    //console.log("10", Totalloc);
    if (Origen === "5") {
      setTot5(valor * 5);
      seti5Input(valor);
      Totalloc += valor * 5;
    } else {
      Totalloc += tot5;
    }
    //console.log("5", Totalloc);
    if (Origen === "2") {
      setTot2(valor * 2);
      seti2Input(valor);
      Totalloc += valor * 2;
    } else {
      Totalloc += tot2;
    }
    //console.log("2", Totalloc);
    if (Origen === "1") {
      setTot1(valor * 1);
      valor !== "" ? seti1Input(valor * 1) : seti1Input("");
      Totalloc += valor * 1;
    } else {
      Totalloc += tot1;
    }
    //console.log("1", Totalloc);
    //console.log("ult", Totalloc);
    setTotal(Totalloc);
  }
  function CalculaDiferencia() {
    const dif = Math.abs(totalApp - total);
    setDiferencia(dif);
  }

  function setActEf(respuesta) {
    if (respuesta.Error === "") {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
    } else {
      setDialogoOK({
        texto: "Error al guardar: " + respuesta.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
  }

  function guardaDatos() {
    const año = "" + new Date().getFullYear();
    const mes = "" + (new Date().getMonth() + 1);
    const dia = "" + new Date().getDate();
    const fecha =
      año +
      "" +
      (mes.length === 1 ? "0" : "") +
      mes +
      (dia.length === 1 ? "0" : "") +
      dia;
    const datos = {
      c1000: i1000Input > 0 ? i1000Input : 0,
      c500: i500Input > 0 ? i500Input : 0,
      c200: i200Input > 0 ? i200Input : 0,
      c100: i100Input > 0 ? i100Input : 0,
      c50: i50Input > 0 ? i50Input : 0,
      c20: i20Input > 0 ? i20Input : 0,
      c10: i10Input > 0 ? i10Input : 0,
      c5: i5Input > 0 ? i5Input : 0,
      c2: i2Input > 0 ? i2Input : 0,
      c1: i1Input > 0 ? i1Input : 0,
      IDCasa: props.casaSel,
      TotApp: totalApp,
      fecha: fecha,
    };
    //console.log(datos);
    EnviaDatosANuevoServicio(
      "ActualizaEfectivo",
      datos,
      setActEf,
      setIsLoading,
      null,
      idUsu
    );
  }

  function fechaModificacion() {
    if (ultFecha !== null) {
      return FechaMMMDeString(ultFecha);
    } else {
      return "Sin actualización";
    }
  }

  function setRespServ(respuesta) {
    var TotalLoc = 0;
    if (respuesta.CuentaEfectivo.length > 0) {
      const CuentaEf = respuesta.CuentaEfectivo[0];
      //console.log("CuentaEf",parseInt(CuentaEf['c1000']))
      seti1000Input(parseInt(CuentaEf["c1000"]));
      setTot1000(parseInt(CuentaEf["c1000"] * 1000));
      TotalLoc += parseInt(CuentaEf["c1000"] * 1000);
      seti500Input(parseInt(CuentaEf["c500"]));
      setTot500(parseInt(CuentaEf["c500"] * 500));
      TotalLoc += parseInt(CuentaEf["c500"] * 500);
      seti200Input(parseInt(CuentaEf["c200"]));
      setTot200(parseInt(CuentaEf["c200"] * 200));
      TotalLoc += parseInt(CuentaEf["c200"] * 200);
      seti100Input(parseInt(CuentaEf["c100"]));
      setTot100(parseInt(CuentaEf["c100"] * 100));
      TotalLoc += parseInt(CuentaEf["c100"] * 100);
      seti50Input(parseInt(CuentaEf["c50"]));
      setTot50(parseInt(CuentaEf["c50"] * 50));
      TotalLoc += parseInt(CuentaEf["c50"] * 50);
      seti20Input(parseInt(CuentaEf["c20"]));
      setTot20(parseInt(CuentaEf["c20"] * 20));
      TotalLoc += parseInt(CuentaEf["c20"] * 20);
      seti10Input(parseInt(CuentaEf["c10"]));
      setTot10(parseInt(CuentaEf["c10"] * 10));
      TotalLoc += parseInt(CuentaEf["c10"] * 10);
      seti5Input(parseInt(CuentaEf["c5"]));
      setTot5(parseInt(CuentaEf["c5"] * 5));
      TotalLoc += parseInt(CuentaEf["c5"] * 5);
      seti2Input(parseInt(CuentaEf["c2"]));
      setTot2(parseInt(CuentaEf["c2"] * 2));
      TotalLoc += parseInt(CuentaEf["c2"] * 2);
      seti1Input(parseFloat(CuentaEf["c1"]));
      setTot1(parseFloat(CuentaEf["c1"]));
      TotalLoc += parseFloat(CuentaEf["c1"]);
      setUltFecha(CuentaEf["Fecha"]);
      setTotal(TotalLoc);
    } else {
      seti1000Input(0);
      setTot1000(0);
      seti500Input(0);
      setTot500(0);
      seti200Input(0);
      setTot200(0);
      seti100Input(0);
      setTot100(0);
      seti50Input(0);
      setTot50(0);
      seti20Input(0);
      setTot20(0);
      seti10Input(0);
      setTot10(0);
      seti5Input(0);
      setTot5(0);
      seti2Input(0);
      setTot2(0);
      seti1Input(0);
      setTot1(0);
      setUltFecha("Sin Actualización");
      setTotal(0);
    }
    if (respuesta.EfectivoApp > 0) {
      setTotalApp(respuesta.EfectivoApp);
      setDiferencia(Math.abs(respuesta.EfectivoApp - TotalLoc));
    } else {
      setTotalApp(0);
      setDiferencia(TotalLoc);
    }
  }

  useEffect(() => {
    props.setRecargar(false);
    setIsLoading(true);
    const datos = {
      IDCasa: props.casaSel,
    };
    //console.log(datos);
    EnviaDatosANuevoServicio(
      "cuentaEfectivo",
      datos,
      setRespServ,
      setIsLoading,
      null,
      idUsu
    );
  }, [idUsu, props]);

  /**********************************************************************************/
  /******************************* render *******************************************/
  /**********************************************************************************/

  if (isLoading) {
    return (
      <section>
        <h1>Cargando efectivo...</h1>
        <h1>
          <i className="fas fa-spinner fa-spin"></i>
        </h1>
      </section>
    );
  }
  //console.log("props cuentaefectivo", props);
  return (
    <form className={classes.back}>
      <div>
        <label className={classes.textos}>Última actualización </label>
        <label className={classes.textoAct}>{fechaModificacion()} </label>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i1000"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i1000Input}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            calculaTotal("1000", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $1,000.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot1000"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          value={tot1000}
          defaultValue={0}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i500"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i500Input}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            calculaTotal("500", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $500.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot500"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot500}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i200"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i200Input}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            calculaTotal("200", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $200.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot200"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot200}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i100"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i100Input}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            calculaTotal("100", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $100.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot100"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot100}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i50"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i50Input}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            calculaTotal("50", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $50.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot50"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot50}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i20"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i20Input}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            calculaTotal("20", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $20.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot20"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot20}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i10"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i10Input}
          onValueChange={(values) => {
            calculaTotal("10", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $10.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot10"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot10}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i5"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i5Input}
          onValueChange={(values) => {
            calculaTotal("5", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $5.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot5"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot5}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i2"
          type="text"
          className={classes.textareaNE}
          decimalScale={0}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          placeholder="0"
          defaultValue={0}
          onFocus={(e) => e.target.select()}
          value={i2Input}
          onValueChange={(values) => {
            calculaTotal("2", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $2.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot2"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot2}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <NumericFormat
          maxLength="5"
          id="i1"
          type="text"
          className={classes.textareaNE}
          placeholder="0"
          defaultValue={0}
          value={i1Input}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onFocus={(e) => e.target.select()}
          onValueChange={(values) => {
            calculaTotal("1", values.floatValue);
          }}
        ></NumericFormat>
        <label className={classes.textos}>de $1.00 dan </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="tot1"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={tot1}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      <div>
        <label className={classes.textoTot}>Total físico:</label>
        <NumericFormat
          maxLength="8"
          id="total"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          defaultValue={0}
          value={total}
          prefix={"$"}
          onFocus={(e) => e.target.select()}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            CalculaDiferencia();
          }}
        ></NumericFormat>
      </div>
      {props.nivel === 1 ? (
        <div className={classes.divBotones}>
          <button className={classes.botones} onClick={submitHandler}>
            Actualizar
          </button>
        </div>
      ) : null}
      <div>
        <label className={classes.textoTot}>Total app:</label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="totalApp"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          value={totalApp}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            CalculaDiferencia();
          }}
        ></NumericFormat>
      </div>
      <div>
        <label className={classes.textoTot}>
          {totalApp - total > 0 ? "Faltan" : "Sobran"}:
        </label>
        <NumericFormat
          readOnly={true}
          maxLength="8"
          id="diferencia"
          type="text"
          className={classes.textareaTot}
          placeholder="$0.00"
          value={diferencia}
          prefix={"$"}
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
        ></NumericFormat>
      </div>
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
    </form>
  );
}

export default CuentaEfectivo;
