import { useRef } from "react";
import { EnviaDatosAServicio } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./CeCoNuevo.module.css";
import { Slide, toast } from "react-toastify";

function NuevoCeCo(props) {
  const CeCoRef = useRef();
  const observRef = useRef();
  const { token } = useToken();
  const idCasa = token.casaSel;

  const GuardaCeco = async (ev) => {
    ev.preventDefault();
    if (CeCoRef.current.value === "") {
      props.setDialogoOK({
        texto: "por favor llena el nombre del Centro de Costos",
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("por favor llena el nombre del Centro de Costos");
    } else {
      const CeCoN = {
        IDCeCo: "0",
        IDCasa: idCasa,
        CeCo: CeCoRef.current.value,
        Observaciones: observRef.current.value,
      };
      const envio = await EnviaDatosAServicio(CeCoN, "nuevoCeCo");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setMuestraNuevoCeCo(false);
        props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    }
  };

  function cancelaNuevo(ev) {
    ev.preventDefault();
    props.setMuestraNuevoCeCo(false);
  }

  return (
    <section className={classes.modal}>
      <h1>Nuevo CeCo</h1>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>Centro Costos:</label>
        <input
          required
          type="text"
          ref={CeCoRef}
          placeholder="Nombre CeCo"
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>Observaciones:</label>
        <input
          required
          type="text"
          ref={observRef}
          placeholder="Algun comentario?"
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.divBotones}>
        <button className={classes.botones} onClick={(ev) => GuardaCeco(ev)}>
          <i className="fas fa-save"></i>
        </button>
        <button className={classes.botones} onClick={cancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </section>
  );
}

export default NuevoCeCo;
