import { useEffect, useRef, useState } from "react";
import classes from "./RecurrenteNuevo.module.css";
import useToken from "../login/useToken";
import {
  EnviaDatosANuevoServicio,
  EnviaDatosAServicio,
  FechaMMMDeString,
  FechaParaInputDate,
} from "../../Funciones/Funciones";
import { Slide, toast } from "react-toastify";
import Select from "react-select";
import OKModal from "../dialogos/dialogoOK";
import Backdrop from "../layout/backdrop";

function NuevoRecurrente(props) {
  const [ceCosLoaded, setCeCosLoaded] = useState([]);
  const [proveedoresLoaded, setProveedoresLoaded] = useState([]);
  const [frecuenciasLoaded, setFrecuenciasLoaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ceCoRef = useRef();
  const proveedorRef = useRef();
  const fechaIniRef = useRef();
  const frecuenciaRef = useRef();
  const NombreRef = useRef();
  const token = useToken().token;
  const idCasa = token.casaSel;
  const idUsu = token.Id;
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);

  function respGuarda(resp) {
    if (resp.Error !== "SI") {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });

      props.setMuestraNuevoRecurrente(false);
      props.setRecargar(true);
    } else {
      setDialogoOK({
        texto: "Error: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
  }

  function palomeaOK(resp) {
    setMuestraDialogoOK(false);
  }

  function RespServ(respuesta) {
    const ceCoT = respuesta.RCeCos.map((ceco) => {
      return {
        value: ceco.IDCeCo,
        label: ceco.CeCo,
      };
    });
    const provT = respuesta.RProveedores.map((prov) => {
      return { value: prov.IDProv, label: prov.Proveedor };
    });
    const frecT = respuesta.Frecuencias.map((frec, i) => {
      return { value: i, label: frec.Frecuencia };
    });
    setCeCosLoaded(ceCoT);
    setProveedoresLoaded(provT);
    setFrecuenciasLoaded(frecT);
  }

  useEffect(() => {
    if (idCasa > 0) {
      setIsLoading(true);
      //console.log(token);
      const datos = {
        IDCasa: idCasa,
      };
      EnviaDatosANuevoServicio(
        "IniciaNuevoPagoProgramado",
        datos,
        RespServ,
        setIsLoading,
        null,
        idUsu
      );
      /*
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "iniciaNuevoEgreso",
          IDCasa: idCasa,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var ceCos = [];
          var proveedores = [];
          var formasPago = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "RCeCos") {
                ceCos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "RProveedores") {
                proveedores = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "RFormasPago") {
                formasPago = Object.keys(datos).map((key) => datos[key]);
              }
            }
            const ceCoT = ceCos.map((ceco) => {
              return {
                value: ceco.IDCeCo,
                label: ceco.CeCo,
              };
            });
            const provT = proveedores.map((prov) => {
              return { value: prov.IDProv, label: prov.Proveedor };
            });
            const fpT = formasPago.map((fp) => {
              return { value: fp.IDForPag, label: fp.FormaPago };
            });
            //console.log("ceCos", ceCos, ceCoT);
            //console.log("proveedores", proveedores);
            //console.log("recurrente", props.recurrente);
            //console.log("formasPago", fpT);
            if (props.tipo === "Recurrente") {
              const CeCoR = ceCoT.filter((ceco) => {
                return ceco.value === props.recurrente.IDCeco;
              });
              const ProvR = provT.filter((prov) => {
                return prov.value === props.recurrente.IDProv;
              });
              //console.log("Filtro", CeCoR);
              setCeCosLoaded(CeCoR);
              setProveedoresLoaded(ProvR);
            } else {
              setCeCosLoaded(ceCoT);
              setProveedoresLoaded(provT);
            }
            setFormasPagoLoaded(fpT);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.DESCERROR);
          }
        });*/
    } else {
      setIsLoading(false);
    }
  }, [idCasa, idUsu]);

  function CancelaNuevo(ev) {
    ev.preventDefault();
    props.setMuestraNuevoRecurrente(false);
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      //console.log("IDCeCo", ceCoRef.current.getValue()[0].value);
      const recurrenteN = {
        IDRec: "",
        IDCasa: idCasa,
        IDCeCo: ceCoRef.current.getValue()[0].value,
        IDProv: proveedorRef.current.getValue()[0].value,
        Frecuencia: frecuenciaRef.current.getValue()[0].label,
        FechaIni: fechaIniRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Nombre: NombreRef.current.value,
      };
      EnviaDatosANuevoServicio(
        "NuevoRecurrente",
        recurrenteN,
        respGuarda,
        setIsLoading,
        null,
        idUsu
      );
      /*const envio = await EnviaDatosAServicio(egresoN, "nuevoEgreso");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });

        props.setMostrarNuevo(false);
        props.setRecargar(true);
      } else {
        setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }*/
    } else {
      setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";
    if (ceCoRef.current.getValue().length === 0) {
      respuesta = respuesta + "Nombre de Centro de Costos\n";
    }
    if (proveedorRef.current.getValue().length === 0) {
      respuesta = respuesta + "Nombre del proveedor\n";
    }
    if (frecuenciaRef.current.getValue().length === 0) {
      respuesta = respuesta + "Frecuencia\n";
    }
    if (NombreRef.current.value === "") {
      respuesta = respuesta + "Nombre\n";
    }

    return respuesta;
  }

  /**************************************************************************************/
  /************************************ render ******************************************/
  /**************************************************************************************/

  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button className={classes.botTop} onClick={CancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1>Nuevo Pago Programado</h1>
      <form onSubmit={submitHandler}>
        <div>
          <div className={classes.contSelect}>
            <label className={classes.labelP}>CeCo:</label>
            <Select
              ref={ceCoRef}
              className={classes.textareaNE1}
              defaultValue={
                props.tipo === "Recurrente"
                  ? ceCosLoaded.find(
                      (valor) => valor.value === props.recurrente.IDCeco
                    )
                  : null
              }
              options={ceCosLoaded}
            />
          </div>
          <div className={classes.contSelect}>
            <label className={classes.labelP}>Proveedor:</label>
            <Select
              ref={proveedorRef}
              className={classes.textareaNE1}
              defaultValue={
                props.tipo === "Recurrente"
                  ? proveedoresLoaded.find(
                      (valor) => valor.value === props.recurrente.IDProv
                    )
                  : null
              }
              options={proveedoresLoaded}
            />
          </div>
          <div className={classes.contSelect}>
            <label className={classes.labelP}>Frecuencia:</label>
            <Select
              ref={frecuenciaRef}
              className={classes.textareaNE1}
              options={frecuenciasLoaded}
            />
          </div>
          <div>
            <label className={classes.labelP}>Nombre</label>
            <input
              type="text"
              ref={NombreRef}
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Fecha de inicio</label>
            <input
              required
              id="fechaPag"
              ref={fechaIniRef}
              type="date"
              defaultValue={FechaParaInputDate()}
              className={classes.textareaNE}
            ></input>
          </div>
        </div>
      </form>
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
    </section>
  );
}

export default NuevoRecurrente;
