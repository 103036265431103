import classes from "./backdrop.module.css";

function Backdrop(props) {
  function recarga() {
    if (props.recarga) {
      props.recarga();
    }
  }
  return <div style={{ zIndex: props.zindex ? props.zindex : 1 }} className={classes.backdrop} onClick={recarga} />;
}

export default Backdrop;
