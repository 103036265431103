import { useCallback, useEffect, useRef, useState } from "react";
import {
  differenceInCalendarDays,
  compareAsc,
  addMonths,
  getYear,
  getMonth,
} from "date-fns";

import classes from "./EstudianteDescuento.module.css";
import useFitText from "../layout/FitText";
import useToken from "../login/useToken";
import {
  FormateaCurrency,
  DejaSoloNumerosDeCurrency,
  FormateaPorcentaje,
  EnviaDatosAServicio,
  NumeroAMes,
  FuncionValidaDatos,
  FechaDeString,
  FechaParaInputDate,
} from "../../Funciones/Funciones";
import { Slide, toast } from "react-toastify";

function validaCurrency(event) {
  FuncionValidaDatos(event, "currency");
}
function validaPorcentaje(event) {
  if (event.target.selectionEnd > event.target.selectionStart) {
    event.target.value =
      event.target.value.slice(0, event.target.selectionStart) +
      event.target.value.slice(event.target.selectionEnd);
  }
  var key = "";
  if (event.type === "paste") {
    key = event.clipboardData.getData("text/plain");
  } else if (event.type === "keypress") {
    key = event.key;
  } else {
    key = event;
  }
  if (
    (event.target.value + key).match(
      "^[0-9]{1,2}([.]?)(?:[0-9]{1,2})?(?:%)?(?<![0-9]{3})$"
    ) === null
  ) {
    event.returnValue = false;
    if (event.preventDefault) event.preventDefault();
  }
}
function DescuentoEstudiante(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [vacaciones, setVacaciones] = useState([]);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [mostrarDescExtra, setmostrarDescExtra] = useState(false);
  const [ultimoPago, setUltimoPago] = useState([]);
  const [datosDetalle, setDatosDetalle] = useState({
    Descuento: "20",
    Enviado: "",
    Fin: "",
    IDEst: props.estudiante.IDEst,
    IDVac: "",
    Inicio: "",
    MesQAplica: "",
    Observaciones: "",
    Pago: "",
  });
  const { fontSize, ref } = useFitText();
  const token = useToken().token;
  const idCasa = token.casaSel;
  const idEst = props.estudiante.IDEst;

  const NumDescRef = useRef();
  const fechaInicioRef = useRef();
  const fechaFinRef = useRef();
  const diasRef = useRef();
  const rentaRef = useRef();
  const descRef = useRef();
  const montoRef = useRef();
  const mesRef = useRef();
  const observRef = useRef();
  const diasDifRef = useRef();
  const descDifRef = useRef();
  const montoDescExtraRef = useRef();
  const montoPagoFinalRef = useRef();
  const descExtraCheckRef = useRef();

  const CalculaDescExtra = useCallback(() => {
    if (descDifRef.current.value === "") descDifRef.current.value = "10%";
    if (diasRef.current.value !== "" && diasRef.current.value < 30)
      diasDifRef.current.value = 30 - parseInt(diasRef.current.value);
    const rentaDiaria = parseFloat(props.estudiante.Precio) / 30;
    const descDiario =
      parseFloat(rentaDiaria) *
      (parseFloat(parseFloat(descDifRef.current.value)) / 100);
    montoDescExtraRef.current.value = FormateaCurrency.format(
      parseFloat(descDiario) * parseInt(diasDifRef.current.value)
    );
    montoPagoFinalRef.current.value = FormateaCurrency.format(
      DejaSoloNumerosDeCurrency(montoRef.current.value) -
        DejaSoloNumerosDeCurrency(montoDescExtraRef.current.value)
    );
  }, [props.estudiante.Precio]);

  const CalculaCantidades = useCallback(
    (queCambio) => {
      //console.log("CalcCant");
      if (diasRef.current.value !== "") {
        if (descRef.current.value === "" && queCambio !== "Desc")
          descRef.current.value = "20%";
        if (parseFloat(descRef.current.value) > 0) {
          const descDiario =
            (parseFloat(props.estudiante.Precio) / 30) *
            (parseFloat(descRef.current.value) / 100);
          montoRef.current.value = FormateaCurrency.format(
            parseFloat(props.estudiante.Precio) -
              descDiario * parseInt(diasRef.current.value)
          );
          if (mostrarDescExtra) {
            if (descExtraCheckRef.current.checked) {
              CalculaDescExtra();
            }
          }
        } else {
          montoRef.current.value = "$0.00";
        }
      }
    },
    [CalculaDescExtra, mostrarDescExtra, props.estudiante.Precio]
  );

  useEffect(() => {
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "DatosVacaciones",
        IDCasa: idCasa,
        IDEst: idEst,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log("data INGNVO", data);
        if (data.ERROR !== "SI") {
          var vacaciones = [];
          var ultimP = [];
          var inqui = [];
          for (const key in data) {
            //console.log("key ", key, data[key]);
            const datos = {
              ...data[key],
            };
            if (key === "Vacaciones") {
              vacaciones = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "UltimoPago") {
              ultimP = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Inquilinos") {
              inqui = Object.keys(datos).map((key) => datos[key]);
            }
          }
          //console.log("inquilinos", inqui);
          //console.log("vacaciones", vacaciones);
          //console.log("ultimopago", ultimP[0].MesQPag);
          setIsLoading(false);
          setVacaciones(vacaciones);
          //vamos a generar los meses posibles de descuento. deben de ser del ultimo pago al mes siguiente del actual
          //creamos la fecha con el mes siguiente del actual
          var FechaSig = new Date();
          FechaSig = new Date(
            FechaSig.getFullYear(),
            FechaSig.getMonth(),
            FechaSig.getDate()
          );
          //le agregamos un mes
          FechaSig = addMonths(FechaSig, 1);
          //el mes de inicio (si no hay otro) va a ser el mes de ingreso
          //creamos la fecha con el mes de ingreso
          var Fecha = new Date(
            inqui[0].Datos.FechaIng.slice(0, 4),
            inqui[0].Datos.FechaIng.slice(4, 6) - 1,
            inqui[0].Datos.FechaIng.slice(6, 8)
          );
          //si hay mes de ultimo descuento
          if (vacaciones.length > 0) {
            //console.log(vacaciones);
            if (vacaciones[0].MesQAplica) {
              //creamos la fecha con ese mes
              Fecha = new Date(
                vacaciones[0].MesQAplica.slice(0, 4),
                vacaciones[0].MesQAplica.slice(4, 6) - 1,
                1
              );
              //y le agregamos un mes para iniciar en el siguiente del que ya existe descuento
              Fecha = addMonths(Fecha, 1);
            }
          } //si no hay mes de ultimo descuento, pero hay mes de ultimo pago
          else if (ultimP[0].MesQPag) {
            //creamos la fecha con ese mes
            Fecha = new Date(
              ultimP[0].MesQPag.slice(0, 4),
              ultimP[0].MesQPag.slice(4, 6) - 1,
              1
            );
          }
          var tempUltimP = [];
          //verificamos si el ultimo pago es adelantado (fechasig es el mes actual mas 1, fecha es fecha de ingreso, fecha de ultimo descuento o fecha de ultimo pago)
          if (FechaSig < Fecha) {
            FechaSig = addMonths(Fecha, 1);
          }
          //agregamos a tempUltimP los meses faltantes para llegar a mes actual
          while (FechaSig >= Fecha) {
            //console.log("fechasig", FechaSig, "fecha", Fecha);
            var mes = (getMonth(Fecha) + 1).toString();
            if (mes.length === 1) mes = "0" + mes;
            tempUltimP.push({
              IDEst: inqui[0].Datos.IDEst,
              MesQPag: getYear(Fecha).toString() + mes,
            });
            Fecha = addMonths(Fecha, 1);
          }
          setUltimoPago(tempUltimP);
          //console.log("ultimP ", ultimP);
        } else {
          setIsLoading(false);
          console.log("ERROR", data.ERROR);
        }
      });
  }, [idCasa, idEst]);

  useEffect(() => {
    if (datosDetalle.IDVac !== "") {
      fechaInicioRef.current.value = FechaParaInputDate(datosDetalle.Inicio);
      fechaFinRef.current.value = FechaParaInputDate(datosDetalle.Fin);
      descRef.current.value = datosDetalle.Descuento + "%";
      montoRef.current.value = FormateaCurrency.format(datosDetalle.Pago);
      mesRef.current.value = datosDetalle.MesQAplica;
      const fechaI = new Date(FechaParaInputDate(datosDetalle.Inicio));
      const fechaF = new Date(FechaParaInputDate(datosDetalle.Fin));

      if (compareAsc(fechaF, fechaI) >= 0) {
        //console.log("dif", fechaF, fechaI, differenceInCalendarDays(fechaF, fechaI));
        const d = differenceInCalendarDays(fechaF, fechaI);
        diasRef.current.value = d;
        CalculaCantidades("dias");
        if (d < 30 && d > 0) {
          setmostrarDescExtra(true);
        } else {
          setmostrarDescExtra(false);
        }
      }
    }
  }, [datosDetalle, CalculaCantidades]);

  function cambioDescDif() {
    if (
      descDifRef.current.value.match(
        "^[0-9]{1,2}(?:)(?:.)?(?:[0-9]{1,2})?(?:%)?$"
      ) !== null
    ) {
      CalculaDescExtra();
    }
  }
  function cambiodescExtraCheck(ev) {
    //console.log(ev);
    if (ev.target.checked) {
      CalculaDescExtra();
    } else {
      CalculaCantidades();
    }
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      var _IDVac = "0";
      if (NumDescRef.current.value !== "") {
        _IDVac = NumDescRef.current.value;
      }
      const descN = {
        IDVac: _IDVac,
        IDEst: props.estudiante.IDEst,
        Inicio: fechaInicioRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Fin: fechaFinRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Pago: DejaSoloNumerosDeCurrency(montoRef.current.value),
        Descuento: parseFloat(descRef.current.value),
        Enviado: "SI",
        MesQAplica: mesRef.current.value,
        Observaciones: observRef.current.value,
      };
      if (mostrarDescExtra) {
        if (descExtraCheckRef.current.checked) {
          const descNvo = FormateaPorcentaje.format(
            parseFloat(
              DejaSoloNumerosDeCurrency(montoPagoFinalRef.current.value)
            ) / parseFloat(props.estudiante.Precio)
          );
          descN.Descuento = parseFloat(descNvo);
          descN.Pago = DejaSoloNumerosDeCurrency(
            montoPagoFinalRef.current.value
          );
        }
      }
      console.log("descN", descN);
      const envio = await EnviaDatosAServicio(descN, "GuardaDescuento");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setMostrarDescuentos(false);
        props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";
    if (
      compareAsc(
        new Date(fechaInicioRef.current.value),
        new Date(fechaFinRef.current.value)
      ) === 0
    ) {
      respuesta = respuesta + "Fecha Inicio no puede ser igual a Fecha Fin\n";
    } else if (
      compareAsc(
        new Date(fechaInicioRef.current.value),
        new Date(fechaFinRef.current.value)
      ) > 0
    ) {
      respuesta = respuesta + "Fecha Inicio no puede ser mayor a Fecha Fin\n";
    }
    if (!parseFloat(descRef.current.value)) {
      respuesta = respuesta + "El descuento no puede ser cero\n";
    } else {
      if (
        descRef.current.value.match(
          "^[0-9]{1,2}([.]?)(?:[0-9]{1,2})?(?:%)?(?<![0-9]{3})$"
        ) === null
      ) {
        respuesta = respuesta + "El descuento no se entiende\n";
      }
    }
    if (parseFloat(DejaSoloNumerosDeCurrency(montoRef.current.value))) {
      console.log("solonum", DejaSoloNumerosDeCurrency(montoRef.current.value));
      if (
        ///^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
        montoRef.current.value.match(
          /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
        ) === null
      ) {
        respuesta = respuesta + "El monto no se entiende\n";
      }
    }
    if (mesRef.current.value === "0") {
      respuesta = respuesta + "Selecciona el mes que aplica\n";
    }
    if (mostrarDescExtra) {
      if (descExtraCheckRef.checked) {
        if (!parseFloat(descDifRef.current.value)) {
          respuesta = respuesta + "El descuento extra no puede ser cero\n";
        } else {
          if (
            descDifRef.current.value.match(
              "^[0-9]{1,2}([.]?)(?:[0-9]{1,2})?(?:%)?(?<![0-9]{3})$"
            ) === null
          ) {
            respuesta = respuesta + "El descuento extra no se entiende\n";
          }
        }
      }
    }
    return respuesta;
  }

  const eliminaDescuento = async (ev) => {
    ev.preventDefault();
    const vaca = vacaciones.filter((vacat) => ev.target.id === vacat.IDVac);
    //console.log("vaca", vaca);
    if (
      window.confirm(
        "Seguro que quieres eliminar la ausencia del " +
          FechaDeString(vaca[0].Inicio) +
          " al " +
          FechaDeString(vaca[0].Fin)
      )
    ) {
      const envio = await EnviaDatosAServicio(vaca[0], "EliminaDescuento");
      if (envio.ERROR === "") {
        props.setDialogoOK({
          texto: "Eliminado OK",
          mostrandoError: false,
          recargar: false,
          cerrarVentana: "mostrarDescuentos",
        });
        props.setMuestraDialogoOK(true);
        //alert("Eliminado OK");
        //props.setMostrarDescuentos(false);
      } else {
        props.setDialogoOK({
          texto: "Error al eliminar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al eliminar: " + envio.DESCERROR);
      }
    }
  };

  function modificaDescuento(ev) {
    ev.preventDefault();
    //buscamos el descuento
    const vaca = vacaciones.filter((vacat) => ev.target.id === vacat.IDVac);
    setDatosDetalle(vaca[0]);
    setMostrarDetalle(true);
    //en vaca[0] traemos los datos buscados
  }
  function AgregaDescuento(ev) {
    ev.preventDefault();
    setmostrarDescExtra(false);
    const vaca = {
      Descuento: "20",
      Enviado: "",
      Fin: "",
      IDEst: props.estudiante.IDEst,
      IDVac: "",
      Inicio: "",
      MesQAplica: "",
      Observaciones: "",
      Pago: "",
    };
    setDatosDetalle(vaca);
    setMostrarDetalle(true);
  }
  function CancelaNuevo(ev) {
    ev.preventDefault();
    setMostrarDetalle(false);
  }
  function Cierra(ev) {
    ev.preventDefault();
    props.setMostrarDescuentos(false);
  }
  function cambioFecha(ev) {
    const fechaI = new Date(fechaInicioRef.current.value);
    const fechaF = new Date(fechaFinRef.current.value);

    if (compareAsc(fechaF, fechaI) >= 0) {
      const d = differenceInCalendarDays(fechaF, fechaI);
      diasRef.current.value = d;
      CalculaCantidades("dias");
      if (d < 30 && d > 0) {
        console.log("menos de 30");
        setmostrarDescExtra(true);
      } else {
        console.log("mas de 30");
        setmostrarDescExtra(false);
      }
    } else {
      if (ev.target.id === "FInicio")
        fechaFinRef.current.value = fechaInicioRef.current.value;
      else fechaInicioRef.current.value = fechaFinRef.current.value;
    }
  }

  function cambioDesc() {
    if (
      descRef.current.value.match(
        "^[0-9]{1,2}(?:)(?:.)?(?:[0-9]{1,2})?(?:%)?$"
      ) !== null
    )
      CalculaCantidades("Desc");
  }
  function cambioMonto(event) {
    if (event.target.selectionEnd > event.target.selectionStart) {
      event.target.value =
        event.target.value.slice(0, event.target.selectionStart) +
        event.target.value.slice(event.target.selectionEnd);
    }

    if (
      montoRef.current.value.match(
        "^[0-9]{1,2}(?:)(?:.)?(?:[0-9]{1,2})?(?:%)?$"
      ) !== null
    )
      if (diasRef.current.value !== "") {
        const monto = DejaSoloNumerosDeCurrency(montoRef.current.value);
        if (parseInt(monto) > 0) {
          const rentaDiaria = parseFloat(props.estudiante.Precio) / 30;
          const DescxAus =
            parseFloat(props.estudiante.Precio) - parseFloat(monto);
          descRef.current.value = FormateaPorcentaje.format(
            parseFloat(DescxAus) /
              parseFloat(diasRef.current.value) /
              parseFloat(rentaDiaria)
          );
          if (mostrarDescExtra) {
            if (descExtraCheckRef.current.checked) {
              CalculaDescExtra();
            }
          }
        } else {
          descRef.current.value = "100%";
        }
      }
  }
  /***************************************************************** */
  /*****************************RENDER ************************************ */
  /***************************************************************** */

  //console.log(props);
  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={AgregaDescuento}>
            <i className="fas fa-plus"></i>
          </button>
        )}
        <button className={classes.botCancela} onClick={Cierra}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc} ref={ref} style={{ fontSize }}>
        {props.estudiante.Nombre}
      </h1>
      <label className={classes.tituloP + " " + classes.labelP}>
        Ultimas ausencias de la casa
      </label>
      <table className={classes.tabla}>
        <thead>
          <tr>
            <td>INICIO</td>
            <td>FIN</td>
            <td>MES</td>
            <td>MONTO</td>
          </tr>
        </thead>
        <tbody>
          {vacaciones.map((vaca, indice) => {
            return (
              <tr key={vaca.IDVac}>
                <td>{FechaDeString(vaca.Inicio)}</td>
                <td>{FechaDeString(vaca.Fin)}</td>
                <td>{FechaDeString(vaca.MesQAplica)}</td>
                <td>{FormateaCurrency.format(vaca.Pago)}</td>
                <td className={classes.tabbot}>
                  <button
                    onClick={modificaDescuento}
                    id={vaca.IDVac}
                    className={classes.innbtn}
                  >
                    {props.nivel === 1 ? (
                      <i id={vaca.IDVac} className="fas fa-pencil-alt"></i>
                    ) : (
                      <i id={vaca.IDVac} className="fas fa-eye"></i>
                    )}
                  </button>
                  {props.nivel === 1 ? (
                    <button
                      onClick={eliminaDescuento}
                      id={vaca.IDVac}
                      className={classes.innbtn}
                    >
                      <i id={vaca.IDVac} className="fas fa-times"></i>
                    </button>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {mostrarDetalle && (
        <div className={classes.subcont}>
          <div className={classes.contBotTop2}>
            {props.nivel === 1 && (
              <button className={classes.botTop} onClick={submitHandler}>
                <i className="fas fa-save"></i>
              </button>
            )}
            <button className={classes.botTop} onClick={CancelaNuevo}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <label className={classes.tituloP + " " + classes.labelP}>
            {props.nivel === 1 ? "AGREGAR/EDITAR DESCUENTO" : "VER DESCUENTO"}
          </label>
          <div>
            <label className={classes.labelP}># Desc.</label>
            <input
              readOnly
              type="text"
              placeholder="Por Definir"
              ref={NumDescRef}
              defaultValue={datosDetalle.IDVac}
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Inicio</label>
            <input
              required
              id="FInicio"
              ref={fechaInicioRef}
              type="date"
              defaultValue={
                datosDetalle.Inicio === ""
                  ? new Date().toISOString().substr(0, 10)
                  : FechaParaInputDate(datosDetalle.Inicio)
              }
              className={classes.textareaNE}
              onChange={cambioFecha}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Fin</label>
            <input
              required
              id="FFin"
              ref={fechaFinRef}
              type="date"
              defaultValue={
                datosDetalle.Fin === ""
                  ? new Date().toISOString().substr(0, 10)
                  : FechaParaInputDate(datosDetalle.Fin)
              }
              className={classes.textareaNE}
              onChange={cambioFecha}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Días</label>
            <input
              readOnly
              type="text"
              id="dias"
              ref={diasRef}
              placeholder="Cambia las fechas"
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Renta</label>
            <input
              readOnly
              type="text"
              id="renta"
              ref={rentaRef}
              value={FormateaCurrency.format(props.estudiante.Precio)}
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>% Desc</label>
            <input
              type="text"
              id="desc"
              ref={descRef}
              placeholder="20%"
              defaultValue={
                datosDetalle.Descuento === ""
                  ? ""
                  : datosDetalle.Descuento + "%"
              }
              className={classes.textareaNE}
              onChange={cambioDesc}
              onPaste={validaPorcentaje}
              onKeyPress={validaPorcentaje}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Monto</label>
            <input
              type="text"
              id="monto"
              ref={montoRef}
              placeholder="$0.00"
              defaultValue={
                datosDetalle.Pago === ""
                  ? ""
                  : FormateaCurrency.format(datosDetalle.Pago)
              }
              className={classes.textareaNE}
              onPaste={validaCurrency}
              onKeyPress={validaCurrency}
              onChange={cambioMonto}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Mes Aplica</label>
            <select
              className={classes.textareaNE}
              required
              id="MesAplica"
              ref={mesRef}
              defaultValue={
                datosDetalle.MesQAplica === ""
                  ? ultimoPago[0].MesQPag
                  : datosDetalle.MesQAplica
              }
            >
              <option key="0" value={"0"}>
                Selecciona una opcion
              </option>
              {datosDetalle.MesQAplica !== "" ? (
                <option
                  key={datosDetalle.MesQAplica}
                  value={datosDetalle.MesQAplica}
                >
                  {NumeroAMes(datosDetalle.MesQAplica.slice(4, 6)) +
                    " " +
                    datosDetalle.MesQAplica.slice(0, 4)}
                </option>
              ) : null}
              {ultimoPago.map((ultimP, indice) => {
                return (
                  <option key={ultimP.MesQPag} value={ultimP.MesQPag}>
                    {NumeroAMes(ultimP.MesQPag.slice(4, 6)) +
                      " " +
                      ultimP.MesQPag.slice(0, 4)}
                  </option>
                );
              })}
            </select>
          </div>
          {mostrarDescExtra && (
            <div className={classes.descExtra}>
              <div>
                <input
                  type="checkbox"
                  id="descExtraCheck"
                  ref={descExtraCheckRef}
                  onChange={cambiodescExtraCheck}
                ></input>
                <label htmlFor="descExtraCheck" className={classes.labelCh}>
                  Calcular descuento extra
                </label>
              </div>
              <div>
                <label className={classes.labelP}>Días Dif</label>
                <input
                  readOnly
                  type="text"
                  id="diasDif"
                  ref={diasDifRef}
                  className={classes.textareaNE}
                ></input>
              </div>
              <div>
                <label className={classes.labelP}>% Desc Dif</label>
                <input
                  type="text"
                  id="descDif"
                  ref={descDifRef}
                  placeholder="10%"
                  className={classes.textareaNE}
                  onChange={cambioDescDif}
                  onPaste={validaPorcentaje}
                  onKeyPress={validaPorcentaje}
                ></input>
              </div>
              <div>
                <label className={classes.labelP}>Monto Desc Extra</label>
                <input
                  readOnly
                  type="text"
                  id="montoDescExtra"
                  ref={montoDescExtraRef}
                  className={classes.textareaNE}
                ></input>
              </div>
              <div>
                <label className={classes.labelP}>Monto Pago Final</label>
                <input
                  readOnly
                  type="text"
                  id="montoPagoFinal"
                  ref={montoPagoFinalRef}
                  className={classes.textareaNE}
                ></input>
              </div>
            </div>
          )}
          <div>
            <label className={classes.labelP}>Observaciones</label>
            <input
              type="text"
              id="observaciones"
              ref={observRef}
              placeholder="Comentarios?"
              className={classes.textareaNE}
            ></input>
          </div>
        </div>
      )}
      
    </section>
  );
}

export default DescuentoEstudiante;
