import classes from "./EstudianteFiltroMenu.module.css";
import { useLayoutEffect, useRef, useState } from "react";

function MenuFiltraEstudiantes(props) {
  const targetRef = useRef();
  const sinAdeudoRef = useRef();
  const conAdeudoRef = useRef();
  const porNombreRef = useRef();
  const porHabitacionRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionFiltroEstudiantes.x - targetRef.current.offsetWidth,
        y: props.posicionFiltroEstudiantes.y,
      });
    }
  }, [props.posicionFiltroEstudiantes]);

  function cambioFiltro() {
    props.setFiltroEstudiantes({
      sinAdeudo: sinAdeudoRef.current.checked,
      conAdeudo: conAdeudoRef.current.checked,
      porNombre: porNombreRef.current.value,
      porHabitacion: porHabitacionRef.current.value,
    });
    //console.log("cambiofiltro", sinAdeudoRef.current.checked);
  }

  function quitaFiltros(ev) {
    ev.preventDefault();
    props.setFiltroEstudiantes({
      sinAdeudo: true,
      conAdeudo: true,
      porNombre: "",
      porHabitacion: "",
    });
    props.setMuestraFiltroEst(false);
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top:
          dimensions.y === 0 ? props.posicionFiltroEstudiantes.y : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionFiltroEstudiantes.x : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <div className={classes.contCheckboxes}>
        <label>
          <input
            type="checkbox"
            ref={sinAdeudoRef}
            defaultChecked={props.filtroEstudiantes.sinAdeudo}
            onChange={cambioFiltro}
          ></input>
          Sin Adeudo
        </label>
        <label>
          <input
            type="checkbox"
            ref={conAdeudoRef}
            defaultChecked={props.filtroEstudiantes.conAdeudo}
            onChange={cambioFiltro}
          ></input>
          Con Adeudo
        </label>
        <label>Por nombre:</label>
        <input
          type="text"
          ref={porNombreRef}
          defaultValue={props.filtroEstudiantes.porNombre}
          onChange={cambioFiltro}
        ></input>
        <label>Por habitacion:</label>
        <input
          type="text"
          ref={porHabitacionRef}
          defaultValue={props.filtroEstudiantes.porHabitacion}
          onChange={cambioFiltro}
        ></input>
        <div className={classes.botok}>
          <button className={classes.botones} onClick={() => props.setMuestraFiltroEst(false)}>
            <i className="fas fa-check"></i>
          </button>
          <button className={classes.botones} onClick={quitaFiltros}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MenuFiltraEstudiantes;
