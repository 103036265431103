import { useRef, useState, useLayoutEffect } from "react";
import classes from "./LlavesFiltra.module.css";

function MenuFiltraLlaves(props) {
  const targetRef = useRef();
  const asignadaARef = useRef();
  const llaveRef = useRef();
  const observRef = useRef();
  const OKRef = useRef();
  const perdidaRef = useRef();
  const noSirveRef = useRef();
  const asignadaRef = useRef();
  const noAsignadaRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  function cancelaFiltro() {
    asignadaARef.current.value = "";
    llaveRef.current.value = "";
    observRef.current.value = "";
    props.setFiltroLlaves({
      ...props.filtroLlaves,
      ...{
        OK: true,
        perdida: false,
        noSirve: false,
        asignada: true,
        noAsignada: true,
        asignadaA: "",
        llave: "",
        observ: "",
      },
    });
    props.setMuestraFiltroLlav(false);
  }

  function cambioFiltro() {
    props.setFiltroLlaves({
      ...props.filtroLlaves,
      ...{
        OK: OKRef.current.checked,
        perdida: perdidaRef.current.checked,
        noSirve: noSirveRef.current.checked,
        asignada: asignadaRef.current.checked,
        noAsignada: noAsignadaRef.current.checked,
        asignadaA: asignadaARef.current.value,
        llave: llaveRef.current.value,
        observ: observRef.current.value,
      },
    });
  }

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionFiltroLlaves.x - targetRef.current.offsetWidth,
        y: props.posicionFiltroLlaves.y,
      });
    }
  }, [props.posicionFiltroLlaves]);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posicionFiltroLlaves.y : dimensions.y,
        left: dimensions.x === 0 ? props.posicionFiltroLlaves.x : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <label>
        <input
          type="checkbox"
          ref={OKRef}
          defaultChecked={props.filtroLlaves.OK}
          onChange={cambioFiltro}
        ></input>
        OK
      </label>
      <label>
        <input
          type="checkbox"
          ref={perdidaRef}
          defaultChecked={props.filtroLlaves.perdida}
          onChange={cambioFiltro}
        ></input>
        Perdida
      </label>
      <label>
        <input
          type="checkbox"
          ref={noSirveRef}
          defaultChecked={props.filtroLlaves.noSirve}
          onChange={cambioFiltro}
        ></input>
        No Sirve
      </label>
      <label>
        <input
          type="checkbox"
          ref={asignadaRef}
          defaultChecked={props.filtroLlaves.asignada}
          onChange={cambioFiltro}
        ></input>
        Asignada
      </label>
      <label>
        <input
          type="checkbox"
          ref={noAsignadaRef}
          defaultChecked={props.filtroLlaves.noAsignada}
          onChange={cambioFiltro}
        ></input>
        No Asignada
      </label>
      <label>
        Llave:{" "}
        <input
          type="text"
          ref={llaveRef}
          defaultValue={props.filtroLlaves.llave}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Asignada a:{" "}
        <input
          type="text"
          ref={asignadaARef}
          defaultValue={props.filtroLlaves.asignadaA}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Observaciones:{" "}
        <input
          type="text"
          ref={observRef}
          defaultValue={props.filtroLlaves.observ}
          onChange={cambioFiltro}
        ></input>
      </label>
      <div className={classes.botok}>
        <button onClick={cancelaFiltro}>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => {
            props.setMuestraFiltroLlav(false);
          }}
        >
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>
  );
}

export default MenuFiltraLlaves;
