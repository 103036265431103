import classes from "./EstudianteAdeudo.module.css";
import { DaFormatoAdeudos } from "../../Funciones/Funciones";
import { useLayoutEffect, useRef, useState } from "react";

function AdeudoEstudiante(props) {
  function cierraAdeudo() {
    props.setMuestraAdeudo(false);
  }
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });
  //console.log("propsAdeudoEstudiante", props);
  //console.log("dimensions", dimensions);
  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x:
          props.datosPopAdeudo.x +
          (props.datosPopAdeudo.w - targetRef.current.offsetWidth) / 2,
        y:
          props.datosPopAdeudo.y +
          props.datosPopAdeudo.h -
          targetRef.current.offsetHeight -
          10,
      });
    }
  }, [props.datosPopAdeudo]);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.datosPopAdeudo.y : dimensions.y,
        left: dimensions.x === 0 ? props.datosPopAdeudo.x : dimensions.x,
      }}
    >
      <button className={classes.botCancela} onClick={cierraAdeudo}>
        <i className="fas fa-times"></i>
      </button>
      <label className={classes.nombre}>Adeuda:</label>

      {DaFormatoAdeudos(props.datosPopAdeudo.adeudo).map((i, key) => {
        return (
          <label className={classes.otros} key={key}>
            {i}
          </label>
        );
      })}
    </div>
  );
}

export default AdeudoEstudiante;
