import EstudianteItem from "./EstudianteItem";
import classes from "./EstudianteLista.module.css";
import EstudianteMiniItem from "./EstudianteMiniiItem";

function EstudianteLista(props) {
  //console.log("EstLista", props);
  /*if (props.tipo === "MiniItem") {
    return (
      <ul className={classes.list}>
        {props.estudiantes
          .sort((a, b) => a.Nombre - b.Nombre)
          .map((estudiante) => (
            <EstudianteMiniItem
              key={estudiante.IDEst}
              habitaciones={props.habitaciones}
              estudiante={estudiante}
              AgregaDescuentoHandler={props.AgregaDescuentoHandler}
              AgregaPagoHandler={props.AgregaPagoHandler}
              setMuestraSubMenu={props.setMuestraSubMenu}
              setDatosSubMenu={props.setDatosSubMenu}
              EliminaEstudianteHandler={props.EliminaEstudianteHandler}
              EditaEstudianteHandler={props.EditaEstudianteHandler}
              recargaFoto={props.recargaFoto}
              preparaAdeudo={props.preparaAdeudo}
              nivel={props.nivel}
            />
          ))}
      </ul>
    );
  }*/
  //console.log(props);
  return (
    <ul className={classes.list}>
      {props.estudiantes
        .sort((a, b) => a.Nombre - b.Nombre)
        .map((estudiante) =>
          props.muestra !== "Adeudo" && props.muestra !== "LN" ? (
            <EstudianteItem
              key={estudiante.IDEst}
              habitaciones={props.habitaciones}
              estudiante={estudiante}
              AgregaDescuentoHandler={props.AgregaDescuentoHandler}
              AgregaPagoHandler={props.AgregaPagoHandler}
              setMuestraSubMenu={props.setMuestraSubMenu}
              setDatosSubMenu={props.setDatosSubMenu}
              EliminaEstudianteHandler={props.EliminaEstudianteHandler}
              EditaEstudianteHandler={props.EditaEstudianteHandler}
              recargaFoto={props.recargaFoto}
              setRecargaFoto={props.setRecargaFoto}
              preparaAdeudo={props.preparaAdeudo}
              nivel={props.nivel}
            />
          ) : (
            <EstudianteMiniItem
              key={estudiante.IDEst}
              habitaciones={props.habitaciones}
              estudiante={estudiante}
              AgregaDescuentoHandler={props.AgregaDescuentoHandler}
              AgregaPagoHandler={props.AgregaPagoHandler}
              setMuestraSubMenu={props.setMuestraSubMenu}
              setDatosSubMenu={props.setDatosSubMenu}
              EliminaEstudianteHandler={props.EliminaEstudianteHandler}
              EditaEstudianteHandler={props.EditaEstudianteHandler}
              recargaFoto={props.recargaFoto}
              preparaAdeudo={props.preparaAdeudo}
              nivel={props.nivel}
              muestra={props.muestra}
            />
          )
        )}
    </ul>
  );
}

export default EstudianteLista;
