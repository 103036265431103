import TodosLosEstudiantesPage from "./TodosLosEstudiantes";
//import CuentaEfectivo from "../components/resumenes/CuentaEfectivo";
import { useState } from "react";
import TodosLosRecurrentesPage from "./TodosLosRecurrentes";

function InicioPage(props) {
  const [recargar, setRecargar] = useState(false);

  //console.log("recargar", recargar);
  return (
    <section className="centrarElem">
      {props.casaSel > 0 ? (
        <section>
          {
            <section className="centrarElem">
              <TodosLosRecurrentesPage
                casaSel={props.casaSel}
                nivel={props.nivel}
                recargar={recargar}
                muestra="Inicio"
                setRecargar={setRecargar}
              />
            </section>
          }
          <h1>Inquilinos con adeudo</h1>
          <TodosLosEstudiantesPage
            casaSel={props.casaSel}
            nivel={props.nivel}
            muestra={"Adeudo"}
            setRecargar={setRecargar}
          />
          {/*<section className="centrarElem">
            <h1>Efectivo vs app</h1>
            <CuentaEfectivo
              casaSel={props.casaSel}
              nivel={props.nivel}
              recargar={recargar}
              setRecargar={setRecargar}
            />
          </section>*/}
        </section>
      ) : (
        <section>
          <h1>Primero selecciona la casa</h1>
        </section>
      )}
    </section>
  );
}

export default InicioPage;
