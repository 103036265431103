import { useRef, useState, useLayoutEffect } from "react";
import WebCam from "react-webcam";
import classes from "./camaraItem.module.css";

function MuestraCamara(props) {
  const targetRef = useRef();
  const webcam = useRef();
  const [dimensions, setDimensions] = useState({ alto: 640, ancho: 480 });
  const videoConstraints = {
    width: 525,
    height: 700,
    aspectRatio: 1.7777,
    facingMode: "user",
    mirrored: true,
  };

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        alto:  (targetRef.current.offsetWidth * 0.9) ,
        ancho:  targetRef.current.offsetWidth * 0.6,
      });
    }
  }, []);

  function capturaImagen(ev) {
    ev.preventDefault();
    const imgsrc = webcam.current.getScreenshot();
    props.cambiaFoto(imgsrc);
    props.setMuestraCamara(false);
  }

  return (
    <div ref={targetRef} className={classes.modal}>
      <label className={classes.tituloP + " " + classes.labelP}>
        Foto de Estudiante
      </label>
      <WebCam
        ref={webcam}
        audio={false}
        videoConstraints={videoConstraints}
        forceScreenshotSourceSize={true}
        //width={512}
        style={{
          height: dimensions.alto,
          width: dimensions.ancho,
          objectFit: "cover",
        }}
      />
      <div>
        <button onClick={(ev) => capturaImagen(ev)}>
          <i className="fas fa-camera"></i>
        </button>
        <button
          onClick={(ev) => {
            ev.preventDefault();
            props.setMuestraCamara();
          }}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  );
}

export default MuestraCamara;
