import classes from "./EstudianteOrdenaMenu.module.css";
import { useLayoutEffect, useRef, useState } from "react";

function MenuOrdenaEstudiantes(props) {
  const targetRef = useRef();
  const HabAZRef = useRef();
  const HabZARef = useRef();
  const NombreAZRef = useRef();
  const NombreZARef = useRef();
  const AdeudaZARef = useRef();
  const AdeudaAZRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionOrdenaEstudiantes.x - targetRef.current.offsetWidth,
        y: props.posicionOrdenaEstudiantes.y,
      });
    }
  }, [props.posicionOrdenaEstudiantes]);

  function cambioOrden(ev) {
    props.setMuestraOrdenaEst(false);
    NombreAZRef.current.checked = ev.target.id === "NombreAZ";
    NombreZARef.current.checked = ev.target.id === "NombreZA";
    HabAZRef.current.checked = ev.target.id === "HabAZ";
    HabZARef.current.checked = ev.target.id === "HabZA";
    AdeudaAZRef.current.checked = ev.target.id === "AdeudaAZ";
    AdeudaZARef.current.checked = ev.target.id === "AdeudaZA";

    props.setOrdenaEstudiantes({
      NombreAZ: NombreAZRef.current.checked,
      NombreZA: NombreZARef.current.checked,
      HabAZ: HabAZRef.current.checked,
      HabZA: HabZARef.current.checked,
      AdeudaAZ: AdeudaAZRef.current.checked,
      AdeudaZA: AdeudaZARef.current.checked,
    });
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top:
          dimensions.y === 0 ? props.posicionOrdenaEstudiantes.x : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionOrdenaEstudiantes.y : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Ordenar por:</label>
      <div className={classes.contCheckboxes}>
        <div className={classes.ordenItem}>
          <label>
            <input
              ref={NombreAZRef}
              id="NombreAZ"
              onChange={cambioOrden}
              defaultChecked={props.ordenaEstudiantes.NombreAZ}
              type="radio"
            ></input>
            Nombre
          </label>
          <div className={classes.ordenIcon}>
            <i className="fas fa-sort-amount-down"></i>
          </div>
        </div>
        <div className={classes.ordenItem}>
          <label>
            <input
              ref={NombreZARef}
              id="NombreZA"
              onChange={cambioOrden}
              defaultChecked={props.ordenaEstudiantes.NombreZA}
              type="radio"
            ></input>
            Nombre
          </label>
          <div className={classes.ordenIcon}>
            <i className="fas fa-sort-amount-up"></i>
          </div>
        </div>
        <div className={classes.ordenItem}>
          <label>
            <input
              ref={HabAZRef}
              id="HabAZ"
              onChange={cambioOrden}
              defaultChecked={props.ordenaEstudiantes.HabAZ}
              type="radio"
            ></input>
            Habitación
          </label>
          <div className={classes.ordenIcon}>
            <i className="fas fa-sort-amount-down"></i>
          </div>
        </div>
        <div className={classes.ordenItem}>
          <label>
            <input
              ref={HabZARef}
              id="HabZA"
              onChange={cambioOrden}
              defaultChecked={props.ordenaEstudiantes.HabZA}
              type="radio"
            ></input>
            Habitación
          </label>
          <div className={classes.ordenIcon}>
            <i className="fas fa-sort-amount-up"></i>
          </div>
        </div>
        <div className={classes.ordenItem}>
          <label>
            <input
              ref={AdeudaAZRef}
              id="AdeudaAZ"
              onChange={cambioOrden}
              defaultChecked={props.ordenaEstudiantes.AdeudaAZ}
              type="radio"
            ></input>
            Adeuda
          </label>
          <div className={classes.ordenIcon}>
            <i className="fas fa-sort-amount-down"></i>
          </div>
        </div>
        <div className={classes.ordenItem}>
          <label>
            <input
              ref={AdeudaZARef}
              id="AdeudaZA"
              onChange={cambioOrden}
              defaultChecked={props.ordenaEstudiantes.AdeudaZA}
              type="radio"
            ></input>
            Adeuda
          </label>
          <div className={classes.ordenIcon}>
            <i className="fas fa-sort-amount-up"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuOrdenaEstudiantes;
