import { useRef, useState, useLayoutEffect } from "react";
import classes from "./IngresoFiltroMenu.module.css";

function MenuFiltraIngresos(props) {
  const targetRef = useRef();
  const nombreRef = useRef();
  const rentaRef = useRef();
  const otrosRef = useRef();
  const depGarantiaRef = useRef();
  const habitacionRef = useRef();
  const efectivoRef = useRef();
  const transferRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  function cancelaFiltro() {
    otrosRef.current.checked = false;
    depGarantiaRef.current.checked = false;
    rentaRef.current.checked = true;
    efectivoRef.current.checked = true;
    transferRef.current.checked = true;
    nombreRef.current.value = "";
    habitacionRef.current.value = "";
    props.setFiltroIngresos({
      ...props.filtroIngresos,
      ...{
        Otros: false,
        DepGar: false,
        Renta: true,
        Efectivo: true,
        Transferencia: true,
        Nombre: "",
        Habitacion: "",
      },
      //FechaIni: YYYYMMDDdeDate(new Date(fechaIniRef.current.value + " 0:0:0")),
      //FechaFin: YYYYMMDDdeDate(new Date(fechaFinRef.current.value + " 0:0:0")),
    });
    props.setMuestraFiltroIng(false);
  }

  function cambioFiltro() {
    props.setFiltroIngresos({
      ...props.filtroIngresos,
      ...{
        Otros: otrosRef.current.checked,
        DepGar: depGarantiaRef.current.checked,
        Renta: rentaRef.current.checked,
        Transferencia: transferRef.current.checked,
        Efectivo: efectivoRef.current.checked,
        Nombre: nombreRef.current.value,
        Habitacion: habitacionRef.current.value,
      },
    });
    //console.log("cambiofiltro", sinAdeudoRef.current.checked);
  }

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionFiltroIngresos.x - targetRef.current.offsetWidth,
        y: props.posicionFiltroIngresos.y,
      });
    }
  }, [props.posicionFiltroIngresos]);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posicionFiltroIngresos.y : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionFiltroIngresos.x : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <label>
        <input
          type="checkbox"
          ref={rentaRef}
          defaultChecked={props.filtroIngresos.Renta}
          onChange={cambioFiltro}
        ></input>
        Renta
      </label>
      <label>
        <input
          type="checkbox"
          ref={depGarantiaRef}
          defaultChecked={props.filtroIngresos.DepGar}
          onChange={cambioFiltro}
        ></input>
        Depósito en garantía
      </label>
      <label>
        <input
          type="checkbox"
          ref={otrosRef}
          defaultChecked={props.filtroIngresos.Otros}
          onChange={cambioFiltro}
        ></input>
        Otros
      </label>

      <label>
        <input
          type="checkbox"
          ref={efectivoRef}
          defaultChecked={props.filtroIngresos.Efectivo}
          onChange={cambioFiltro}
        ></input>
        Efectivo
      </label>
      <label>
        <input
          type="checkbox"
          ref={transferRef}
          defaultChecked={props.filtroIngresos.Transferencia}
          onChange={cambioFiltro}
        ></input>
        Transferencia
      </label>

      <label>
        Nombre:{" "}
        <input
          type="text"
          ref={nombreRef}
          defaultValue={props.filtroIngresos.Nombre}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Habitación:{" "}
        <input
          type="text"
          ref={habitacionRef}
          defaultValue={props.filtroIngresos.Habitacion}
          onChange={cambioFiltro}
        ></input>
      </label>

      <div className={classes.botok}>
        <button onClick={cancelaFiltro}>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => {
            props.setMuestraFiltroIng(false);
          }}
        >
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>
  );
}

export default MenuFiltraIngresos;
