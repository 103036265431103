import classes from "./AccesoTemporalItem.module.css";
import { useEffect, useRef, useState } from "react";
import {
  //FormateaCurrency,
  FuncionValidaDatos,
  DateParaInputDate,
  EnviaDatosANuevoServicio,
  NumeroAMoneda,
} from "../../Funciones/Funciones";
import { addYears, compareAsc } from "date-fns";

function validaEntero(event) {
  FuncionValidaDatos(event, "numeros");
}

function AccesoTemporalItem(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadedHabitaciones, setLoadedHabitaciones] = useState([]);

  const contraseñaRef = useRef();
  const habitacionRef = useRef();
  const fechaIngresoRef = useRef();
  const venceContratoRef = useRef();
  const diaCorteRef = useRef();

  function respAutorizacion(resp) {
    //console.log(resp);
    if (resp.Error !== "SI") {
      props.setTextoModal(
        "Invitacion autorizada. Recuerda que tiene 10 minutos para ingresar"
      );
      props.setMostrandoError(false);
    } else {
      props.setTextoModal("Error:\n" + resp.DESCERROR);
      props.setMostrandoError(true);
    }
    props.setMuestraDialogoOK(true);
  }

  useEffect(() => {
    //console.log(props);
    setIsLoading(true);

    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "InicioAltaInquilino",
        IDCasa: props.casaSel,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var habitaciones = [];
        //console.log("data ", data);
        if (data.ERROR !== "SI") {
          for (const key in data) {
            //console.log("key ", key, data[key]);
            const datos = {
              ...data[key],
            };
            if (key === "HabDisp") {
              habitaciones = Object.keys(datos).map((key) => datos[key]);
            }
          }
          //console.log("habitaciones", habitaciones);
          //console.log("datosResumen ", datosResumen, datosResumen.ERROR[0]);
          setIsLoading(false);
          setLoadedHabitaciones(habitaciones);
        } else {
          setIsLoading(false);
          console.log("ERROR", data.ERROR, data.DESCERROR);
        }
      });
  }, [props.casaSel]);

  const submitHandler = async (event) => {
    event.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      const TemporalN = {
        IDCasa: props.casaSel,
        Pwd: contraseñaRef.current.value,
        IDHab: habitacionRef.current.value,
        fechaI: fechaIngresoRef.current.value,
        fechaV: venceContratoRef.current.value,
        DiaC: diaCorteRef.current.value,
        Usuario: "invitado",
      };

      EnviaDatosANuevoServicio(
        "AutorizaInvitado",
        TemporalN,
        respAutorizacion,
        setIsLoading,
        null,
        props.usuario
      );
    } else {
      props.setTextoModal(DatosValidos);
      props.setMostrandoError(true);
      props.setMuestraDialogoOK(true);
      //alert(DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";
    if (contraseñaRef.current.value === "") {
      respuesta = respuesta + "la contraseña no puede estar vacía\n";
    }
    if (habitacionRef.current.value === "0") {
      respuesta = respuesta + "Selecciona la habitación que se le asignó\n";
    }
    if (
      compareAsc(
        new Date(fechaIngresoRef.current.value),
        new Date(venceContratoRef.current.value)
      ) === 0
    ) {
      respuesta =
        respuesta +
        "El vencimiento de contrato no puede ser igual a la fecha de ingreso\n";
    }
    if (parseInt(diaCorteRef.current.value) === 0) {
      respuesta = respuesta + "El dia de corte no puede ser cero\n";
    } else if (parseInt(diaCorteRef.current.value) === 31) {
      respuesta =
        respuesta + "El dia de corte no puede ser 31, cambialo a 30\n";
    } else if (parseInt(diaCorteRef.current.value) > 31) {
      respuesta = respuesta + "El dia de corte no puede ser mayor a 31\n";
    }
    return respuesta;
  }

  if (isLoading) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <form onSubmit={submitHandler}>
      <div>
        <label className={classes.labelP}>Contraseña a autorizar</label>
        <input
          type="text"
          required
          ref={contraseñaRef}
          className={classes.textareaNE}
        ></input>
      </div>
      <div>
        <label className={classes.labelP}>Habitación asignada</label>
        <select
          required
          type="text"
          ref={habitacionRef}
          className={classes.textareaNE}
        >
          <option key="0" value={"0"}>
            selecciona habitación
          </option>
          {loadedHabitaciones.map((habitacion, indice) => {
            return (
              <option key={habitacion.IDHab} value={habitacion.IDHab}>
                {habitacion.Habitacion} ({NumeroAMoneda(habitacion.Precio)})
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <label className={classes.tituloP + " " + classes.labelP}>Fechas</label>
      </div>
      <div>
        <label className={classes.labelP}>Fecha Ingreso</label>
        <input
          required
          type="date"
          ref={fechaIngresoRef}
          className={classes.textareaNE}
          defaultValue={DateParaInputDate()}
        ></input>
      </div>
      <div>
        <label className={classes.labelP}>Vence Contrato</label>
        <input
          required
          type="date"
          ref={venceContratoRef}
          className={classes.textareaNE}
          defaultValue={DateParaInputDate(addYears(new Date(), 1))}
        ></input>
      </div>
      <div>
        <label className={classes.labelP}>Dia corte</label>
        <input
          maxLength="2"
          type="text"
          required
          ref={diaCorteRef}
          className={classes.textareaNE}
          onPaste={validaEntero}
          onKeyPress={validaEntero}
          defaultValue={DateParaInputDate().substr(8, 2)}
        ></input>
      </div>
      <div className={classes.divBotones}>
        <button className={classes.botones}>Generar Acceso </button>
      </div>
      <div>
        <label className={classes.tituloP + " " + classes.labelP}>
          Instrucciones
        </label>
      </div>
      <ol className={classes.lista}>
        <li className={classes.puntolista}>
          Llena todos los campos de la pagina y presiona "Generar Acceso"
        </li>
        <li className={classes.puntolista}>
          A partir de que presiones el botón, el invitado tendra 10 minutos para
          ingresar o el acceso caducará y tendras que generar uno nuevo
        </li>
        <li className={classes.puntolista}>
          Dale al invitado los siguientes datos junto con la contraseña que
          acabas de autorizar
          <div className={classes.sangria}>
            Usuario: <span className={classes.azul}>invitado</span>
          </div>
          <div className={classes.sangria}>
            Liga: <span className={classes.azul}>www.copilco.net</span>
          </div>
        </li>
        <li className={classes.puntolista}>
          En esa liga, el invitado deberá llenar los datos que se le solicitan y
          terminar presionando "Guardar"
        </li>
        <li className={classes.puntolista}>
          Una vez que el invitado te avise que ya lleno sus datos y los guardo,
          entra en "Edicion de inquilinos" y captura los datos que le faltan
          para completar su expediente
        </li>
        <li className={classes.puntolista}>
          Una vez que lo guardes, podras generar el contrato para imprimirlo,
          compartirlo o mostrarlo en algun dispositivo
        </li>
      </ol>
    </form>
  );
}

export default AccesoTemporalItem;
