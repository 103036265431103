import classes from "./EstudianteNuevoLN.module.css";
import EstudianteMiniItem from "./EstudianteMiniiItem";

function EstudianteNuevoListaNegra(props) {
  function Cierra() {
    props.setMuestraNuevoBloqueado(false);
  }

  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        <button className={classes.botCancela} onClick={Cierra}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1>Agregar a Lista Negra</h1>

      <ul className={classes.list}>

        {props.estudiantes.length > 0 ? props.estudiantes
          .sort((a, b) => a.Nombre - b.Nombre)
          .map((estudiante) => (
            <EstudianteMiniItem
              key={estudiante.IDEst}
              estudiante={estudiante}
              nivel={props.nivel}
              muestra={props.muestra}
              AgregaAListaNegra={props.AgregaAListaNegra}
            />
          )) : <h1>No hay estudiantes dados de baja en esta casa</h1>}
      </ul>
    </section>
  );
}

export default EstudianteNuevoListaNegra;
