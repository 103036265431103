import React, { useRef, useState, useEffect } from "react";
import {
  EnviaDatosAServicio,
  FechaParaInputDate,
} from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./EgresosEdita.module.css";
import Select from "react-select";
import { Slide, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

function EditaEgreso(props) {
  const [ceCosLoaded, setCeCosLoaded] = useState([]);
  const [proveedoresLoaded, setProveedoresLoaded] = useState([]);
  const [formasPagoLoaded, setFormasPagoLoaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [montoR, setMontoR] = useState();
  const ceCoRef = useRef();
  const proveedorRef = useRef();
  const forPagRef = useRef();
  const fechaPagRef = useRef();
  const conceptoRef = useRef();
  //const montoRef = useRef();
  const observRef = useRef();
  const token = useToken().token;
  const idCasa = token.casaSel;

  useEffect(() => {
    if (idCasa > 0) {
      setIsLoading(true);
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "iniciaNuevoEgreso",
          IDCasa: idCasa,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var ceCos = [];
          var proveedores = [];
          var formasPago = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "RCeCos") {
                ceCos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "RProveedores") {
                proveedores = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "RFormasPago") {
                formasPago = Object.keys(datos).map((key) => datos[key]);
              }
            }
            const ceCoT = ceCos.map((ceco) => {
              return {
                value: ceco.IDCeCo,
                label: ceco.CeCo,
              };
            });
            const provT = proveedores.map((prov) => {
              return { value: prov.IDProv, label: prov.Proveedor };
            });
            const fpT = formasPago.map((fp) => {
              return { value: fp.IDForPag, label: fp.FormaPago };
            });
            //console.log("ceCos", ceCos, ceCoT);
            //console.log("proveedores", proveedores);
            //console.log("formasPago", fpT);
            setCeCosLoaded(ceCoT);
            setProveedoresLoaded(provT);
            setFormasPagoLoaded(fpT);
            setIsLoading(false);
            setMontoR(props.datosEdicionEgreso.Monto);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.DESCERROR);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [idCasa, props.datosEdicionEgreso.Monto]);

  function nuevoCeCo(ev) {
    ev.preventDefault();
    props.setMuestraNuevoCeCo(true);
  }

  function nuevoProv(ev) {
    ev.preventDefault();
    props.setMuestraNuevoProv(true);
  }

  function CancelaEdicion(ev) {
    ev.preventDefault();
    props.setMostrarEdicion(false);
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      const egresoN = {
        IDEgr: props.datosEdicionEgreso.IDEgr,
        IDCeCo: ceCoRef.current.getValue()[0].value,
        IDProv: proveedorRef.current.getValue()[0].value,
        IDForPag: forPagRef.current.getValue()[0].value,
        FechaPag: fechaPagRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Concepto: conceptoRef.current.value,
        Monto: montoR, // DejaSoloNumerosDeCurrency(montoRef.current.value),
        Observaciones: observRef.current.value,
      };
      //console.log("egresoN", egresoN);
      const envio = await EnviaDatosAServicio(egresoN, "editaEgreso");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });

        props.setMostrarEdicion(false);
        props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";
    if (ceCoRef.current.getValue().length === 0) {
      respuesta = respuesta + "Nombre de Centro de Costos\n";
    }
    if (proveedorRef.current.getValue().length === 0) {
      respuesta = respuesta + "Nombre del proveedor\n";
    }
    if (forPagRef.current.getValue().length === 0) {
      respuesta = respuesta + "Forma de Pago\n";
    }
    if (conceptoRef.current.value === "") {
      respuesta = respuesta + "Concepto\n";
    }
    if (montoR === 0 || montoR === undefined) {
      respuesta = respuesta + "Monto\n";
    }
    /*
    if (montoRef.current.value === "") {
      respuesta = respuesta + "Monto\n";
    } else if (
      montoRef.current.value.match(
        "^[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:.[0-9]{2})?$"
      ) === null
    ) {
      respuesta = respuesta + "Monto\n";
    }
    */
    return respuesta;
  }

  /********************************************************************************/
  /************************************ RENDER ************************************/
  /********************************************************************************/
  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaEdicion}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      {props.nivel === 1 ? (
        <h1 className={classes.h1loc}>Edita Egreso</h1>
      ) : (
        <h1>Detalle Egreso</h1>
      )}
      <form onSubmit={submitHandler}>
        <div>
          <div className={classes.contSelect}>
            <label className={classes.labelP}>CeCo:</label>
            <Select
              ref={ceCoRef}
              className={classes.textareaNE1}
              options={ceCosLoaded}
              defaultValue={{
                label: props.datosEdicionEgreso.CeCo,
                value: props.datosEdicionEgreso.IDCeCo,
              }}
            />
            {props.nivel === 1 ? (
              <button onClick={nuevoCeCo}>
                <i className="fas fa-plus"></i>
              </button>
            ) : null}
          </div>
          <div className={classes.contSelect}>
            <label className={classes.labelP}>Proveedor:</label>
            <Select
              ref={proveedorRef}
              className={classes.textareaNE1}
              options={proveedoresLoaded}
              defaultValue={{
                label: props.datosEdicionEgreso.Proveedor,
                value: props.datosEdicionEgreso.IDProv,
              }}
            />
            {props.nivel === 1 ? (
              <button onClick={nuevoProv}>
                <i className="fas fa-plus"></i>
              </button>
            ) : null}
          </div>
          <div className={classes.contSelect}>
            <label className={classes.labelP}>forma Pago:</label>
            <Select
              ref={forPagRef}
              className={classes.textareaNE1}
              options={formasPagoLoaded}
              defaultValue={{
                label: props.datosEdicionEgreso.FormaPago,
                value: props.datosEdicionEgreso.IDForPag,
              }}
            />
          </div>
          <div>
            <label className={classes.labelP}>Fecha de pago</label>
            <input
              required
              id="fechaPag"
              ref={fechaPagRef}
              type="date"
              defaultValue={FechaParaInputDate(
                props.datosEdicionEgreso.FechaPag
              )}
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Concepto</label>
            <input
              type="text"
              ref={conceptoRef}
              className={classes.textareaNE}
              defaultValue={props.datosEdicionEgreso.Concepto}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Monto:</label>
            <NumericFormat
              maxLength="10"
              id="Monto"
              type="text"
              className={classes.textareaNE}
              placeholder="$0.00"
              prefix={"$"}
              value={montoR}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={montoR}
              onValueChange={(values) => {
                setMontoR(values.floatValue);
              }}
            ></NumericFormat>
            {/*
            <input
              type="text"
              maxLength="10"
              ref={montoRef}
              className={classes.textareaNE}
              defaultValue={FormateaCurrency.format(
                props.datosEdicionEgreso.Monto
              )}
              onPaste={validaMonto}
              onKeyPress={validaMonto}
            ></input>*/}
          </div>

          <div>
            <label className={classes.labelP}>Observaciones:</label>
            <input
              type="text"
              ref={observRef}
              placeholder="algún comentario?"
              defaultValue={props.datosEdicionEgreso.Observaciones}
              className={classes.textareaNE}
            ></input>
          </div>
        </div>
      </form>
    </section>
  );
}

export default EditaEgreso;
