import { EnviaDatosANuevoServicio } from "../Funciones/Funciones";
import LoginForm from "../components/login/LoginForm";

function LoginPage(props) {
  const loginUser = async (credenciales) => {
    //console.log("credenciales", credenciales);
    EnviaDatosANuevoServicio(
      "login",
      credenciales,
      respServ,
      isLoading,
      null,
      null
    );
    
  };

  function respServ(resp) {
    //console.log(resp);
    if (resp.ErrorLogin === "") {
      props.setToken(resp);
    } else {
      alert(resp.ErrorLogin);
    }
  }

  function isLoading() {}
  //  const history = useHistory();
  //console.log("login", props);
  const EnviaLoginHandler = async (credenciales) => {
    loginUser(credenciales);
    //console.log("respuesta ", token);
    /*
    if (token.ErrorLogin === "") {
      props.setToken(token);
    } else {
      //console.log(token.ErrorLogin);
      alert(token.ErrorLogin);
    }*/
  };

  return <LoginForm onLogin={EnviaLoginHandler} vers={props.vers} />;
}

export default LoginPage;
