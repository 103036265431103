import { useLayoutEffect, useRef, useState } from "react";
import classes from "./IngresoOrdenaMenu.module.css";

function MenuOrdenaIngresos(props) {
  const targetRef = useRef();
  const fechaAZRef = useRef();
  const fechaZARef = useRef();
  const habitacionAZRef = useRef();
  const habitacionZARef = useRef();
  const montoAZRef = useRef();
  const montoZARef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionOrdenaIngresos.x - targetRef.current.offsetWidth,
        y: props.posicionOrdenaIngresos.y,
      });
    }
  }, [props.posicionOrdenaIngresos]);

  function cambioFiltro(ev) {
    props.setMuestraOrdenaIng(false);
    fechaAZRef.current.checked = ev.target.id === "fechaAZ";
    fechaZARef.current.checked = ev.target.id === "fechaZA";
    habitacionAZRef.current.checked = ev.target.id === "habitacionAZ";
    habitacionZARef.current.checked = ev.target.id === "habitacionZA";
    montoAZRef.current.checked = ev.target.id === "montoAZ";
    montoZARef.current.checked = ev.target.id === "montoZA";

    props.setOrdenaIngresos({
      fechaAZ: fechaAZRef.current.checked,
      fechaZA: fechaZARef.current.checked,
      habitacionAZ: habitacionAZRef.current.checked,
      habitacionZA: habitacionZARef.current.checked,
      montoAZ: montoAZRef.current.checked,
      montoZA: montoZARef.current.checked,
    });
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posicionOrdenaIngresos.y : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionOrdenaIngresos.x : dimensions.x,
      }}
    >
      <label>
        <input
          type="radio"
          id="fechaAZ"
          ref={fechaAZRef}
          defaultChecked={props.ordenaIngresos.fechaAZ}
          onChange={cambioFiltro}
        ></input>
        Fecha <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="fechaZA"
          ref={fechaZARef}
          defaultChecked={props.ordenaIngresos.fechaZA}
          onChange={cambioFiltro}
        ></input>
        Fecha <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="habitacionAZ"
          ref={habitacionAZRef}
          defaultChecked={props.ordenaIngresos.habitacionAZ}
          onChange={cambioFiltro}
        ></input>
        Habitación <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="habitacionZA"
          ref={habitacionZARef}
          defaultChecked={props.ordenaIngresos.habitacionZA}
          onChange={cambioFiltro}
        ></input>
        Habitación <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="montoAZ"
          ref={montoAZRef}
          defaultChecked={props.ordenaIngresos.montoAZ}
          onChange={cambioFiltro}
        ></input>
        Monto <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="montoZA"
          ref={montoZARef}
          defaultChecked={props.ordenaIngresos.montoZA}
          onChange={cambioFiltro}
        ></input>
        Monto <i className="fas fa-sort-amount-up"></i>
      </label>
    </div>
  );
}

export default MenuOrdenaIngresos;
