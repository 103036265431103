import { useLayoutEffect, useRef, useState } from "react";
import classes from "./SubMenuEgresos.module.css";

function SubMenuEgresos(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.datosSubMenu.x - targetRef.current.offsetWidth,
        y: props.datosSubMenu.y,
      });
    }
  }, [props.datosSubMenu]);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.datosSubMenu.y : dimensions.y,
        left: dimensions.x === 0 ? props.datosSubMenu.x : dimensions.x,
      }}
    >
      <label
        className={classes.botones}
        onClick={() => {
          props.setMuestraSubMenu(false);
          props.setMuestraPagosProgramados(true);
        }}
      >
        Catalogo de Pagos Programados
      </label>
      <label
        className={classes.botones}
        onClick={() => {
          props.setMuestraSubMenu(false);
          props.setMuestraEditaCeCo(true);
        }}
      >
        {props.nivel === 1 ? "Editar CeCo" : "Ver CeCos"}
      </label>
      <label
        className={classes.botones}
        onClick={() => {
          props.setMuestraEditaProv(true);
        }}
      >
        {props.nivel === 1 ? "Editar Proveedor" : "Ver Proveedores"}
      </label>
    </div>
  );
}

export default SubMenuEgresos;
