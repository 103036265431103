import differenceInDays from "date-fns/differenceInDays";
import { useEffect, useRef, useState } from "react";
import { EnviaDatosAServicio } from "../../Funciones/Funciones";
import classes from "./EstudianteElimina.module.css";
import { Slide, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

function LlaveDeArray(llaves) {
  var respuesta = "";
  for (var i = 0; i < llaves.length; i++) {
    respuesta = respuesta + llaves[i].Llave + ", ";
  }
  if (respuesta.length > 0) {
    respuesta = respuesta.substring(0, respuesta.length - 2);
  }
  return respuesta;
}

function EliminaEstudiante(props) {
  //console.log(props)
  const [devolverCalculado, setDevolverCalculado] = useState("");
  const Hoy = new Date();
  const [fechaDesocupa, setFechaDesocupa] = useState(
    new Date(Hoy.getFullYear(), Hoy.getMonth(), Hoy.getDate(), 0, 0, 0)
      .toISOString()
      .substring(0, 10)
  );
  const [composturas, setComposturas] = useState(0);
  const DepGar = props.estudiante.Deposito * 1;
  const Precio = props.estudiante.Precio * 1;
  const UltDiaPagado = props.estudiante.UltDiaPagado;
  //const composturasRef = useRef();
  const marcarDevueltoRef = useRef();
  const agregaEgresosRef = useRef();

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    const gastoGar =
      (agregaEgresosRef.current.checked ? "SI" : "NO") +
      (marcarDevueltoRef.current.checked ? "SI" : "NO");
    if (DatosValidos === "") {
      const ElimN = {
        IDEst: props.estudiante.IDEst,
        FechaIng: fechaDesocupa.toString().toUpperCase().replace(/-/gi, ""),
        Llaves: props.estudiante.Llaves,
        IDHab: props.estudiante.IDHab,
        Email: gastoGar, //aqui va si se debe guardar el gasto o no como "SI" O "NO" e inmediatamente si se indico marcar como devuelto el deposito en garantía, otra vez, con "SI" o "NO"
        Nombre: props.estudiante.Nombre,
        Estado: devolverCalculado, //aqui va el monto a devolver
        FechaBaja: "hola",
      };
      //console.log("ElimN", ElimN);
      const envio = await EnviaDatosAServicio(ElimN, "EliminaInquilino");
      if (envio.ERROR === "") {
        toast("Inquilino eliminado", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.EliminadoOK();
        //props.setMuestraNuevoProv(false);
        //props.setRecargar(true);
        /*
        props.setDialogoOK({
          texto: "Eliminado",
          mostrandoError: false,
          recargar: true,
          cerrarVentana: "mostrarElimina",
        });
        props.setMuestraDialogoOK(true);*/
        //alert("Guardado OK");
        //props.EliminadoOK();
        //agregar nuevo a estudiantes
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    return respuesta;
  }

  function cambioFecha(ev) {
    setFechaDesocupa(
      new Date(ev.target.value + " 0:0:0").toISOString().substring(0, 10)
    );
  }

  function CancelaElimina(ev) {
    ev.preventDefault();
    props.setMostrarElimina(false);
  }

  useEffect(() => {
    const dias = differenceInDays(
      UltDiaPagado,
      new Date(fechaDesocupa + " 0:0:0")
    );
    const montoExtra = (parseFloat(Precio) / 30) * dias;
    setDevolverCalculado(DepGar + montoExtra - composturas);
  }, [UltDiaPagado, DepGar, fechaDesocupa, Precio, composturas]);

  //console.log(props);
  console.log(
    "UE",
    UltDiaPagado,
    Precio,
    DepGar,
    composturas,
    devolverCalculado
  );

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-trash-alt"></i>
          </button>
        )}
        <button onClick={CancelaElimina}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>Baja de Estudiante</h1>
      <form>
        <div>
          <label className={classes.labelP}>Nombre</label>
          <input
            readOnly
            type="text"
            value={props.estudiante.Nombre}
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Habitación</label>
          <input
            readOnly
            type="text"
            value={props.estudiante.Habitacion}
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Renta</label>
          <NumericFormat
            maxLength="8"
            readOnly
            type="text"
            className={classes.textareaNE}
            placeholder="$0.00"
            defaultValue={props.estudiante.Precio}
            value={props.estudiante.Precio}
            prefix={"$"}
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
          ></NumericFormat>
          {/*<input
            readOnly
            type="text"
            value={props.estudiante.Precio}
            className={classes.textareaNE}
          ></input>*/}
        </div>
        <div>
          <label className={classes.labelP}>Dep. Garantía</label>
          <NumericFormat
            maxLength="8"
            readOnly
            type="text"
            className={classes.textareaNE}
            placeholder="$0.00"
            defaultValue={props.estudiante.Deposito}
            value={props.estudiante.Deposito}
            prefix={"$"}
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
          ></NumericFormat>
          {/*<input
            readOnly
            type="text"
            value={FormateaCurrency.format(props.estudiante.Deposito)}
            className={classes.textareaNE}
          ></input>*/}
        </div>
        <div>
          <label className={classes.labelP}>Ult. Periodo</label>
          <input
            readOnly
            type="text"
            value={props.estudiante.UltPeriodoAbarca}
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Llave(s)</label>
          <input
            readOnly
            type="text"
            value={
              props.estudiante.Llaves === null
                ? ""
                : LlaveDeArray(props.estudiante.Llaves)
            }
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Fecha Desocupa</label>
          <input
            required
            type="date"
            defaultValue={fechaDesocupa}
            className={classes.textareaNE}
            onChange={cambioFecha}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Composturas</label>
          <NumericFormat
            maxLength="8"
            autoComplete="off"
            id="composturas"
            type="text"
            className={classes.textareaNE}
            placeholder="$0.00"
            defaultValue={composturas}
            value={composturas}
            prefix={"$"}
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            onValueChange={(values) => {
              setComposturas(values.floatValue);
            }}
          ></NumericFormat>
          {/*<input
            type="text"
            defaultValue={composturas}
            ref={composturasRef}
            onPaste={validaCurrency}
            onKeyPress={validaCurrency}
            onChange={cambioCompostura}
            className={classes.textareaNE}
          ></input>*/}
        </div>
        <div>
          <label className={classes.labelP}>Devolver</label>
          <NumericFormat
            readOnly
            maxLength="8"
            type="text"
            className={classes.textareaNE}
            placeholder="$0.00"
            defaultValue={devolverCalculado}
            value={devolverCalculado}
            prefix={"$"}
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
          ></NumericFormat>
          {/*<input
            readOnly
            type="text"
            value={devolverCalculado}
            className={classes.textareaNE}
          ></input>*/}
        </div>
        <div>
          <input
            type="checkbox"
            id="AgregaEgresos"
            ref={agregaEgresosRef}
            defaultChecked="true"
          ></input>
          <label htmlFor="AgregaEgresos" className={classes.labelCh}>
            Agregar a egresos
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="MarcarDevuelto"
            ref={marcarDevueltoRef}
            defaultChecked="true"
          ></input>
          <label htmlFor="MarcarDevuelto" className={classes.labelCh}>
            Marcar Dep en garantía devuelto
          </label>
        </div>
      </form>
    </section>
  );
}

export default EliminaEstudiante;
