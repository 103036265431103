import { useState, useEffect } from "react";
import { FormateaCurrency } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./ResumenIngEgAgrupados.module.css";

function ResumenIngEgrLista(props) {
  const [ingresos, setIngresos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = useToken().token;
  const idCasa = token.casaSel;

  function ocultaVentana() {
    props.setMuestraResumenIngEgr();
  }

  function calculaTotal() {
    var total = 0;
    for (var i = 0; i < ingresos.length; i++) {
      total = total + parseFloat(ingresos[i].Monto);
    }
    return total;
  }

  useEffect(() => {
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "Resumen" + props.titulo + "Lista",
        IDCasa: idCasa,
        FechaIni: props.fechaIni,
        FechaFin: props.fechaFin,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        var Ingresos = [];
        for (const key in data) {
          const datos = {
            ...data[key],
          };
          if (key === "Ingresos") {
            Ingresos = Object.keys(datos).map((key) => datos[key]);
          }
        }
        const ingresosAgrupado = [];
        for (var i = 0; i < Ingresos.length; i++) {
          var esta = false;
          for (var j = 0; j < ingresosAgrupado.length; j++) {
            if (ingresosAgrupado[j].Concepto === Ingresos[i].Concepto) {
              esta = true;
              ingresosAgrupado[j].Monto =
                parseFloat(ingresosAgrupado[j].Monto) +
                parseFloat(Ingresos[i].Monto);
              break;
            }
          }
          if (!esta) {
            if (
              Ingresos[i].Concepto === "Renta" ||
              Ingresos[i].Concepto === "Deposito en garantia" ||
              Ingresos[i].Concepto === "Duplicado llaves" ||
              parseInt(Ingresos[i].Cuenta) > 3 
            ) {
              ingresosAgrupado.push(Ingresos[i]);
            } else {
              var esta1 = false;
              for (var k = 0; k < ingresosAgrupado.length; k++) {
                if (ingresosAgrupado[k].Concepto === "Otros") {
                  esta1 = true;
                  ingresosAgrupado[k].Monto =
                    parseFloat(ingresosAgrupado[k].Monto) +
                    parseFloat(Ingresos[i].Monto);
                  break;
                }
              }
              if (!esta1) {
                ingresosAgrupado.push({
                  Concepto: "Otros",
                  Cuenta: "1",
                  Monto: Ingresos[i].Monto,
                });
              }
            }
          }
        }
        setIsLoading(false);
        console.log("ingresosAgrupado", ingresosAgrupado);
        setIngresos(ingresosAgrupado);
      });
  }, [idCasa, props.fechaFin, props.fechaIni, props.titulo]);

  if (isLoading) {
    //console.log("cargando");
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <h1>{props.titulo} Agrupados</h1>
      <div className={classes.divFichas}>
        {ingresos.length === 0 ? (
          <h1>No hay {props.titulo}</h1>
        ) : (
          ingresos.map((ing, index) => (
            <div className={classes.renglones}>
              <label key={index} className={classes.labelP}>
                {" "}
                {ing.Concepto}:{" "}
              </label>
              <label key={index} className={classes.labelP1}>
                {FormateaCurrency.format(ing.Monto)}{" "}
              </label>
            </div>
          ))
        )}
      </div>
      <label className={classes.textoNegritas}>Total: {FormateaCurrency.format(calculaTotal())}</label>
      <div className={classes.divBotones}>
        <button className={classes.botones} onClick={ocultaVentana}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </section>
  );
}

export default ResumenIngEgrLista;
