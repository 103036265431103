import classes from "./MainNavigation.module.css";
import Burger from "../HambMenu/Burger/Burger";
import Menu from "../HambMenu/Menu/Menu";
import useOpened from "./useOpened";
import { useEffect, useState } from "react";
import ListaCasas from "../casas/CasasLista";
import Backdrop from "./backdrop";
import useToken from "../login/useToken";

function MainNavigation(props) {
  const { opened, setOpened } = useOpened();
  const [muestraListaCasas, setMuestraListaCasas] = useState(false);
  const [loadedCasas, setLoadedCasas] = useState([]);
  const [nombreCasa, setNombreCasa] = useState(
    props?.esInvitado
      ? "Nuevo"
      : props.casaSel === "" || props.casaSel === undefined
      ? "Selecciona Casa"
      : props.nombreCasa
  );
  const scrollDirection = useScrollDirection();
  const { token, setToken } = useToken();
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.scrollY;

      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  useEffect(() => {
    //console.log(loadedCasas);
    if (
      nombreCasa === "Selecciona Casa" &&
      (props.casaSel === "" || props.casaSel === undefined)
    ) {
      if (loadedCasas.length > 1) {
        setMuestraListaCasas(true);
      } else {
        setNombreCasa(loadedCasas[0].value.Casa);
        token["nombreCasaSel"] = loadedCasas[0].value.Casa;
        setToken(token);
        //casaSeleccionada(
        //loadedCasas[0].value.IDCasa,
        //loadedCasas[0].value.Casa
      }
    }
  }, [loadedCasas, setToken, token, nombreCasa, props.casaSel]);

  function TextoNivel() {
    if (props.nivel === 1) {
      return "✏️"; //"administrador";
    } else if (props.nivel === 2) {
      return "👁️"; //"consulta";
    } else if (props.nivel === 3) {
      return "🧹"; //"conserje";
    } else if (props.nivel === 4) {
      return "Captura Datos";
    }
  }

  if (loadedCasas.length === 0) {
    const casNiv = JSON.parse(token.cas_y_niv);
    const arr = [];
    Object.keys(casNiv).forEach((key) =>
      arr.push({ id: key, value: casNiv[key] })
    );
    //console.log(arr);
    setLoadedCasas(arr);
    //setCasasCargadas(true);
  }

  function listaCasas() {
    setMuestraListaCasas(true);
  }
  //console.log(nombreCasa);
  return (
    <header
      className={scrollDirection === "down" ? classes.hide : classes.header}
    >
      <div className={classes.logo} onClick={listaCasas}>
        <label className={classes.lacasa}> {nombreCasa}</label>
      </div>
      <div className={classes.divBurgerMenu}>
        <Burger setOpened={setOpened} />
      </div>
      {opened && <Menu setOpened={setOpened} />}
      {muestraListaCasas && (
        <ListaCasas
          setNombreCasa={setNombreCasa}
          setCasaSel={props.setCasaSeleccionada}
          setMuestraListaCasas={setMuestraListaCasas}
          setNivel={props.setNivel}
        />
      )}
      {muestraListaCasas && <Backdrop recarga={setMuestraListaCasas} />}
      <label className={classes.tipoUsuario}>
        {TextoNivel()} v{props.vers}
      </label>
    </header>
  );
}

export default MainNavigation;
