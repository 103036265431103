import { useRef, useState } from "react";
import { EnviaDatosANuevoServicio } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./HabitacionNueva.module.css";
import { Slide, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

function NuevaHabitacion(props) {
  const nombreRef = useRef();
  //const precioRef = useRef();
  //const anchoRef = useRef();
  //const largoRef = useRef();
  const estatusRef = useRef();
  //const maxInqRef = useRef();
  const observRef = useRef();
  const [precioInput, setPrecioInput] = useState();
  const [anchoInput, setAnchoInput] = useState();
  const [largoInput, setLargoInput] = useState();
  const [maxInqInput, setMaxInqInput] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken().token;
  const idCasa = token.casaSel;
  const idUsu = token.Id;

  function CancelaNuevo(ev) {
    ev.preventDefault();
    props.setMostrarNuevo(false);
  }

  function respServ(resp) {
    console.log(resp);
    if (resp.Error === "") {
      toast("Habitación agregada", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.habitacionCompleta();
    } else {
      props.setDialogoOK({
        texto: "Error al guardar: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      //console.log("mesQuePagaRef", mesQuePagaRef.current.value);
      //console.log("nombreRef", nombreRef);
      const habitacionN = {
        IDHab: "",
        Habitacion: nombreRef.current.value,
        Precio: precioInput, // DejaSoloNumerosDeCurrency(precioRef.current.value),
        Ancho: anchoInput, //anchoRef.current.value,
        Largo: largoInput, //largoRef.current.value,
        Estatus: estatusRef.current.value,
        MaxInq: maxInqInput, // maxInqRef.current.value,
        Observaciones: observRef.current.value,
        IDCasa: idCasa,
      };
      //console.log("ingresoN", ingresoN);
      EnviaDatosANuevoServicio(
        "nuevaHabitacion",
        habitacionN,
        respServ,
        setIsLoading,
        null,
        idUsu
      );
      /*
      const envio = await EnviaDatosAServicio(habitacionN, "nuevaHabitacion");
      if (envio.ERROR === "") {
        toast("Habitación agregada", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.habitacionCompleta();
        
        //alert("Guardado OK");
        //props.habitacionCompleta();
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }*/
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (nombreRef.current.value === "") {
      respuesta = respuesta + "Nombre de habitación\n";
    }
    /*
    if (precioRef.current.value === "") {
      respuesta = respuesta + "precio de habitación\n";
    } else if (
      precioRef.current.value.match(
        "^[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:.[0-9]{2})?$"
      ) === null
    ) {
      respuesta = respuesta + "precio de habitación\n";
    }
    if (anchoRef.current.value === "") {
      respuesta = respuesta + "ancho de habitación\n";
    } else if (anchoRef.current.value.match("[0-9]*(?:.[0-9]{2})$") === null) {
      respuesta = respuesta + "ancho de habitación\n";
    }
    if (largoRef.current.value === "") {
      respuesta = respuesta + "largo de habitación\n";
    } else if (largoRef.current.value.match("[0-9]*(?:.[0-9]{2})$") === null) {
      respuesta = respuesta + "largo de habitación\n";
    }*/
    if (precioInput === 0 || precioInput === undefined) {
      respuesta = respuesta + "precio de habitación\n";
    }
    if (anchoInput === 0 || anchoInput === undefined) {
      respuesta = respuesta + "ancho de habitación\n";
    }
    if (largoInput === 0 || largoInput === undefined) {
      respuesta = respuesta + "largo de habitación\n";
    }
    if (estatusRef.current.value === "0") {
      respuesta = respuesta + "estatus de habitación\n";
    }
    if (maxInqInput === 0 || maxInqInput === undefined) {
      respuesta = respuesta + "maximo de inquilinos de habitación\n";
    }
    /*
    if (maxInqRef.current.value === "") {
      respuesta = respuesta + "maximo de inquilinos de habitación\n";
    }*/
    return respuesta; // respuesta;
  }

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>Nueva Habitación</h1>
      <form onSubmit={submitHandler}>
        <div>
          <div>
            <label className={classes.labelP}>Nombre:</label>
            <input
              type="text"
              maxLength="4"
              ref={nombreRef}
              placeholder="Nombre habitación"
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Precio:</label>
            <NumericFormat
              maxLength="10"
              id="Precio"
              type="text"
              className={classes.textareaNE}
              placeholder="$0.00"
              prefix={"$"}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={precioInput}
              onValueChange={(values) => {
                setPrecioInput(values.floatValue);
              }}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Ancho:</label>
            <NumericFormat
              maxLength="10"
              id="Ancho"
              type="text"
              className={classes.textareaNE}
              placeholder="0.00"
              suffix={" mts"}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={anchoInput}
              onValueChange={(values) => {
                setAnchoInput(values.floatValue);
              }}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Largo:</label>
            <NumericFormat
              maxLength="10"
              id="Largo"
              type="text"
              className={classes.textareaNE}
              placeholder="0.00"
              suffix={" mts"}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={largoInput}
              onValueChange={(values) => {
                setLargoInput(values.floatValue);
              }}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Estatus:</label>
            <select ref={estatusRef} className={classes.textareaNE}>
              <option key="0" value={"0"}>
                Selecciona el estatus
              </option>
              <option key="1" value={"OK"}>
                OK
              </option>
              <option key="2" value={"REMOD"}>
                En Remodelacion
              </option>
            </select>
          </div>
          <div>
            <label className={classes.labelP}>Max Inquilinos:</label>
            <NumericFormat
              maxLength="1"
              id="MaxInq"
              type="text"
              className={classes.textareaNE}
              placeholder="1"
              decimalScale={0}
              defaultValue={maxInqInput}
              onValueChange={(values) => {
                setMaxInqInput(values.floatValue);
              }}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Observaciones:</label>
            <input
              type="text"
              ref={observRef}
              placeholder="algún comentario?"
              className={classes.textareaNE}
            ></input>
          </div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Las fotos se agregan en la ficha de la habitacion
          </label>
        </div>
      </form>
    </section>
  );
}

export default NuevaHabitacion;
