import { useLayoutEffect, useRef, useState } from "react";
import classes from "./dialogoOK.module.css";

function OKModal(props) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current !== null) {
      setDimensions({
        x: (window.innerWidth - targetRef.current.offsetWidth) / 2,
        y: 100,
      });
    }
  }, []);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y,
        left: dimensions.x,
      }}
    >
      <label className={classes.titulo}>Atencion</label>
      <label className={classes.texto}>{props.texto}</label>
      <div className={classes.renglonBotones}>
        <button onClick={(ev) => props.oculta(ev,1)} className={classes.botok}>
          {props.botones && props.botones.textoBoton1 ? (
            props.botones.textoBoton1
          ) : (
            <i className="fas fa-check"></i>
          )}
        </button>
        {props.botones && (
          <button onClick={(ev) => props.oculta(ev,2)} className={classes.botok}>
            {props.botones.textoBoton2}
          </button>
        )}
      </div>
    </div>
  );
}

export default OKModal;
