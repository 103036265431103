import { useEffect, useState } from "react";
import classes from "./CargaFoto.module.css";
import { EnviaArchivoAServicio } from "../../Funciones/Funciones";
import OKModal from "../dialogos/dialogoOK";
import Backdrop from "./backdrop";

function CargaFoto(props) {
  const [fotoSeleccionada, setFotoSeleccionada] = useState({
    target: null,
    foto: null,
  });
  const [preview, setPreview] = useState();
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "terminaCargaFoto") {
        props.terminaCargaFoto();
      }
      if (dialogoOK.recargar) {
        //setRecargar(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  useEffect(() => {
    if (!fotoSeleccionada.target) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fotoSeleccionada.target);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fotoSeleccionada]);

  function cambiaFoto(e) {
    //console.log("e", e);
    if (!e.target.files || e.target.files.length === 0) {
      setFotoSeleccionada(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFotoSeleccionada({ target: e.target.files[0], foto: reader.result });
    };
  }
  function cancelaFoto() {
    props.setMuestraModal(false);
  }
  const guardaFoto = async (e) => {
    if (fotoSeleccionada.target) {
      if (props.tit_Tipo.tipo !== "") {
        //console.log(fotoSeleccionada);
        const envio = await EnviaArchivoAServicio(
          fotoSeleccionada.target,
          props.IDEst +
            "_" +
            props.tit_Tipo.tipo +
            (props.tit_Tipo.tipo === "Contrato" ? ".pdf" : ".jpg"),
          props.accion
        );
        if (envio.ERROR !== "SI") {
          if (props.tit_Tipo.tipo === "Contrato") {
            setDialogoOK({
              texto: "pdf cambiado OK",
              mostrandoError: false,
              recargar: false,
              cerrarVentana: "terminaCargaFoto",
            });
            setMuestraDialogoOK(true);
            //alert("PDF cambiado OK");
          } else {
            setDialogoOK({
              texto: "Foto cambiada OK",
              mostrandoError: false,
              recargar: false,
              cerrarVentana: "terminaCargaFoto",
            });
            setMuestraDialogoOK(true);
            //alert("Foto cambiada OK");
          }
          //props.terminaCargaFoto();
        } else {
          setDialogoOK({
            texto: "Error: " + envio.DESCERROR,
            mostrandoError: true,
            recargar: false,
            cerrarVentana: "",
          });
          setMuestraDialogoOK(true);
          //alert(envio.DESCERROR);
        }
      }
    } else {
      if (props.tit_Tipo.tipo === "Contrato") {
        setDialogoOK({
          texto: "Selecciona el pdf a subir",
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        setMuestraDialogoOK(true);
        //alert("Selecciona el pdf a subir");
      } else {
        setDialogoOK({
          texto: "Selecciona la foto a subir",
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        setMuestraDialogoOK(true);
        //alert("Selecciona la foto a subir");
      }
    }
  };
  //console.log("preview", preview);

  /***************************************************************** */
  /*****************************RENDER ************************************ */
  /***************************************************************** */

  return (
    <div className={classes.dialogoCambiaFoto}>
      <div className={classes.contBotTop}>
        <button className={classes.botCancela} onClick={cancelaFoto}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h3 className={classes.titulo}>{props.tit_Tipo.titulo}</h3>
      <input
        className={classes.texto}
        type="file"
        accept={
          props.tit_Tipo.tipo === "Contrato" ? "application/pdf" : "image/*"
        }
        onChange={cambiaFoto}
      ></input>
      {fotoSeleccionada && (
        <img alt="" src={preview} className={classes.fotoPreview} />
      )}
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
      <div>
        <button onClick={guardaFoto} className={classes.innbtn}>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>
  );
}

export default CargaFoto;
