import { useRef, useState, useLayoutEffect } from "react";
import classes from "./PendientesFiltro.module.css";

function FiltroPendientes(props) {
  const targetRef = useRef();
  const PrioridadesRef = useRef();
  const AdministracionRef = useRef();
  const PendientesRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });
  

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionFiltroPendientes.x - targetRef.current.offsetWidth,
        y: props.posicionFiltroPendientes.y,
      });
    }
  }, [props.posicionFiltroPendientes]);

  function cambioFiltro() {
    props.setFiltroPendientes({
      Administracion: AdministracionRef.current.checked,
      Pendientes: PendientesRef.current.checked,
      Prioridades: PrioridadesRef.current.checked,
    });
    //console.log("cambiofiltro", sinAdeudoRef.current.checked);
  }
  //console.log("props", props);
  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top:
          dimensions.y === 0 ? props.posicionFiltroPendientes.y : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionFiltroPendientes.x : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <div className={classes.contCheckboxes}>
        <label>
          <input
            type="checkbox"
            ref={PrioridadesRef}
            defaultChecked={props.filtroPendientes.Prioridades}
            onChange={cambioFiltro}
          ></input>
          Prioridades
        </label>
        <label>
          <input
            type="checkbox"
            ref={PendientesRef}
            defaultChecked={props.filtroPendientes.Pendientes}
            onChange={cambioFiltro}
          ></input>
          Pendientes
        </label>
        <label hidden={props.nivel === 3}>
          <input
            type="checkbox"
            ref={AdministracionRef}
            defaultChecked={props.filtroPendientes.Administracion}
            onChange={cambioFiltro}
          ></input>
          Administracion
        </label>
      </div>
    </div>
  );
}

export default FiltroPendientes;
