import { useState } from "react";
var CryptoJS = require("crypto-js");

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    let userToken = "";
    if (tokenString !== null) {
      var bytes = CryptoJS.AES.decrypt(tokenString, "sha512lacasa");
      //console.log("bytes", bytes.toString(CryptoJS.enc.Utf8));
      userToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    //console.log("getToken", userToken);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    sessionStorage.setItem(
      "token",
      CryptoJS.AES.encrypt(JSON.stringify(userToken), "sha512lacasa")
    );
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
