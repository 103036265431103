import { addMonths, addDays } from "date-fns";
import { useEffect, useState, useRef } from "react";
import OKModal from "../components/dialogos/dialogoOK";
import MenuFiltraIngresos from "../components/Ingresos/IngresoFiltroMenu";
import NuevoIngreso from "../components/Ingresos/IngresoNuevo";
import MenuOrdenaIngresos from "../components/Ingresos/IngresoOrdenaMenu";
import IngresosLista from "../components/Ingresos/IngresosLista";
import Backdrop from "../components/layout/backdrop";
import { FechaParaInputDate, YYYYMMDDdeDate } from "../Funciones/Funciones";
import classes from "./TodosLosIngresos.module.css";

function TodosLosIngresosPage(props) {
  const [ingresosLoaded, setIngresosLoaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [muestraFiltroIng, setMuestraFiltroIng] = useState(false);
  const [muestraOrdenaIng, setMuestraOrdenaIng] = useState(false);
  const [posFiltroIngresos, setPosFiltroIngresos] = useState([]);
  const [ordenaIngresos, setOrdenaIngresos] = useState({
    fechaAZ: false,
    fechaZA: true,
    habitacionAZ: false,
    habitacionZA: false,
    montoAZ: false,
    montoZA: false,
  });
  const [filtroIngresos, setFiltroIngresos] = useState({
    Otros: true,
    Renta: true,
    DepGar: true,
    Efectivo: true,
    Transferencia: true,
    Nombre: "",
    Habitacion: "",
    FechaIni: FechaInicio(new Date(), "carga"),
    FechaFin: FechaFin(new Date(), "carga"),
  });
  const [muestraNuevoIngreso, setMuestraNuevoIngreso] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [muestraEdicionPago, setMuestraEdicionPago] = useState(false);
  const [datosEdicionPago, setDatosEdicionPago] = useState([]);
  const fechaIniRef = useRef();
  const fechaFinRef = useRef();
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);

  function ExportaExcel() {
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "ExportaIngresos",
        IDCasa: props.casaSel,
        FechaIni: filtroIngresos.FechaIni,
        FechaFin: filtroIngresos.FechaFin,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        console.log(data, data.ERROR);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Ingresos.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "pagoNuevo") {
        setMuestraNuevoIngreso(false);
        setMuestraEdicionPago(false);
      }
      if (dialogoOK.cerrarVentana === "mostrarEdicion") {
        //setalgo
      }
      if (dialogoOK.cerrarVentana === "mostrarElimina") {
        //setMostrarEdicion(false);
      }
      if (dialogoOK.cerrarVentana === "NuevoEstudiante") {
        //setAlgo
      }
      if (dialogoOK.recargar) {
        //setMostrarElimina(false);
        //setMostrarNuevo(false);
        //if (dialogoOK.cerrarVentana === "NuevoEgreso")
        //  setMuestraNuevoEgreso(false);
        setRecargar(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  function editaPago(ev) {
    ev.preventDefault();
    const pago = ingresosLoaded.find((pago) => pago.IDIng === ev.target.id);
    console.log("EP", pago);
    setDatosEdicionPago(pago);
    setMuestraEdicionPago(true);
  }

  function cambioFecha() {
    const nvoFiltro = {
      ...filtroIngresos,
      ...{
        FechaIni: YYYYMMDDdeDate(
          new Date(fechaIniRef.current.value + " 0:0:0")
        ),
        FechaFin: YYYYMMDDdeDate(
          new Date(fechaFinRef.current.value + " 0:0:0")
        ),
      },
    };
    //console.log("nvofiltro", nvoFiltro);
    setFiltroIngresos(nvoFiltro);
  }

  function PagoCompletado() {
    setMuestraNuevoIngreso(false);
    setRecargar(true);
  }

  function FechaInicio(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addDays(fecha, -(fecha.getDate() - 1));
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    //console.log("tempi", temp);
    return temp;
  }
  function FechaFin(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addMonths(fecha, 1);
      fecha = addDays(fecha, -fecha.getDate());
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    return temp;
  }

  function OrdenaIngresos(ev) {
    setPosFiltroIngresos({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraOrdenaIng(true);
  }

  function reOrdenaIngresos(a, b) {
    if (ordenaIngresos.fechaAZ) return a.FechaPag > b.FechaPag ? 1 : -1;
    if (ordenaIngresos.fechaZA) return a.FechaPag < b.FechaPag ? 1 : -1;
    if (ordenaIngresos.habitacionAZ)
      return a.Habitacion > b.Habitacion ? 1 : -1;
    if (ordenaIngresos.habitacionZA)
      return a.Habitacion < b.Habitacion ? 1 : -1;
    if (ordenaIngresos.montoAZ)
      return parseFloat(a.Monto) > parseFloat(b.Monto) ? 1 : -1;
    if (ordenaIngresos.montoZA)
      return parseFloat(a.Monto) < parseFloat(b.Monto) ? 1 : -1;
  }

  function FiltraIngresos(ev) {
    setPosFiltroIngresos({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraFiltroIng(true);
  }

  function queIngresosMostrar(ingreso) {
    if (
      ingreso.Estudiante.toUpperCase().indexOf(
        filtroIngresos.Nombre.toUpperCase()
      ) < 0
    )
      return false;
    if (
      ingreso.Habitacion.toUpperCase().indexOf(
        filtroIngresos.Habitacion.toUpperCase()
      ) < 0
    )
      return false;
    if (
      ingreso.FormaPago.toUpperCase() === "EFECTIVO" &&
      !filtroIngresos.Efectivo
    )
      return false;
    if (
      ingreso.FormaPago.toUpperCase() !== "EFECTIVO" &&
      !filtroIngresos.Transferencia
    )
      return false;
    if (
      ingreso.Concepto.toUpperCase() === "DEPOSITO EN GARANTIA" &&
      !filtroIngresos.DepGar
    )
      return false;
    if (ingreso.Concepto.toUpperCase() === "RENTA" && !filtroIngresos.Renta)
      return false;
    if (
      ingreso.Concepto.toUpperCase() !== "RENTA" &&
      ingreso.Concepto.toUpperCase() !== "DEPOSITO EN GARANTIA" &&
      !filtroIngresos.Otros
    )
      return false;
    return true;
  }

  useEffect(() => {
    setMuestraDialogoOK(false);
    setMuestraEdicionPago(false);
    setMuestraFiltroIng(false);
    setMuestraNuevoIngreso(false);
    setMuestraOrdenaIng(false);
    if (props.casaSel > 0) {
      setRecargar(false);
      setIsLoading(true);
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "listadoIngresos",
          IDCasa: props.casaSel,
          FechaIni: filtroIngresos.FechaIni,
          FechaFin: filtroIngresos.FechaFin,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var formasPago = {};
          var estudiantes = [];
          var habitaciones = [];
          var ingresos = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "FormasPago") {
                formasPago = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "Habitaciones") {
                habitaciones = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "Ingresos") {
                ingresos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "Inquilinos") {
                estudiantes = Object.keys(datos).map((key) => datos[key]);
              }
            }
            //console.log("formasPago", formasPago);
            //console.log("habitaciones", habitaciones);
            //console.log("estudiantes", estudiantes);
            //console.log("ingresos", ingresos);
            const ingt = ingresos.map((ingt1) => {
              //console.log("ingt1", ingt1);
              return {
                Concepto: ingt1.Concepto,
                FechaPag: ingt1.FechaPag,
                IDEst: ingt1.IDEst,
                Estudiante:
                  ingt1.IDEst !== "0"
                    ? estudiantes.filter(
                        (estudiante) => estudiante.Datos.IDEst === ingt1.IDEst
                      )[0].Datos.Nombre
                    : ingt1.Observaciones.substr(
                        ingt1.Observaciones.indexOf("&&&") + 3,
                        ingt1.Observaciones.substring(
                          ingt1.Observaciones.indexOf("&&&") + 3
                        ).indexOf("&&&")
                      ),
                IDForPag: ingt1.IDForPag,
                FormaPago: formasPago.filter(
                  (formaPago) => formaPago.IDForPag === ingt1.IDForPag
                )[0].FormaPago,
                IDHab: ingt1.IDHab,
                Habitacion:
                  ingt1.IDHab !== "0"
                    ? habitaciones.filter(
                        (habitacion) => habitacion.IDHab === ingt1.IDHab
                      )[0].Habitacion
                    : "N/A",
                IDIng: ingt1.IDIng,
                MesQPag: ingt1.MesQPag,
                Monto: ingt1.Monto,
                Observaciones: ingt1.Observaciones,
              };
            });
            //console.log("ingresos", ingt);
            //setFormasPagoLoaded(formasPago);
            //setHabitacionesLoaded(habitaciones);
            setIngresosLoaded(ingt);
            //setEstudiantesLoaded(estudiantes);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.ERROR);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [
    props.casaSel,
    filtroIngresos.FechaFin,
    filtroIngresos.FechaIni,
    recargar,
  ]);

  /******************************************************************************************** */
  /***************************************** RENDER ******************************************* */
  /******************************************************************************************** */

  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.centrarElem}>
      <div className={classes.TituloBoton}>
        <div className="TitEst">
          <h1>Ingresos</h1>
          <label className={classes.rangoFechas}>
            <input
              required
              type="date"
              ref={fechaIniRef}
              defaultValue={FechaParaInputDate(filtroIngresos.FechaIni)}
              onChange={cambioFecha}
            ></input>{" "}
            al{" "}
            <input
              required
              type="date"
              ref={fechaFinRef}
              defaultValue={FechaParaInputDate(filtroIngresos.FechaFin)}
              onChange={cambioFecha}
            ></input>
          </label>
        </div>
        <div className="contBotones">
          {ingresosLoaded.length > 0 ? (
            <button className="botAgregaEst" onClick={OrdenaIngresos}>
              <i className="fas fa-sort-amount-up-alt"></i>
            </button>
          ) : null}
          <button className="botAgregaEst" onClick={FiltraIngresos}>
            <i className="fas fa-filter"></i>
          </button>
          <button className="botAgregaEst" onClick={ExportaExcel}>
            <i className="fas fa-file-excel"></i>
          </button>
          {props.nivel === 1 ? (
            <button className="botAgregaEst" onClick={setMuestraNuevoIngreso}>
              <i className="fas fa-plus-square"></i>
            </button>
          ) : null}
        </div>
      </div>
      <IngresosLista
        nivel={props.nivel}
        editaPago={editaPago}
        setRecargar={setRecargar}
        setDialogoOK={setDialogoOK}
        setMuestraDialogoOK={setMuestraDialogoOK}
        ingresosLoaded={ingresosLoaded
          .sort((a, b) => reOrdenaIngresos(a, b))
          .filter((ingreso) => queIngresosMostrar(ingreso))}
      />
      {muestraFiltroIng && (
        <MenuFiltraIngresos
          posicionFiltroIngresos={posFiltroIngresos}
          setFiltroIngresos={setFiltroIngresos}
          filtroIngresos={filtroIngresos}
          setMuestraFiltroIng={setMuestraFiltroIng}
        />
      )}
      {muestraOrdenaIng && (
        <MenuOrdenaIngresos
          posicionOrdenaIngresos={posFiltroIngresos}
          setOrdenaIngresos={setOrdenaIngresos}
          ordenaIngresos={ordenaIngresos}
          setMuestraOrdenaIng={setMuestraOrdenaIng}
        />
      )}
      {muestraEdicionPago && (
        <NuevoIngreso
          PagoCompletado={PagoCompletado}
          setMostrarNuevo={setMuestraEdicionPago}
          edicionPago={datosEdicionPago}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {muestraNuevoIngreso && (
        <NuevoIngreso
          setMostrarNuevo={setMuestraNuevoIngreso}
          pagoOtros={true}
          PagoCompletado={PagoCompletado}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
      {muestraEdicionPago && <Backdrop />}
      {muestraFiltroIng && <Backdrop recarga={setMuestraFiltroIng} />}
      {muestraOrdenaIng && <Backdrop recarga={setMuestraOrdenaIng} />}
      {muestraNuevoIngreso && <Backdrop />}
    </section>
  );
}

export default TodosLosIngresosPage;
