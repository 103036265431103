import { useRef } from "react";
import "./LoginForm.css";

function LoginForm(props) {
  const usuarioRef = useRef();
  const contrasenaRef = useRef();

  function submitHandler(event) {
    event.preventDefault();

    const enteredUsuario = usuarioRef.current.value;
    const enteredContrasena = contrasenaRef.current.value;

    const credenciales = {
      usuario: enteredUsuario,
      contrasena: enteredContrasena,
    };

    props.onLogin(credenciales);
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="login-wrapper">
        <div className="titulo">La Casa</div>
        <div className="loginDet">
          <input
            autoComplete="off"
            className="inputC"
            type="text"
            name="usuario"
            id="usuario"
            placeholder="Usuario"
            required
            autoFocus
            ref={usuarioRef}
          />
          <input
            className="inputC"
            type="password"
            name="contrasena"
            id="contrasena"
            placeholder="Contraseña"
            required
            ref={contrasenaRef}
          />
          <button className="inputB">Entrar</button>
          <label className="ver">ver {props.vers}</label>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
