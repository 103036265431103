import { useRef, useState, useLayoutEffect } from "react";
import classes from "./HabitacionesFiltra.module.css";

function MenuFiltraHabitaciones(props) {
  const targetRef = useRef();
  const nombreRef = useRef();
  const habitacionRef = useRef();
  const ocupadaRef = useRef();
  const remodelacionRef = useRef();
  const desocupadaRef = useRef();
  const maxInqRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  function cancelaFiltro() {
    nombreRef.current.value = "";
    habitacionRef.current.value = "";
    maxInqRef.current.value = "";
    props.setFiltroHabitaciones({
      ...props.filtroHabitaciones,
      ...{
        Ocupada: true,
        Remodelacion: true,
        Desocupada: true,
        Nombre: "",
        Habitacion: "",
        MaxInq: "",
      },
    });
    props.setMuestraFiltroHab(false);
  }

  function cambioFiltro() {
    props.setFiltroHabitaciones({
      ...props.filtroHabitaciones,
      ...{
        Ocupada: ocupadaRef.current.checked,
        Remodelacion: remodelacionRef.current.checked,
        Desocupada: desocupadaRef.current.checked,
        Nombre: nombreRef.current.value,
        Habitacion: habitacionRef.current.value,
        MaxInq: maxInqRef.current.value,
      },
    });
  }

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionFiltroHabitaciones.x - targetRef.current.offsetWidth,
        y: props.posicionFiltroHabitaciones.y,
      });
    }
  }, [props.posicionFiltroHabitaciones]);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top:
          dimensions.y === 0
            ? props.posicionFiltroHabitaciones.y
            : dimensions.y,
        left:
          dimensions.x === 0
            ? props.posicionFiltroHabitaciones.x
            : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <label>
        <input
          type="checkbox"
          ref={ocupadaRef}
          defaultChecked={props.filtroHabitaciones.Ocupada}
          onChange={cambioFiltro}
        ></input>
        Ocupada
      </label>
      <label>
        <input
          type="checkbox"
          ref={desocupadaRef}
          defaultChecked={props.filtroHabitaciones.Desocupada}
          onChange={cambioFiltro}
        ></input>
        Desocupada
      </label>
      <label>
        <input
          type="checkbox"
          ref={remodelacionRef}
          defaultChecked={props.filtroHabitaciones.Remodelacion}
          onChange={cambioFiltro}
        ></input>
        En Remodelacion
      </label>
      <label>
        Nombre:{" "}
        <input
          type="text"
          ref={nombreRef}
          defaultValue={props.filtroHabitaciones.Nombre}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Habitación:{" "}
        <input
          type="text"
          ref={habitacionRef}
          defaultValue={props.filtroHabitaciones.Habitacion}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Max Inq:{" "}
        <input
          type="text"
          ref={maxInqRef}
          defaultValue={props.filtroHabitaciones.MaxInq}
          onChange={cambioFiltro}
        ></input>
      </label>
      <div className={classes.botok}>
        <button onClick={cancelaFiltro}>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => {
            props.setMuestraFiltroHab(false);
          }}
        >
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>
  );
}

export default MenuFiltraHabitaciones;
