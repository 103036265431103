import { useRef, useState } from "react";
import { EnviaDatosAServicio } from "../../Funciones/Funciones";
import classes from "./LlaveEdicion.module.css";
import { Slide, toast } from "react-toastify";
import { PatternFormat } from "react-number-format";

function EdicionLlave(props) {
  //const llaveRef = useRef();
  const [LlaveInput, setllaveInput] = useState(props.llave.Llave);
  const estatusRef = useRef();
  const observRef = useRef();

  function CancelaEdicion(ev) {
    ev.preventDefault();
    props.setMostrarLlave(false);
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      const llaveN = {
        IDLlave: props.llave.IDLlave,
        Llave: LlaveInput.toString().trim(),
        Estatus: estatusRef.current.value,
        Observaciones: observRef.current.value,
        IDCasa: props.llave.IDCasa,
      };
      //console.log("llaveN", llaveN);
      const envio = await EnviaDatosAServicio(llaveN, "ModificaLlave");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        /*
        props.setTextoModal("Guardado OK");
        props.setMostrandoError(false);
        props.setMuestraDialogoOK(true);*/
        props.llaveCompleta();
      } else {
        props.setTextoModal("Error al guardar:\n" + envio.DESCERROR);
        props.setMostrandoError(true);
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    } else {
      props.setTextoModal("Revisa los siguientes datos\n" + DatosValidos);
      props.setMostrandoError(true);
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (LlaveInput === "" || parseInt(LlaveInput) === 0) {
      respuesta = respuesta + "Numero de la llave\n";
    }
    if (estatusRef.current.value === "0") {
      respuesta = respuesta + "estatus de habitación\n";
    }
    return respuesta; // respuesta;
  }

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaEdicion}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>Ficha Llave</h1>
      <form onSubmit={submitHandler}>
        <div>
          <div>
            <label className={classes.labelP}>Llave:</label>
            <PatternFormat
              maxLength="12"
              format="##########"
              valueIsNumericString={true}
              id="Llave"
              type="text"
              defaultValue={props.llave.Llave}
              className={classes.textareaNE}
              placeholder="Número llave"
              onValueChange={(values) => {
                setllaveInput(values.formattedValue);
              }}
            ></PatternFormat>
          </div>
          <div>
            <label className={classes.labelP}>Estatus:</label>
            <select
              ref={estatusRef}
              className={classes.textareaNE}
              defaultValue={props.llave.Estatus}
            >
              <option key="0" value={"0"}>
                Selecciona el estatus
              </option>
              <option key="1" value={"OK"}>
                OK
              </option>
              <option key="2" value={"PERDIDA"}>
                Perdida
              </option>
              <option key="3" value={"NOSIRVE"}>
                No Sirve
              </option>
            </select>
          </div>
          <div>
            <label className={classes.labelP}>Observaciones:</label>
            <input
              type="text"
              ref={observRef}
              placeholder="algún comentario?"
              defaultValue={props.llave.Observaciones}
              className={classes.textareaNE}
            ></input>
          </div>
        </div>
      </form>
    </section>
  );
}

export default EdicionLlave;
