import { useState, useEffect } from "react";
import useToken from "../login/useToken";
import DepGarItem from "./DepGarItem";
import classes from "./ResumenDepGar.module.css";

function ResumenDepGarLista(props) {
  const [depGar, setDepGar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = useToken().token;
  const idCasa = token.casaSel;

  function ocultaVentana() {
    props.setMuestraResumenDepGar();
  }

  useEffect(() => {
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "ResumenDepGar",
        IDCasa: idCasa,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var depGar = [];
        for (const key in data) {
          const datos = {
            ...data[key],
          };
          if (key === "DepGar") {
            depGar = Object.keys(datos).map((key) => datos[key]);
          }
        }
        //console.log(totales);
        setIsLoading(false);
        //console.log("DepGar", depGar);
        //console.log("totEgresos", totEgresos);
        //console.log("totIngresos", totIngresos);
        setDepGar(depGar);
      });
  }, [idCasa]);

  if (isLoading) {
    //console.log("cargando");
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <h1>Depósitos en garantía vigentes</h1>
      <div className={classes.divFichas}>
        {depGar.length === 0 ? (
          <h1>No hay depósitos en garantia vigentes</h1>
        ) : (
          depGar.map((dg, index) => (
            <DepGarItem key={index} ingreso={dg} sinbotones={true} />
          ))
        )}
      </div>

      <div className={classes.divBotones}>
        <button className={classes.botones} onClick={ocultaVentana}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </section>
  );
}

export default ResumenDepGarLista;
