import classes from "./Menu.module.css";
import useToken from "../../login/useToken";
import Backdrop from "../../layout/backdrop";
import { Link, useNavigate } from "react-router-dom";

const Menu = (props) => {
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const casaSel = token.casaSel > 0 ? true : false;

  function Logout() {
    console.log("borrando");
    token.ErrorLogin = "Si";
    token.casaSel = "";
    token.nombreCasaSel = "";
    setToken(token);
    navigate("/");
    window.location.reload();
  }

  function recarga() {
    props.setOpened();
  }

  function EsConserje() {
    return token.nivel === 3;
  }

  function EsInvitado() {
    return token.nivel === 4;
  }

  return (
    <div className={classes.mainMenu}>
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/inicio" onClick={recarga}>
          <span role="img" aria-label="Inicio">
            🏠
          </span>
          Inicio
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/estudiantes" onClick={recarga}>
          <span role="img" aria-label="estudiantes">
            🧑
          </span>
          Estudiantes
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/ingresos" onClick={recarga}>
          <span role="img" aria-label="ingresos">
            💰
          </span>
          Ingresos
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/egresos" onClick={recarga}>
          <span role="img" aria-label="egresos">
            💸
          </span>
          Egresos
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/habitaciones" onClick={recarga}>
          <span role="img" aria-label="habitaciones">
            🛏️
          </span>
          Habitaciones
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/llaves" onClick={recarga}>
          <span role="img" aria-label="llaves">
            🔑
          </span>
          Llaves
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/resumenes" onClick={recarga}>
          <span role="img" aria-label="resumenes">
            📝
          </span>
          Resúmenes
        </Link>
      )}
      {casaSel && !EsInvitado() && (
        <Link className={classes.liga} to="/pendientes" onClick={recarga}>
          <span role="img" aria-label="pendientes">
            📃
          </span>
          Pendientes
        </Link>
      )}
      {casaSel && token.nivel === 1 && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/temporal" onClick={recarga}>
          <span role="img" aria-label="pendientes">
            ⏲️
          </span>
          Temporal
        </Link>
      )}
      {casaSel && !EsInvitado() && !EsConserje() && (
        <Link className={classes.liga} to="/ajustes" onClick={recarga}>
          <span role="img" aria-label="ajustes">
            ⚙️
          </span>
          Ajustes
        </Link>
      )}
      <Link className={classes.liga} to="/" onClick={Logout}>
        <span role="img" aria-label="salir">
          🚪
        </span>
        Salir
      </Link>
      <Backdrop recarga={recarga} />
    </div>
  );
};
export default Menu;
