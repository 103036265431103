import classes from "./SubMenuEstudiantes.module.css";

function SubMenuEstudiantes(props) {
  return (
    <div
      className={classes.modal}
      style={{
        top: props.posicionSubMenu.posY,
        left: props.posicionSubMenu.posX,
      }}
    >
      <label
        className={classes.botones}
        onClick={() => props.muestraPagosEstudiante(props.estudiante.IDEst)}
      >
        Pagos estudiante
      </label>
      {props.nivel === 1 ? <label
        className={classes.botones}
        onClick={() => props.cambiaHabitacion(props.estudiante.IDEst)}
      >
        Cambiar habitación
      </label>: null}
    </div>
  );
}

export default SubMenuEstudiantes;
