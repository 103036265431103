import { FormateaCurrency } from "../../Funciones/Funciones";
import classes from "./ResumenItem.module.css";

function ItemResumen(props) {
  function clickeado(ev) {
    ev.preventDefault();
    props.muestraDetalle(props.flujo.titulo);
  }
  //console.log(props.flujo);
  return (
    <div className={classes.back} id={"fondoItem"} onClick={clickeado}>
      <div>
        <label className={classes.nombre}>{props.flujo.titulo}</label>
        {props.flujo.titulo !== "Depósitos en garantía" ? (
          <div>
            <label className={classes.otros}>
              Ingresos: {FormateaCurrency.format(props.flujo.ingresos)}
            </label>
            <label className={classes.otros}>
              Egresos: {FormateaCurrency.format(props.flujo.egresos)}
            </label>
            <label className={classes.otrosNegrita}>
              Total:{" "}
              {FormateaCurrency.format(
                props.flujo.ingresos - props.flujo.egresos
              )}
            </label>
          </div>
        ) : (
          <div>
            <label className={classes.otros}>
              En resguardo: {FormateaCurrency.format(props.flujo.ingresos)}
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemResumen;
