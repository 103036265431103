import { addYears } from "date-fns";
import { useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import classes from "./EstudianteFechasContrato.module.css";

function FechasNuevoContrato(props) {
  const fechaInicioRef = useRef();
  const fechaVenceRef = useRef();
  const [precioNuevo, setPrecioNuevo] = useState(props.estudiante.Precio);

  function cancelaRenovacion(ev) {
    ev.preventDefault();
    props.setMuestraRenueva(false);
  }

  function guardaRenovacion(ev) {
    ev.preventDefault();
    console.log(
      "" + props.estudiante.Precio === "" + precioNuevo,
      "" + props.estudiante.Precio,
      "" + precioNuevo
    );
    const Fechas = {
      IniciaCont: fechaInicioRef.current.value,
      VenceCont: fechaVenceRef.current.value,
      PrecioNuevo:
        "" + props.estudiante.Precio === "" + precioNuevo ? 0 : precioNuevo,
    };
    props.terminaRenueva(Fechas);
  }

  /***************************************************************** */
  /*****************************RENDER ************************************ */
  /***************************************************************** */
  
  return (
    <div className={classes.dialogoRenuevaContrato}>
      <h1 className={classes.titulo}>Nuevas fechas contrato</h1>
      <label>
        Nuevo Inicio
        <input
          defaultValue={new Date().toISOString().substr(0, 10)}
          required
          ref={fechaInicioRef}
          type="date"
        ></input>
      </label>
      <label>
        Nuevo Vencimiento
        <input
          defaultValue={addYears(new Date(), 1).toISOString().substr(0, 10)}
          required
          ref={fechaVenceRef}
          type="date"
        ></input>
      </label>
      <h1 className={classes.titulo}>
        Habitación {props.estudiante.Habitacion}
      </h1>
      <div>
        <label className={classes.labelP}>Precio actual</label>
        <NumericFormat
          readOnly
          type="text"
          className={classes.textareaNE}
          defaultValue={props.estudiante.Precio}
          prefix={"$"}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          decimalScale={2}
        ></NumericFormat>
      </div>
      <div>
        <label className={classes.labelP}>Precio nuevo</label>
        <NumericFormat
          id="precioNuevo"
          type="text"
          className={classes.textareaNE}
          defaultValue={props.estudiante.Precio}
          prefix={"$"}
          decimalScale={2}
          value={precioNuevo}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            setPrecioNuevo(values.floatValue);
          }}
        ></NumericFormat>
      </div>
      <div>
        <button onClick={guardaRenovacion} className={classes.innbtn}>
          <i className="fas fa-check"></i>
        </button>
        <button onClick={cancelaRenovacion} className={classes.innbtn}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  );
}

export default FechasNuevoContrato;
