import { useEffect, useRef, useState } from "react";
import {
  EnviaDatosANuevoServicio,
  FechaMMMDeString,
} from "../../Funciones/Funciones";
import CargaFoto from "../layout/CargaFoto";
import useToken from "../login/useToken";
import classes from "./HabitacionEdicion.module.css";
import { Slide, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

function EdicionHabitacion(props) {
  const nombreRef = useRef();
  //const precioRef = useRef();
  //const anchoRef = useRef();
  //const largoRef = useRef();
  const estatusRef = useRef();
  //const maxInqRef = useRef();
  const observRef = useRef();
  const token = useToken().token;
  const [timeStamp, setTimeStamp] = useState(new Date());
  const [muestraModal, setMuestraModal] = useState(false);
  const [tit_Tipo, setTit_Tipo] = useState([]);
  const [recargarFotos, setRecargarFotos] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fotosExtras, setFotosExtras] = useState([]);
  const [preciosAnt, setPreciosAnt] = useState([]);
  const [precioInput, setPrecioInput] = useState(props.habitacion.Precio);
  const [anchoInput, setAnchoInput] = useState(props.habitacion.Ancho);
  const [largoInput, setLargoInput] = useState(props.habitacion.Largo);
  const [maxInqInput, setMaxInqInput] = useState(props.habitacion.MaxInq);
  const [hayCambios, setHayCambios] = useState(false);
  const idCasa = token.casaSel;
  const idUsu = token.Id;

  function terminaCargaFoto() {
    setTimeStamp(new Date());
    setMuestraModal(false);
    setRecargarFotos(true);
  }

  function agregaFotoHabitacion(ev) {
    //console.log("ev", ev.target.id);
    ev.preventDefault();
    const temp = {
      titulo: "Carga foto habitacion",
      tipo: "Foto" + (parseInt(fotosExtras.length) + 1),
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function respServ(resp) {
    if (resp.Error !== "SI") {
      const hab = resp.RHabitaciones[0];
      var fotosExtras = [];
      for (var j = 0; j < hab.NumFotos; j += 1) {
        fotosExtras.push("Foto" + (j + 1));
      }
      const preciosAntT = resp.preciosAnt.map((precio, indice) => {
        const strT = precio.split(",");
        return { fecha: strT[0], precio: strT[1], key: indice };
      });
      console.log("preciosAnt", preciosAntT);
      setFotosExtras(fotosExtras);
      setPreciosAnt(preciosAntT.reverse());
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log("ERROR", resp.DESCERROR);
    }
  }

  useEffect(() => {
    if (idCasa > 0) {
      setIsLoading(true);
      setRecargarFotos(false);
      const datos = {
        IDCasa: idCasa,
        IDHab: props.habitacion.IDHab,
      };
      EnviaDatosANuevoServicio(
        "resumenHabitaciones",
        datos,
        respServ,
        setIsLoading,
        null,
        idUsu
      );
    } else {
      setIsLoading(false);
    }
  }, [idCasa, recargarFotos, props.habitacion.IDHab, idUsu]);

  function cambiaFotoExtra(ev) {
    ev.preventDefault();
    //console.log("ev", ev);
    const temp = {
      titulo: "Carga foto habitacion",
      tipo: ev.target.id,
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function cambiaFotoHabitacion(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga foto habitacion",
      tipo: "ficha",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function CancelaEdicion(ev) {
    ev.preventDefault();
    props.setMostrarHabitacion(false);
  }

  function respServGuarda(resp) {
    console.log(resp);
    if (resp.Error === "") {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.habitacionCompleta();
    } else {
      props.setDialogoOK({
        texto: "Error al guardar: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      //console.log("mesQuePagaRef", mesQuePagaRef.current.value);
      //console.log("nombreRef", nombreRef);
      const habitacionN = {
        IDHab: props.habitacion.IDHab,
        Habitacion: nombreRef.current.value,
        Precio: precioInput, // DejaSoloNumerosDeCurrency(precioRef.current.value),
        Ancho: anchoInput, // anchoRef.current.value,
        Largo: largoInput, // largoRef.current.value,
        Estatus: estatusRef.current.value,
        MaxInq: maxInqInput, // maxInqRef.current.value,
        Observaciones: observRef.current.value,
        IDCasa: idCasa,
      };
      //console.log("ingresoN", ingresoN);
      EnviaDatosANuevoServicio(
        "editarHabitacion",
        habitacionN,
        respServGuarda,
        setIsLoading,
        null,
        idUsu
      );
      /*
      const envio = await EnviaDatosAServicio(habitacionN, "editarHabitacion");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        
        //alert("Guardado OK");
        props.habitacionCompleta();
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }*/
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (nombreRef.current.value === "") {
      respuesta = respuesta + "Nombre de habitación\n";
    }
    if (precioInput === 0 || precioInput === undefined) {
      respuesta = respuesta + "precio de habitación\n";
    }

    if (anchoInput === 0 || anchoInput === undefined) {
      respuesta = respuesta + "ancho de habitación\n";
    }

    if (largoInput === 0 || largoInput === undefined) {
      respuesta = respuesta + "largo de habitación\n";
    }

    if (estatusRef.current.value === "0") {
      respuesta = respuesta + "estatus de habitación\n";
    }
    if (maxInqInput === 0 || maxInqInput === undefined) {
      respuesta = respuesta + "maximo de inquilinos de habitación\n";
    }
    return respuesta; // respuesta;
  }

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  //console.log("props", props);
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {hayCambios && props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaEdicion}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>Ficha Habitación</h1>
      <form onSubmit={submitHandler}>
        <div>
          <div>
            <label className={classes.labelP}>Habitación ID:</label>
            <input
              readOnly
              type="text"
              maxLength="4"
              value={props.habitacion.IDHab}
              className={classes.textareaNE}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Nombre:</label>
            <input
              type="text"
              maxLength="4"
              ref={nombreRef}
              placeholder="Nombre habitación"
              defaultValue={props.habitacion.Habitacion}
              className={classes.textareaNE}
              onChange={setHayCambios}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Precio:</label>
            <NumericFormat
              maxLength="10"
              id="Precio"
              type="text"
              className={classes.textareaNE}
              placeholder="$0.00"
              prefix={"$"}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={precioInput}
              onValueChange={(values) => {
                setPrecioInput(values.floatValue);
              }}
              onChange={setHayCambios}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Ancho:</label>
            <NumericFormat
              maxLength="10"
              id="Ancho"
              type="text"
              className={classes.textareaNE}
              placeholder="0.00"
              suffix={" mts"}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={anchoInput}
              onValueChange={(values) => {
                setAnchoInput(values.floatValue);
              }}
              onChange={setHayCambios}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Largo:</label>
            <NumericFormat
              maxLength="10"
              id="Largo"
              type="text"
              className={classes.textareaNE}
              placeholder="0.00"
              suffix={" mts"}
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              defaultValue={largoInput}
              onValueChange={(values) => {
                setLargoInput(values.floatValue);
              }}
              onChange={setHayCambios}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Estatus:</label>
            <select
              ref={estatusRef}
              className={classes.textareaNE}
              defaultValue={props.habitacion.Estatus}
              onChange={setHayCambios}
            >
              <option key="0" value={"0"}>
                Selecciona el estatus
              </option>
              <option key="1" value={"OK"}>
                OK
              </option>
              <option key="2" value={"REMOD"}>
                En Remodelacion
              </option>
            </select>
          </div>
          <div>
            <label className={classes.labelP}>Max Inquilinos:</label>
            <NumericFormat
              maxLength="1"
              id="MaxInq"
              type="text"
              className={classes.textareaNE}
              placeholder="1"
              decimalScale={0}
              defaultValue={maxInqInput}
              onValueChange={(values) => {
                setMaxInqInput(values.floatValue);
              }}
              onChange={setHayCambios}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>Observaciones:</label>
            <input
              type="text"
              ref={observRef}
              placeholder="algún comentario?"
              defaultValue={props.habitacion.Observaciones}
              className={classes.textareaNE}
              onChange={setHayCambios}
            ></input>
          </div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Precios Anteriores
          </label>
          {preciosAnt.length > 0 ? (
            preciosAnt.map((precioA) => {
              return (
                <div key={precioA.key}>
                  <label className={classes.labelP}>
                    hasta el {FechaMMMDeString(precioA.fecha)}
                  </label>
                  <NumericFormat
                    readOnly
                    type="text"
                    prefix={"$"}
                    decimalScale={0}
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    className={classes.textareaNE1}
                    value={precioA.precio}
                  ></NumericFormat>
                </div>
              );
            })
          ) : (
            <label className={classes.labelP1}>
              No se ha cambiado el precio
            </label>
          )}

          <label className={classes.tituloP + " " + classes.labelP}>
            Foto Ficha
          </label>
          <div>
            <img
              key={timeStamp}
              className={classes.fotoEstud}
              src={
                "https://www.copilco.net/expedientes/habitaciones/" +
                props.habitacion.IDHab +
                "_ficha.jpg?" +
                timeStamp
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://www.copilco.net/expedientes/habitaciones/default.jpg";
              }}
              alt={props.habitacion.Habitacion}
            />
            {props.nivel === 1 ? (
              <button
                onClick={cambiaFotoHabitacion}
                id={props.habitacion.IDHab}
                className={classes.innbtn}
              >
                <i className="fas fa-edit"></i>
              </button>
            ) : null}
          </div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Otras Fotos
          </label>
          {fotosExtras.map((fotoE) => (
            <div key={fotoE + timeStamp}>
              <img
                className={classes.fotoEstud}
                src={
                  "https://www.copilco.net/expedientes/habitaciones/" +
                  props.habitacion.IDHab +
                  "_" +
                  fotoE +
                  ".jpg?" +
                  timeStamp
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://www.copilco.net/expedientes/habitaciones/default.jpg";
                }}
                alt={props.habitacion.Habitacion}
              />
              {props.nivel === 1 ? (
                <button
                  onClick={cambiaFotoExtra}
                  id={fotoE}
                  className={classes.innbtn}
                >
                  <i id={fotoE} className="fas fa-edit"></i>
                </button>
              ) : null}
            </div>
          ))}
          {props.nivel === 1 ? (
            <button
              onClick={agregaFotoHabitacion}
              id={props.habitacion.IDHab + "_" + props.habitacion.nFotos}
            >
              <i
                id={props.habitacion.IDHab + "_" + props.habitacion.nFotos}
                className="fas fa-plus"
              ></i>
            </button>
          ) : null}
        </div>
      </form>
      {muestraModal && (
        <CargaFoto
          IDEst={props.habitacion.IDHab}
          tit_Tipo={tit_Tipo}
          accion="cargaFotoHabitaciones"
          setMuestraModal={setMuestraModal}
          terminaCargaFoto={terminaCargaFoto}
        />
      )}
    </section>
  );
}

export default EdicionHabitacion;
