import IngresoItem from "./IngresoItem";
import classes from "./IngresosLista.module.css";

function IngresosLista(props) {
  //console.log(props);
  return (
    <ul className={classes.list}>
      {props.ingresosLoaded.length === 0 ? (
        <h1>No hay ingresos que mostrar</h1>
      ) : (
        props.ingresosLoaded.map((ingreso) => (
          <IngresoItem
            setRecargar={props.setRecargar}
            key={ingreso.IDIng}
            ingreso={ingreso}
            eliminaGasto={props.eliminaGasto}
            editaPago={props.editaPago}
            setDialogoOK={props.setDialogoOK}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
            nivel={props.nivel}
          />
        ))
      )}
    </ul>
  );
}

export default IngresosLista;
