import { compareAsc } from "date-fns";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  FechaParaInputDate,
  FormateaCurrency,
  EnviaDatosAServicio,
} from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./EstudianteReactiva.module.css";
import { Slide, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

function Reactivaestudiante(props) {
  const [loadedHabitacionesDisp, setLoadedHabitacionesDisp] = useState([]);
  const [loadedLlavesDisp, setLoadedLlavesDisp] = useState([]);
  const [loadedEstudiantes, setLoadedEstudiantes] = useState([]);
  const [estudianteSelected, setEstudianteSelected] = useState(false);
  const [cualEstudianteSelected, setCualEstudianteSelected] = useState([]);
  const [llaveAsignada, setLlaveAsignada] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useToken();
  const idCasa = token.casaSel;

  const fechaReingresoRef = useRef();
  const venceContratoRef = useRef();
  const [diaCorteInput, setDiaCorteInput] = useState();
  //const diaCorteInputRef = useRef();
  const habitacionInputRef = useRef();

  const ReactivaEstudianteFn = async (ev) => {
    ev.preventDefault();
    const datosValidos = validaDatos();
    if (datosValidos === "") {
      const EstEnvia = {
        Nombre: cualEstudianteSelected.Nombre,
        FechaIng: fechaReingresoRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        DiaCorte: diaCorteInput,
        IDHab: habitacionInputRef.current.value,
        VenceCont: venceContratoRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        IDEst: cualEstudianteSelected.IDEst,
        IDLlave: llaveAsignada.IDLlave,
      };
      //console.log(EstEnvia, cualEstudianteSelected);
      const envio = await EnviaDatosAServicio(EstEnvia, "ReactivaInquilino");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setMuestraReactivaEstudiante(false);
        props.setRecargar(true);
        /*
        props.setDialogoOK({
          texto: "Inquilino reactivado. Modifica lo necesario en su ficha",
          mostrandoError: false,
          recargar: true,
          cerrarVentana: "ReactivaEstudiante",
        });
        props.setMuestraDialogoOK(true);*/
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
      }
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + datosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  };

  function validaDatos() {
    var respuesta = "";
    console.log(habitacionInputRef.current.value);

    if (diaCorteInput === "" || diaCorteInput === undefined) {
      respuesta = respuesta + "Dia de corte\n";
    } else if (parseInt(diaCorteInput) < 1 || parseInt(diaCorteInput) > 31) {
      respuesta = respuesta + "El día de corte debe estar entre 1 y 31\n";
    }

    if (
      compareAsc(
        new Date(fechaReingresoRef.current.value),
        new Date(venceContratoRef.current.value)
      ) >= 0
    ) {
      respuesta =
        respuesta +
        "El vencimiento de contrato no puede ser igual ni anterior a la fecha de ingreso\n";
    }
    if (
      habitacionInputRef.current.value === "" ||
      habitacionInputRef.current.value === "0"
    ) {
      respuesta = respuesta + "Habitación\n";
    }
    return respuesta;
  }

  function OpcionSeleccionada(EstudianteSeleccionado) {
    const Inquilino = loadedEstudiantes.filter(
      (inquilino) => inquilino.IDEst === EstudianteSeleccionado.value
    );
    setCualEstudianteSelected(Inquilino[0]);
    setEstudianteSelected(true);
  }

  function LlaveSeleccionada(llaveSel) {
    const llave = loadedLlavesDisp.filter(
      (llave) => llave.IDLlave === llaveSel.value
    );
    setLlaveAsignada(llave[0]);
  }

  useEffect(() => {
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "DatosParaActualizaInquilinos",
        IDCasa: idCasa,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var LlavesDisp = [];
        var HabitacionesDisp = [];
        var Inquilinos = [];
        //console.log("data ", data);
        if (data.ERROR !== "SI") {
          for (const key in data) {
            //console.log("key ", key, data[key]);
            const datos = {
              ...data[key],
            };
            if (key === "LlavesDisp") {
              LlavesDisp = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "HabDisp") {
              HabitacionesDisp = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "RInquilinos") {
              Inquilinos = Object.keys(datos).map((key) => datos[key]);
            }
          }
          console.log("dataHD", data["HabDisp"]);
          //console.log("LlavesDisp", LlavesDisp);
          console.log("HabitacionesDisp", HabitacionesDisp);
          //console.log("Inquilinos", Inquilinos);
          setIsLoading(false);
          setLoadedLlavesDisp(LlavesDisp);
          setLoadedHabitacionesDisp(HabitacionesDisp);
          setLoadedEstudiantes(Inquilinos.map((inquilino) => inquilino.Datos));
        } else {
          setIsLoading(false);
          console.log("ERROR", data);
          //alert(data.DESCERROR);
        }
      });
  }, [idCasa, token]);

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {estudianteSelected && props.nivel === 1 && (
          <button
            className={classes.botTop}
            onClick={(ev) => ReactivaEstudianteFn(ev)}
          >
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={() => props.setMuestraReactivaEstudiante(false)}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>REACTIVA Estudiante</h1>
      <form>
        <div className={classes.contSelect}>
          <label className={classes.labelP}>Inquilino:</label>
          <Select
            className={classes.textareaNE1}
            onChange={OpcionSeleccionada}
            options={loadedEstudiantes.map((estudiante) => {
              return { value: estudiante.IDEst, label: estudiante.Nombre };
            })}
          />
        </div>
        {estudianteSelected && (
          <div>
            <label className={classes.tituloP + " " + classes.labelP}>
              Fecha Reingreso
            </label>
            <input
              ref={fechaReingresoRef}
              type="date"
              required
              className={classes.textareaNE}
              defaultValue={FechaParaInputDate()}
            ></input>
            <label className={classes.tituloP + " " + classes.labelP}>
              Día de corte
            </label>
            <NumericFormat
              required
              maxLength="2"
              id="diaCorte"
              type="text"
              className={classes.textareaNE}
              placeholder="1"
              onValueChange={(values) => {
                setDiaCorteInput(values.floatValue);
              }}
            ></NumericFormat>
            {/*<input
              maxLength="2"
              id="diaCorte"
              ref={diaCorteInputRef}
              type="text"
              className={classes.textareaNE}
              onPaste={(event) => FuncionValidaDatos(event, "numeros")}
              onKeyPress={(event) => FuncionValidaDatos(event, "numeros")}
              placeholder="Solo números"
            ></input>*/}
            <label className={classes.tituloP + " " + classes.labelP}>
              Vence Contrato
            </label>
            <input
              ref={venceContratoRef}
              type="date"
              required
              className={classes.textareaNE}
              defaultValue={FechaParaInputDate()}
            ></input>
            <label className={classes.tituloP + " " + classes.labelP}>
              Habitación
            </label>
            <select
              id="habitacion"
              ref={habitacionInputRef}
              className={classes.textareaNE}
            >
              <option key="0" value={"0"}>
                selecciona habitación
              </option>
              {loadedHabitacionesDisp.map((habitacion) => {
                return (
                  <option key={habitacion.IDHab} value={habitacion.IDHab}>
                    {habitacion.Habitacion} (
                    {FormateaCurrency.format(habitacion.Precio)})
                  </option>
                );
              })}
            </select>
            <label className={classes.tituloP + " " + classes.labelP}>
              Llave (opcional)
            </label>
            <div className={classes.contSelect}>
              <Select
                className={classes.textareaNE}
                options={loadedLlavesDisp.map((llave) => {
                  return { value: llave.IDLlave, label: llave.Llave };
                })}
                onChange={LlaveSeleccionada}
              />
            </div>
          </div>
        )}
      </form>
    </section>
  );
}

export default Reactivaestudiante;
