import { Slide, toast } from "react-toastify";
import {
  EnviaDatosAServicio,
  FechaMMMDeString,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import classes from "./EgresoItem.module.css";

function EgresoItem(props) {
  const elimina = async (ev) => {
    ev.preventDefault();
    if (window.confirm("Seguro que quieres eliminar este gasto?")) {
      const envio = await EnviaDatosAServicio(props.egreso, "borrarGasto");
      if (envio.ERROR === "") {
        toast("Egreso Eliminado", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al eliminar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
      }
    }
  };

  function QuitaAmpers(observ) {
    if (observ) {
      while (observ.indexOf("&&&") >= 0) {
        observ = observ.substr(observ.indexOf("&&&") + 3);
      }

      return observ;
    }
    return "";
  }

  /**************************************************************************************/
  /************************************ render ******************************************/
  /**************************************************************************************/

  return (
    <div
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.egreso.FormaPago === "Efectivo" ? "#ffa" : "#acc",
        width: props.tipo === "Recurrente" ? 100 : null,
      }}
    >
      <label className={classes.nombre}>{props.egreso.CeCo}</label>
      <label className={classes.nombre}>{props.egreso.Proveedor}</label>
      <label className={classes.otros}>
        Concepto: {props.egreso.Concepto.substr(0, 50)}
      </label>
      <label className={classes.otros}>
        Fecha pago: {FechaMMMDeString(props.egreso.FechaPag)}
      </label>
      <label className={classes.otros}>
        Forma pago: {props.egreso.FormaPago}
      </label>
      <label className={classes.otros}>
        Monto: {FormateaCurrency.format(props.egreso.Monto)}
      </label>
      {props.egreso.Observaciones !== "" && props.tipo !== "Recurrente" && (
        <hr className={classes.linea}></hr>
      )}
      {props.tipo !== "Recurrente" ? (
        <label className={classes.otros}>
          {QuitaAmpers(props.egreso.Observaciones.substr(0, 50))}
        </label>
      ) : null}
      {props.egreso.Observaciones !== "" && props.tipo !== "Recurrente" && (
        <hr className={classes.linea}></hr>
      )}
      <div className={classes.divBotones}>
        {props.tipo !== "Recurrente" ? (
          <button
            id={props.egreso.IDEgr}
            onClick={() => props.editaEgreso(props.egreso.IDEgr)}
          >
            {props.nivel === 1 ? (
              <i id={props.egreso.IDEgr} className="fas fa-edit"></i>
            ) : (
              <i id={props.egreso.IDEgr} className="fas fa-eye"></i>
            )}
          </button>
        ) : null}
        {props.nivel === 1 && props.tipo !== "Recurrente" ? (
          <button id={props.egreso.IDEgr} onClick={elimina}>
            <i id={props.egreso.IDEgr} className="fas fa-trash-alt"></i>
          </button>
        ) : null}
        {props.nivel === 1 && props.tipo === "Recurrente" ? (
          <button
            id={props.egreso.IDEgr}
            onClick={() => props.selecciona(props.egreso)}
          >
            <i id={props.egreso.IDEgr} className="fas fa-check"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default EgresoItem;
