import { Slide, toast } from "react-toastify";
import "../../FontAwesome/css-fa/all.css";
import classes from "./PendienteItem.module.css";
import { useLayoutEffect, useRef, useState } from "react";

function PendienteItem(props) {
  const nuevaPrioridad = useRef();
  const descripcionRef = useRef();
  const opciones = [];
  const [hayCambios, setHayCambios] = useState(false);

  for (var i = 1; i <= props.numpends; i++) {
    opciones.push(i);
  }

  function cambiaPrioridad() {
    props.cambiaPrioridad(props.pendiente.IDPend, nuevaPrioridad.current.value);
  }

  useLayoutEffect(() => {
    if (nuevaPrioridad.current) {
      if (nuevaPrioridad.current.value !== "")
        nuevaPrioridad.current.value = props.indice; // props.pendiente.Orden;
    }
  });
  //console.log(props.pendiente.Descripcion);
  let varcolor = "#0000ff";
  if (props.pendiente.Tipo === "1") {
    varcolor = "#ff0000";
  } else if (props.pendiente.Tipo === "3") varcolor = "#006400";
  return (
    <section
      className={classes.resPend}
      id={props.IDPend}
      draggable={props.nivel === 2}
    >
      <div className={classes.datosPend}>
        <div className={classes.botonesOrdena}>
          {props.nivel === 1 && (
            <button
              id={props.IDPend}
              className={classes.innbtn}
              onClick={() => props.agregaAntes(props.pendiente.IDPend)}
            >
              <i className="fas fa-plus-circle"></i>
            </button>
          )}
          {props.nivel === 1 && (
            <button
              id={props.IDPend}
              className={classes.innbtn}
              onClick={() => {
                if (props.indice > 1) {
                  props.subePrioridad(props.pendiente.IDPend);
                } else {
                  toast("No se puede subir mas", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    transition: Slide,
                  });
                }
              }}
            >
              <i className="fas fa-chevron-circle-up"></i>
            </button>
          )}
          {hayCambios ? (
            <button
              className={classes.innbtn}
              onClick={(ev) =>
                props.guardaDescripcion(
                  ev,
                  props.pendiente,
                  descripcionRef.current.value
                )
              }
            >
              <i className="fas fa-save"></i>
            </button>
          ) : (
            <button
              className={classes.innbtn}
              onClick={() => props.editaPendiente(props.pendiente.IDPend)}
            >
              <i className="fas fa-file-alt"></i>
            </button>
          )}
          {props.nivel === 1 && (
            <button
              id={props.IDPend}
              className={classes.innbtn}
              onClick={() => {
                if (props.indice < props.numpends) {
                  props.bajaPrioridad(props.pendiente.IDPend);
                } else {
                  toast("No se puede bajar mas", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    transition: Slide,
                  });
                }
              }}
            >
              <i className="fas fa-chevron-circle-down"></i>
            </button>
          )}
          {props.nivel === 1 && (
            <select
              id={props.IDPend}
              ref={nuevaPrioridad}
              className={classes.innbtn1}
              defaultValue={props.indice}
              onChange={() => cambiaPrioridad()}
            >
              {opciones.map((numero) => (
                <option key={numero} value={numero}>
                  {numero}
                </option>
              ))}
            </select>
          )}
        </div>
        <label
          className={classes.tituloP + " " + classes.labelP}
          style={{ backgroundColor: varcolor }}
        >
          {props.pendiente.DescTipo +
            " " +
            props.pendiente.Orden +
            "   (" +
            props.pendiente.Estatus +
            ")"}
        </label>
        <div>
          <textarea
            ref={descripcionRef}
            onChange={setHayCambios}
            className={classes.textareaDesc}
            defaultValue={props.pendiente.Descripcion}
          ></textarea>
        </div>
      </div>
    </section>
  );
}

export default PendienteItem;
