import { useState } from "react";
import AccesoTemporalItem from "../components/accesoTemporal/AccesoTemporalItem";
import OKModal from "../components/dialogos/dialogoOK";
import Backdrop from "../components/layout/backdrop";
import { useNavigate } from "react-router-dom";

function AccesoTemporalPage(props) {
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [textoModal, setTextoModal] = useState("Guardado OK");
  const [mostrandoError, setMostrandoError] = useState(false);
  const navigate = useNavigate();

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!mostrandoError) {
      navigate("/");
    }
  }
  console.log(props);
  return (
    <section className="centrarElem">
      <h1>GENERA ACCESO TEMPORAL</h1>
      <AccesoTemporalItem
        casaSel={props.casaSel}
        usuario={props.usuario}
        setTextoModal={setTextoModal}
        setMuestraDialogoOK={setMuestraDialogoOK}
        setMostrandoError={setMostrandoError}
      />
      {muestraDialogoOK && <OKModal texto={textoModal} oculta={palomeaOK} />}
      {muestraDialogoOK && <Backdrop zindex={49} />}
    </section>
  );
}

export default AccesoTemporalPage;
