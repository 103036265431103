import classes from "./CasaItem.module.css";

function CasaItem(props) {
  //console.log("props", props.seleccionada);
  return (
    <div
      className={classes.back}
      style={{
        backgroundColor: props.seleccionada ? "#888" : "#eee",
      }}
      onClick={() => props.casaSeleccionada(props.casa.IDCasa, props.casa.Casa)}
    >
      <label className={classes.nombre}>{props.casa.Casa}</label>
    </div>
  );
}

export default CasaItem;
