import LlaveItem from "./LlaveItem";
import classes from "./LlavesLista.module.css";

function LlavesLista(props) {
  //console.log("props", props)
  return (
    <ul className={classes.list}>
      {props.llavesLoaded.length === 0 ? (
        <h1>No hay llaves que mostrar</h1>
      ) : (
        props.llavesLoaded.map((llave) => (
          <LlaveItem
            nivel={props.nivel}
            setRecargar={props.setRecargar}
            key={llave.IDLlave}
            llave={llave}
            eliminaLlave={props.eliminaLlave}
            editaLlave={props.editaLlave}
            setTextoModal={props.setTextoModal}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
            setMostrandoError={props.setMostrandoError}
          />
        ))
      )}
    </ul>
  );
}

export default LlavesLista;
