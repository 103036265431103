import { useState } from "react";
import { EnviaDatosANuevoServicio } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./PendientesMateriales.module.css";

function MostrarMateriales(props) {
  const { token } = useToken();
  const IDUsu = token.Id;
  const [isLoading, setIsLoading] = useState(false);
  const filtroPendientes = props.filtroPendientes;

  function respServ(resp) {
    console.log(resp);
    if (resp.Error === "") {
      const nombrePDF = resp.rutaPDF;
      setTimeout(() => {
        window.open(
          "https://www.copilco.net/expedientes/documentos/" +
            nombrePDF +
            "?" +
            new Date()
        );
      });
    } else {
      props.setDialogoOK({
        texto: "Error generando impresion: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Error generando impresion: " + envio.DESCERROR);
    }
  }

  const imprimeMateriales = async () => {
    const Casa = { IDCasa: token.casaSel, Filtro: filtroPendientes };
    EnviaDatosANuevoServicio(
      "ImprimeMateriales",
      Casa,
      respServ,
      setIsLoading,
      null,
      IDUsu
    );
    /*
    const envio = await EnviaDatosAServicio(Casa, "imprimeMateriales");
    */
  };

  /***********************************************************************/
  /********************************* RENDER ******************************/
  /***********************************************************************/

  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <div className={classes.modal}>
      <h1>Materiales</h1>
      {props.pendientes.map((pendiente) => (
        <label className={classes.labelMateriales} key={pendiente.IDPend}>
          {pendiente.Materiales}
        </label>
      ))}
      <div className={classes.divBotones}>
        <button
          className={classes.botones}
          onClick={() => {
            props.setMostrarMateriales(false);
          }}
        >
          <i className="fas fa-check"></i>
        </button>
        <button
          className={classes.botones}
          onClick={() => {
            imprimeMateriales();
          }}
        >
          <i className="fas fa-print"></i>
        </button>
      </div>
    </div>
  );
}

export default MostrarMateriales;
