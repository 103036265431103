import { useRef, useState, useLayoutEffect } from "react";
import classes from "./HabitacionesOrdena.module.css";

function MenuOrdenaHabitaciones(props) {
  const targetRef = useRef();
  const habitacionAZRef = useRef();
  const habitacionZARef = useRef();
  const montoAZRef = useRef();
  const montoZARef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionOrdenaHabitaciones.x - targetRef.current.offsetWidth,
        y: props.posicionOrdenaHabitaciones.y,
      });
    }
  }, [props.posicionOrdenaHabitaciones]);

  function cambioFiltro(ev) {
    props.setMuestraOrdenaHab(false);
    habitacionAZRef.current.checked = ev.target.id === "habitacionAZ";
    habitacionZARef.current.checked = ev.target.id === "habitacionZA";
    montoAZRef.current.checked = ev.target.id === "montoAZ";
    montoZARef.current.checked = ev.target.id === "montoZA";

    props.setOrdenaHabitaciones({
      habitacionAZ: habitacionAZRef.current.checked,
      habitacionZA: habitacionZARef.current.checked,
      montoAZ: montoAZRef.current.checked,
      montoZA: montoZARef.current.checked,
    });
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posicionOrdenaHabitaciones.y : dimensions.y,
        left:
          dimensions.x === 0 ? props.posicionOrdenaHabitaciones.x : dimensions.x,
      }}
    >
      <label>
        <input
          type="radio"
          id="habitacionAZ"
          ref={habitacionAZRef}
          defaultChecked={props.ordenaHabitaciones.habitacionAZ}
          onChange={cambioFiltro}
        ></input>
        Habitación <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="habitacionZA"
          ref={habitacionZARef}
          defaultChecked={props.ordenaHabitaciones.habitacionZA}
          onChange={cambioFiltro}
        ></input>
        Habitación <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="montoAZ"
          ref={montoAZRef}
          defaultChecked={props.ordenaHabitaciones.montoAZ}
          onChange={cambioFiltro}
        ></input>
        Monto <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="montoZA"
          ref={montoZARef}
          defaultChecked={props.ordenaHabitaciones.montoZA}
          onChange={cambioFiltro}
        ></input>
        Monto <i className="fas fa-sort-amount-up"></i>
      </label>
    </div>
  );
}

export default MenuOrdenaHabitaciones;
