import React from 'react';
import { FechaMMMDeString, YYYYMMDDdeDate } from "../../Funciones/Funciones";
import classes from "./RecurrenteItem.module.css"

function ItemRecurrente(props) {
  const fechaHoy = YYYYMMDDdeDate(new Date());
  //console.log("RI", props.recurrente.RealizarEn > fechaHoy);
  return (
    <div
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.recurrente.RealizarEn > fechaHoy
            ? "#fff"
            : props.tipo !== "Catalogo"
              ? "#c36770"
              : "#ffd",
      }}
    >
      <label className={classes.nombre}>{props.recurrente.Nombre}</label>
      <label className={classes.nombre}>{props.recurrente.Frecuencia}</label>
      <label className={classes.otros}>
        {props.recurrente.RealizarEn === undefined
          ? "Fecha Inicia:"
          : "Fecha pago:"}
      </label>

      <label className={classes.otros}>
        {props.recurrente.RealizarEn === undefined
          ? FechaMMMDeString(props.recurrente.Inicia)
          : FechaMMMDeString(props.recurrente.RealizarEn)}
      </label>

      <div className={classes.divBotones}>
        {props.nivel === 1 && props.tipo !== "Catalogo" ? (
          <button
            id={props.recurrente.IDRec}
            onClick={(ev) => {
              ev.stopPropagation();
              props.AgregaPagoHandler(props.recurrente);
            }}
          >
            <i id={props.recurrente.IDRec} className="fas fa-dollar-sign"></i>
          </button>
        ) : null}
        {props.nivel === 1 && props.tipo !== "Catalogo" ? (
          <button
            id={props.recurrente.IDRec}
            onClick={(ev) => {
              ev.stopPropagation();
              props.SeleccionaPagoHandler(props.recurrente);
            }}
          >
            <i id={props.recurrente.IDRec} className="fas fa-search"></i>
          </button>
        ) : null}
        {props.nivel === 1 && props.tipo === "Catalogo" ? (
          <button
            id={props.recurrente.IDRec}
            onClick={(ev) => {
              ev.stopPropagation();
              props.EditaRecurrenteHandler(props.recurrente);
            }}
          >
            <i id={props.recurrente.IDRec} className="fas fa-file-alt"></i>
          </button>
        ) : null}
        {props.nivel === 1 && props.tipo === "Catalogo" ? (
          <button
            id={props.recurrente.IDRec}
            onClick={(ev) => {
              ev.stopPropagation();
              props.EliminaRecurrenteHandler(props.recurrente);
            }}
          >
            <i id={props.recurrente.IDRec} className="fas fa-times"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default ItemRecurrente;
