//Estamos enviando el pago seleccionado para asignarlo al recurrente. Ahorita regresa "No implementado del PHP"

import { useEffect, useRef, useState } from "react";
import classes from "./TodosLosRecurrentes.module.css";
import Backdrop from "../components/layout/backdrop";
import {
  EnviaDatosANuevoServicio,
  FechaParaInputDate,
  YYYYMMDDdeDate,
} from "../Funciones/Funciones";
import { addDays } from "date-fns";
import ListaRecurrentes from "../components/recurrentes/RecurrentesLista";
import OKModal from "../components/dialogos/dialogoOK";
import useToken from "../components/login/useToken";
import NuevoEgreso from "../components/egresos/EgresoNuevo";
import SeleccionaPago from "../components/egresos/EgresosSelecciona";

function TodosLosRecurrentesPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [recurrentesLoaded, setRecurrentesLoaded] = useState([]);
  const [posFiltroRecurrentes, setPosFiltroRecurrentes] = useState([]);
  const [muestraFiltroRec, setMuestraFiltroRec] = useState(false);
  const [muestraOrdenaRec, setMuestraOrdenaRec] = useState(false);
  const [mostrarPago, setMostrarPago] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [recurrente, setRecurrente] = useState([]);
  const [mostrarSeleccionaPago, setMostrarSeleccionaPago] = useState(false);
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [ordenaRecurrentes, setOrdenaRecurrentes] = useState({
    fechaAZ: true,
    fechaZA: false,
  });
  const [filtroRecurrentes, setFiltroRecurrentes] = useState({
    FechaFin: FechaFin(new Date(), "carga"),
  });
  const fechaFinRef = useRef();
  const { token } = useToken();
  const idUsu = token.Id;

  function SeleccionaPagoHandler(recurenteT) {
    setRecurrente(recurenteT);
    setMostrarSeleccionaPago(true);
  }

  function AgregaPagoHandler(recurenteT) {
    setRecurrente(recurenteT);
    setMostrarPago(true);
  }

  function setRespServ(respuesta) {
    if (respuesta.Error !== "SI") {
      const i = respuesta.Tareas.length;
      for (var j = 0; j < i; j++) {
        respuesta.Tareas[j].IDTemp = j;
      }
      setRecurrentesLoaded(respuesta.Tareas);
    } else {
      console.log("ERROR en TAREAS: " + respuesta.DESCERROR);
    }
  }

  useEffect(() => {
    setMostrarPago(false);
    setMuestraDialogoOK(false);
    setMostrarSeleccionaPago(false);
    if (props.casaSel > 0) {
      setRecargar(false);
      setIsLoading(true);
      const datos = {
        IDCasa: props.casaSel,
        FechaFin: filtroRecurrentes.FechaFin,
      };
      EnviaDatosANuevoServicio(
        "ListadoRecurrentes",
        datos,
        setRespServ,
        setIsLoading,
        null,
        idUsu
      );
    } else {
      setIsLoading(false);
    }
  }, [props.casaSel, filtroRecurrentes.FechaFin, recargar, idUsu]);

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "pagoNuevo") {
        setMostrarPago(false);
        //setMuestraEdicionRecurrente(false);
      }
      if (dialogoOK.cerrarVentana === "mostrarEdicion") {
        //setalgo
      }
      if (dialogoOK.cerrarVentana === "mostrarElimina") {
        //setMostrarEdicion(false);
      }
      if (dialogoOK.cerrarVentana === "NuevoEstudiante") {
        //setAlgo
      }
      if (dialogoOK.recargar) {
        //setMostrarElimina(false);
        //setMostrarNuevo(false);
        //if (dialogoOK.cerrarVentana === "NuevoEgreso")
        //  setMuestraNuevoEgreso(false);
        setRecargar(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  function queRecurrentesMostrar(recurrente) {
    return true;
  }

  function reOrdenaRecurrentes(a, b) {
    if (ordenaRecurrentes.fechaAZ) return a.RealizarEn > b.RealizarEn ? 1 : -1;
    if (ordenaRecurrentes.fechaZA) return a.RealizarEn < b.RealizarEn ? 1 : -1;
  }

  function FiltraRecurrentes(ev) {
    setPosFiltroRecurrentes({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraFiltroRec(true);
  }

  function FechaFin(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addDays(fecha, +7);
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    return temp;
  }

  function cambioFecha() {
    const nvoFiltro = {
      ...filtroRecurrentes,
      ...{
        FechaFin: YYYYMMDDdeDate(
          new Date(fechaFinRef.current.value + " 0:0:0")
        ),
      },
    };
    //console.log("nvofiltro", nvoFiltro);
    setFiltroRecurrentes(nvoFiltro);
  }

  /******************************************************************************************** */
  /***************************************** RENDER ******************************************* */
  /******************************************************************************************** */

  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.centrarElem}>
      <div className={classes.TituloBoton}>
        <div className="TitEst">
          <h1>Pagos Programados</h1>
          <label className={classes.rangoFechas}>
            al{" "}
            <input
              required
              type="date"
              ref={fechaFinRef}
              defaultValue={FechaParaInputDate(filtroRecurrentes.FechaFin)}
              onChange={cambioFecha}
            ></input>
          </label>
        </div>
        {props.muestra !== "Inicio" ? (
          <div className="contBotones">
            {recurrentesLoaded.length > 0 ? (
              <button className="botAgregaEst" onClick={ordenaRecurrentes}>
                <i className="fas fa-sort-amount-up-alt"></i>
              </button>
            ) : null}
            <button className="botAgregaEst" onClick={FiltraRecurrentes}>
              <i className="fas fa-filter"></i>
            </button>
            {props.nivel === 1 ? (
              <button className="botAgregaEst" onClick={setMostrarPago(true)}>
                <i className="fas fa-plus-square"></i>
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
      <ListaRecurrentes
        nivel={props.nivel}
        setRecargar={setRecargar}
        setDialogoOK={setDialogoOK}
        setMuestraDialogoOK={setMuestraDialogoOK}
        muestra={props.muestra}
        SeleccionaPagoHandler={SeleccionaPagoHandler}
        recurrentesLoaded={recurrentesLoaded
          .sort((a, b) => reOrdenaRecurrentes(a, b))
          .filter((ingreso) => queRecurrentesMostrar(ingreso))}
        AgregaPagoHandler={AgregaPagoHandler}
      />
      {/*muestraFiltroRec && (
        <MenuFiltraRecurrentes
          posicionFiltroRecurrentes={posFiltroRecurrentes}
          setFiltroRecurrentes={setFiltroRecurrentes}
          filtroRecurrentes={filtroRecurrentes}
          setMuestraFiltroIng={setMuestraFiltroIng}
        />
      )*/}
      {/*muestraOrdenaRec && (
        <MenuOrdenaRecurrentes
          posicionOrdenaRecurrentes={posFiltroRecurrentes}
          setOrdenaRecurrentes={setOrdenaRecurrentes}
          ordenaRecurrentes={ordenaRecurrentes}
          setMuestraOrdenaIng={setMuestraOrdenaIng}
        />
      )*/}
      {/*muestraEdicionPago && (
        <NuevoIngreso
          PagoCompletado={PagoCompletado}
          setMostrarNuevo={setMuestraEdicionPago}
          edicionPago={datosEdicionPago}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )*/}
      {mostrarPago && (
        <NuevoEgreso
          setMostrarNuevo={setMostrarPago}
          //setMuestraNuevoCeCo={setMuestraNuevoCeCo}
          //setMuestraNuevoProv={setMuestraNuevoProv}
          tipo={"Recurrente"}
          recurrente={recurrente}
          setRecargar={setRecargar}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {mostrarSeleccionaPago && (
        <SeleccionaPago
          setMostrarNuevo={setMostrarPago}
          //setMuestraNuevoCeCo={setMuestraNuevoCeCo}
          //setMuestraNuevoProv={setMuestraNuevoProv}
          tipo={"Recurrente"}
          recurrente={recurrente}
          setRecargar={setRecargar}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          setMostrarSeleccionaPago={setMostrarSeleccionaPago}
          nivel={props.nivel}
        />
      )}
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
      {/*muestraEdicionPago && <Backdrop />*/}
      {/*muestraFiltroIng && <Backdrop recarga={setMuestraFiltroIng} />*/}
      {/*muestraOrdenaIng && <Backdrop recarga={setMuestraOrdenaIng} />*/}
      {mostrarPago && <Backdrop />}
      {mostrarSeleccionaPago && <Backdrop zindex={9} />}
    </section>
  );
}

export default TodosLosRecurrentesPage;
