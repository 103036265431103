import ItemRecurrente from "./RecurrenteItem.tsx";
import classes from "./RecurrentesLista.module.css";

function ListaRecurrentes(props) {
  //console.log("listR", props);
  return (
    <ul className={classes.list}>
      {props.recurrentesLoaded.length === 0 ? (
        props.tipo !== "Catalogo" ? (
          <h1>No hay pagos programados a esa fecha</h1>
        ) : (
          <h1>No hay pagos programados</h1>
        )
      ) : (
        props.recurrentesLoaded.map((recurrente) => (
          <ItemRecurrente
            setRecargar={props.setRecargar}
            key={
              props.tipo !== "Catalogo" ? recurrente.IDTemp : recurrente.IDRec
            }
            recurrente={recurrente}
            eliminaRecurrente={props.eliminaRecurrente}
            AgregaPagoHandler={props.AgregaPagoHandler}
            SeleccionaPagoHandler={props.SeleccionaPagoHandler}
            setDialogoOK={props.setDialogoOK}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
            muestra={props.muestra}
            nivel={props.nivel}
            tipo={props.tipo}
          />
        ))
      )}
    </ul>
  );
}

export default ListaRecurrentes;
