import { useState } from "react";

export default function useOpened() {
  
  const [opened, setOpened] = useState(false);

  const saveOpened = () => {
    setOpened(!opened);
  };

  return {
    setOpened: saveOpened,
    opened,
  };
}
