import MainNavigation from "./MainNavigation";
import "./Layout.css";

function Layout(props) {
  //principal contenedor
  //console.log(props);
  return (
    <div>
      <MainNavigation
        nombreCasa={props.nombreCasa}
        casaSel={props.casaSel}
        setCasaSeleccionada={props.setCasaSeleccionada}
        setNivel={props.setNivel}
        esInvitado={props.esInvitado}
        nivel={props.nivel}
        vers={props.vers}
      />
      <main className="main">{props.children}</main>
    </div>
  );
}

export default Layout;
