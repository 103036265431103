import { useState, useEffect } from "react";
import { EnviaDatosANuevoServicio } from "../Funciones/Funciones";
import ListaPendientes from "../components/pendientes/PendientesLista";
import useToken from "../components/login/useToken";
import NuevoPendiente from "../components/pendientes/PendienteNuevo";
import Backdrop from "../components/layout/backdrop";
import classes from "./TodosLosPendientes.module.css";
import MostrarMateriales from "../components/pendientes/PendientesMateriales";
import FiltroPendientes from "../components/pendientes/PendientesFiltro";
import PendienteEdicion from "../components/pendientes/PendientesEdicion";
import OKModal from "../components/dialogos/dialogoOK";
import { Slide, toast } from "react-toastify";

function TodosLosPendientesPage(props) {
  const { token } = useToken();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedPendientes, setLoadedPendientes] = useState([]);
  const [mostrarNuevo, setMostrarNuevo] = useState(false);
  const [pendNuevo, setPendNuevo] = useState([]);
  const [mostrarMateriales, setMostrarMateriales] = useState(false);
  const [mostrarFiltro, setMostrarFiltro] = useState(false);
  const [posFiltroPendientes, setPosFiltroPendientes] = useState([]);
  const [filtroPendientes, setFiltroPendientes] = useState({
    Administracion: props.nivel === 1 ? true : false,
    Prioridades: true,
    Pendientes: true,
  });
  const [datosEdicionPendiente, setDatosEdicionPendiente] = useState([]);
  const [muestraEdicion, setMuestraEdicion] = useState(false);
  const [recarga, setRecarga] = useState(false);
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const IDUsu = token.Id;

  function respServGD(resp) {
    console.log(resp);
    if (resp.Error === "") {
      toast("Descripción actualizada", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      setRecarga(true);
    } else {
      setDialogoOK({
        texto: "Error: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
  }

  function guardaDescripcion(ev, pendiente, nvaDesc) {
    ev.stopPropagation();
    //console.log(ev, IDPend, nvaDesc);
    const datos = {
      pendiente: pendiente,
      nvaDesc: nvaDesc,
    };
    EnviaDatosANuevoServicio(
      "CambiaDescripcion",
      datos,
      respServGD,
      setIsLoading,
      null,
      IDUsu
    );
  }

  function palomeaOK() {
    //console.log(dialogoOK);
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "mostrarNuevo") {
        setMostrarNuevo(false);
      }
      if (dialogoOK.cerrarVentana === "PendienteCompleto") {
        PendienteCompleto();
      }
      if (dialogoOK.recargar) {
        setRecarga(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  function respServCD(resp) {
    if (resp.Error === "") {
      toast("Orden cambiado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
    } else {
      setDialogoOK({
        texto: "Error: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
    setRecarga(true);
  }

  function cambiaPrioridad(IDPend, nuevoOrden) {
    const datos = {
      pendiente: IDPend,
      nvoOrden: nuevoOrden,
      filtro: filtroPendientes,
      IDCasa: props.casaSel,
    };
    EnviaDatosANuevoServicio(
      "CambiaOrden",
      datos,
      respServCD,
      setIsLoading,
      null,
      IDUsu
    );
    setRecarga(false);
  }

  function respServ(resp) {
    //console.log(resp);
    if (resp.Error === "") {
      if (resp.rPendientes.length > 0) {
        const pendt = resp.rPendientes.map((pend) => {
          const TipoLetra =
            pend.Tipo === "1"
              ? "PRIORIDAD"
              : pend.Tipo === "2"
              ? "PENDIENTE"
              : "ADMINISTRACION";

          return { ...pend, DescTipo: TipoLetra };
        });
        setLoadedPendientes(pendt);
      } else {
        setLoadedPendientes("");
      }
    } else {
      setDialogoOK({
        texto: "Error: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
  }

  useEffect(() => {
    if (setRecarga) {
      setMuestraDialogoOK(false);
      setMuestraEdicion(false);
      setMostrarFiltro(false);
      setMostrarMateriales(false);
      setMostrarNuevo(false);
      setIsLoading(true);
      setRecarga(false);

      const datos = {
        IDCasa: props.casaSel,
      };
      EnviaDatosANuevoServicio(
        "ResumenPendientes",
        datos,
        respServ,
        setIsLoading,
        null,
        IDUsu
      );
    }
  }, [props.casaSel, recarga, IDUsu]);

  function agregaAntes(IDPend) {
    //buscamos el pendiente con el id del boton
    const pendienteSiguiente = loadedPendientes.find(
      (pendiente) => pendiente.IDPend === IDPend
    );
    setPendNuevo(pendienteSiguiente);
    setMostrarNuevo(true);
  }

  function subePrioridad(IDPend) {
    cambiaPrioridad(IDPend, "SUBIR");
    /*
    //buscamos el pendiente a subir
    const pendienteASubir = loadedPendientes.find(
      (pendiente) => pendiente.IDPend === IDPend
    );
    console.log(pendienteASubir);
    /*
    if (pendienteASubir.Orden > 1) {
      cambiaPrioridad(pendienteASubir.IDPend, pendienteASubir.Orden - 1);
    }*/
  }

  function bajaPrioridad(IDPend) {
    cambiaPrioridad(IDPend, "BAJAR");
    /*const pendienteABajar = loadedPendientes.find(
      (pendiente) => pendiente.IDPend === IDPend
    );
    if (pendienteABajar.Orden < loadedPendientes.length) {
      const pendienteASubir = loadedPendientes.find(
        (pendiente) =>
          pendiente.Orden ===
          (parseInt(pendienteABajar.Orden) + parseInt(1)).toString()
      );
      if (pendienteASubir) {
        cambiaOrden(pendienteABajar, pendienteASubir, "BAJA");
      }
    }*/
  }

  function editaPendiente(IDPend) {
    const pendEd = loadedPendientes.filter((pend) => pend.IDPend === IDPend)[0];
    setDatosEdicionPendiente(pendEd);
    setMuestraEdicion(true);
  }

  function FiltraPendientes(ev) {
    //console.log("ev", ev);
    setPosFiltroPendientes({
      x: ev.pageX,
      y: ev.pageY + ev.target.offsetHeight,
    });
    setMostrarFiltro(true);
  }

  function verMateriales() {
    const Materiales = loadedPendientes.filter((pendiente) => {
      if (pendiente.Materiales !== null)
        if (pendiente.Materiales !== "") return pendiente.Materiales;
      return pendiente.Materiales;
    });
    if (Materiales.length === 0) {
      setDialogoOK({
        texto: "No hay materiales en la lista",
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
      //alert("No hay materiales en la lista");
    } else setMostrarMateriales(true);
  }

  function QuePendientesMostrar(pendiente) {
    if (filtroPendientes.Administracion && pendiente.Tipo === "3") return true;
    if (filtroPendientes.Pendientes && pendiente.Tipo === "2") return true;
    if (filtroPendientes.Prioridades && pendiente.Tipo === "1") return true;
    return false;
  }

  function PendienteCompleto() {
    setMuestraEdicion(false);
    setRecarga(true);
  }

  function respServIL(resp) {
    console.log(resp);
    if (resp.Error === "") {
      const nombrePDF = resp.rutaPDF;
      setTimeout(() => {
        window.open(
          "https://www.copilco.net/expedientes/documentos/" +
            nombrePDF +
            "?" +
            new Date()
        );
      });
    } else {
      setDialogoOK({
        texto: "Error generando impresion: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
  }

  const imprimirLista = async () => {
    const Casa = { IDCasa: props.casaSel, Filtro: filtroPendientes };
    EnviaDatosANuevoServicio(
      "imprimePendientes",
      Casa,
      respServIL,
      setIsLoading,
      null,
      IDUsu
    );
  };

  /***********************************************************************/
  /********************************* RENDER ******************************/
  /***********************************************************************/

  //console.log("todoslospendientes", props);
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  if (props.casaSel > 0) {
    if (loadedPendientes.length > 0) {
      //console.log("render ", loadedPendientes);
      return (
        <section className={classes.centrarElem}>
          {muestraEdicion && (
            <PendienteEdicion
              pendiente={datosEdicionPendiente}
              setMuestraEdicion={setMuestraEdicion}
              PendienteCompleto={PendienteCompleto}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              nivel={props.nivel}
            />
          )}
          {muestraEdicion && <Backdrop />}
          {mostrarMateriales && (
            <MostrarMateriales
              pendientes={loadedPendientes.filter((pendiente) =>
                QuePendientesMostrar(pendiente)
              )}
              setMostrarMateriales={setMostrarMateriales}
              filtroPendientes={filtroPendientes}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
            />
          )}
          {mostrarMateriales && <Backdrop recarga={setMostrarMateriales} />}
          {mostrarFiltro && (
            <FiltroPendientes
              posicionFiltroPendientes={posFiltroPendientes}
              setFiltroPendientes={setFiltroPendientes}
              filtroPendientes={filtroPendientes}
              nivel={props.nivel}
            />
          )}
          {mostrarFiltro && <Backdrop recarga={setMostrarFiltro} />}
          <div className={classes.TituloBoton}>
            <h1>Pendientes</h1>
            <div className={classes.contBotones}>
              {props.nivel === 1 ? (
                <button
                  className={classes.botVerMateriales}
                  onClick={setMostrarNuevo}
                >
                  <i className="fas fa-plus"></i>
                </button>
              ) : null}
              {loadedPendientes.length > 0 ? (
                <button
                  className={classes.botVerMateriales}
                  onClick={FiltraPendientes}
                >
                  <i className="fas fa-filter"></i>
                </button>
              ) : null}
              {loadedPendientes.filter((pendiente) =>
                QuePendientesMostrar(pendiente)
              ).length > 0 ? (
                <button
                  className={classes.botVerMateriales}
                  onClick={imprimirLista}
                >
                  <i className="fas fa-print"></i>
                </button>
              ) : null}
              {loadedPendientes.filter((pendiente) =>
                QuePendientesMostrar(pendiente)
              ).length > 0 ? (
                <button
                  className={classes.botVerMateriales}
                  onClick={verMateriales}
                >
                  <i className="fas fa-file-alt"></i>
                </button>
              ) : null}
            </div>
          </div>
          {loadedPendientes.length > 0 &&
            loadedPendientes.filter((pendiente) =>
              QuePendientesMostrar(pendiente)
            ).length === 0 && <h1>Sin Pendientes por filtro</h1>}
          <ListaPendientes
            pendientes={loadedPendientes.filter((pendiente) =>
              QuePendientesMostrar(pendiente)
            )}
            nivel={props.nivel}
            editaPendiente={editaPendiente}
            agregaAntes={agregaAntes}
            subePrioridad={subePrioridad}
            bajaPrioridad={bajaPrioridad}
            setDialogoOK={setDialogoOK}
            setMuestraDialogoOK={setMuestraDialogoOK}
            cambiaPrioridad={cambiaPrioridad}
            guardaDescripcion={guardaDescripcion}
          />
          {mostrarNuevo && (
            <NuevoPendiente
              pendientes={loadedPendientes}
              pendiente={pendNuevo}
              nivel={props.nivel}
              setLoadedPendientes={setLoadedPendientes}
              setMostrarNuevo={setMostrarNuevo}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              setRecarga={setRecarga}
            />
          )}
          {muestraDialogoOK && (
            <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
          )}
          {muestraDialogoOK && <Backdrop zindex={49} />}
          {mostrarNuevo && <Backdrop />}
        </section>
      );
    } else {
      return (
        <section>
          <div className={classes.TituloBoton}>
            <h1>No hay pendientes</h1>
            <div className={classes.contBotones}>
              {props.nivel === 1 ? (
                <button
                  className={classes.botVerMateriales}
                  onClick={setMostrarNuevo}
                >
                  <i className="fas fa-plus"></i>
                </button>
              ) : null}
            </div>
          </div>
          {mostrarNuevo && (
            <NuevoPendiente
              pendientes={loadedPendientes}
              pendiente={pendNuevo}
              setLoadedPendientes={setLoadedPendientes}
              setMostrarNuevo={setMostrarNuevo}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              nivel={props.nivel}
              setRecarga={setRecarga}
            />
          )}
          {mostrarNuevo && <Backdrop />}
          {muestraDialogoOK && (
            <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
          )}
          {muestraDialogoOK && <Backdrop zindex={49} />}
        </section>
      );
    }
  } else {
    return (
      <section>
        <h1>Primero selecciona la casa</h1>
      </section>
    );
  }
}

export default TodosLosPendientesPage;
