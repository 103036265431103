import { useState, useEffect } from "react";
import OKModal from "../components/dialogos/dialogoOK";
import EdicionHabitacion from "../components/habitaciones/HabitacionEdicion";
import MenuFiltraHabitaciones from "../components/habitaciones/HabitacionesFiltra";
import HabitacionesLista from "../components/habitaciones/HabitacionesLista";
import MenuOrdenaHabitaciones from "../components/habitaciones/HabitacionesOrdena";
import NuevaHabitacion from "../components/habitaciones/HabitacionNueva";
import Backdrop from "../components/layout/backdrop";
import classes from "./TodasLasHabitaciones.module.css";
import { EnviaDatosANuevoServicio } from "../Funciones/Funciones";
import useToken from "../components/login/useToken";

function TodasLasHabitacionesPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [habitacionesLoaded, setHabitacionesLoaded] = useState([]);
  const [posFiltroHabitaciones, setPosFiltroHabitaciones] = useState([]);
  const [muestraOrdenaHab, setMuestraOrdenaHab] = useState(false);
  const [ordenaHabitaciones, setOrdenaHabitaciones] = useState({
    habitacionAZ: false,
    habitacionZA: false,
    montoAZ: false,
    montoZA: false,
  });
  const [muestraFiltroHab, setMuestraFiltroHab] = useState(false);
  const [filtroHabitaciones, setFiltroHabitaciones] = useState({
    Ocupada: true,
    Remodelacion: true,
    Desocupada: true,
    Nombre: "",
    Habitacion: "",
    MaxInq: "",
  });
  const [muestraNuevaHabitacion, setMuestraNuevaHabitacion] = useState(false);
  const [datosEdicionHabitacion, setDatosEdicionHabitacion] = useState([]);
  const [fotosExtraHabitacion, setFotosExtraHabitacion] = useState([]);
  const [muestraEdicionHabitacion, setMuestraEdicionHabitacion] =
    useState(false);
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [recargaFoto, setRecargaFoto] = useState(false);
  const token = useToken().token;
  const idUsu = token.Id;

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "habitacionCompleta")
        habitacionCompleta();
      if (dialogoOK.cerrarVentana === "mostrarEdicion") {
        //setalgo
      }
      if (dialogoOK.cerrarVentana === "mostrarElimina") {
        //setMostrarEdicion(false);
      }
      if (dialogoOK.cerrarVentana === "NuevoEstudiante") {
        //setAlgo
      }
      if (dialogoOK.recargar) {
        //setMostrarElimina(false);
        //setMostrarNuevo(false);
        //if (dialogoOK.cerrarVentana === "NuevoEgreso")
        //  setMuestraNuevoEgreso(false);
        setRecargar(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  function editaHabitacion(ev) {
    ev.preventDefault();
    const hab = habitacionesLoaded.filter(
      (habi) => habi.IDHab === ev.target.id
    )[0];
    var fotosExtras = [];
    for (var i = 0; i < hab.nFotos; i += 1) {
      fotosExtras.push("Foto" + (i + 1));
    }
    setFotosExtraHabitacion(fotosExtras);
    setDatosEdicionHabitacion(hab);
    setMuestraEdicionHabitacion(true);
  }

  function habitacionCompleta() {
    setMuestraNuevaHabitacion(false);
    setMuestraEdicionHabitacion(false);
    setRecargar(true);
  }

  function queHabitacionesMostrar(habitacion) {
    if (
      habitacion.Habitacion.toUpperCase().indexOf(
        filtroHabitaciones.Habitacion.toUpperCase()
      ) < 0
    )
      return false;
    if (
      habitacion.MaxInq.toUpperCase().indexOf(
        filtroHabitaciones.MaxInq.toUpperCase()
      ) < 0
    )
      return false;

    if (
      habitacion.Estatus === "OK" &&
      habitacion.Nombre !== null &&
      !filtroHabitaciones.Ocupada
    )
      return false;
    if (
      habitacion.Estatus === "OK" &&
      habitacion.Nombre === null &&
      !filtroHabitaciones.Desocupada
    )
      return false;
    if (habitacion.Estatus === "REMOD" && !filtroHabitaciones.Remodelacion)
      return false;
    if (habitacion.Nombre === null) return filtroHabitaciones.Nombre === "";
    if (
      habitacion.Nombre.toUpperCase().indexOf(
        filtroHabitaciones.Nombre.toUpperCase()
      ) < 0
    )
      return false;
    return true;
  }

  function FiltraHabitaciones(ev) {
    setPosFiltroHabitaciones({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraFiltroHab(true);
  }

  function reordenaHabitaciones(a, b) {
    if (ordenaHabitaciones.habitacionAZ)
      return a.Habitacion > b.Habitacion ? 1 : -1;
    if (ordenaHabitaciones.habitacionZA)
      return a.Habitacion < b.Habitacion ? 1 : -1;
    if (ordenaHabitaciones.montoAZ)
      return parseFloat(a.Precio) > parseFloat(b.Precio) ? 1 : -1;
    if (ordenaHabitaciones.montoZA)
      return parseFloat(a.Precio) < parseFloat(b.Precio) ? 1 : -1;
  }

  function OrdenaHabitaciones(ev) {
    setPosFiltroHabitaciones({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraOrdenaHab(true);
  }

  function respServ(resp) {
    //console.log(resp);
    if (resp.Error === "") {
      const habitaciones = resp.RHabitaciones;
      setHabitacionesLoaded(habitaciones);
      //console.log("habitaciones", habitaciones);
      setIsLoading(false);
    } else {
      setDialogoOK({
        texto: "Error: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      setMuestraDialogoOK(true);
    }
  }

  useEffect(() => {
    setMuestraDialogoOK(false);
    setMuestraEdicionHabitacion(false);
    setMuestraFiltroHab(false);
    setMuestraNuevaHabitacion(false);
    setMuestraOrdenaHab(false);
    if (props.casaSel > 0) {
      setRecargar(false);
      setIsLoading(true);
      const datos = {
        IDCasa: props.casaSel,
      };
      EnviaDatosANuevoServicio(
        "resumenHabitaciones",
        datos,
        respServ,
        setIsLoading,
        null,
        idUsu
      );
      /*
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "resumenHabitaciones",
          IDCasa: props.casaSel,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var habitaciones = [];
          var numFotos = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "NumFotos") {
                numFotos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "RHabitaciones") {
                habitaciones = Object.keys(datos).map((key) => datos[key]);
              }
            }
            for (var i = 0; i < habitaciones.length; i += 1) {
              habitaciones[i] = { ...habitaciones[i], nFotos: numFotos[i] };
            }
            //console.log("numFotos", numFotos);
            //console.log("habitaciones", habitaciones);
            //console.log("estudiantes", estudiantes);
            setHabitacionesLoaded(habitaciones);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.ERROR);
          }
        });*/
    } else {
      setIsLoading(false);
    }
  }, [props.casaSel, recargar, idUsu]);

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.centrarElem}>
      <div className={classes.TituloBoton}>
        <div className="TitEst">
          <h1>Habitaciones</h1>
        </div>
        <div className="contBotones">
          {1 > 0 ? (
            <button className="botAgregaEst" onClick={OrdenaHabitaciones}>
              <i className="fas fa-sort-amount-up-alt"></i>
            </button>
          ) : null}
          <button className="botAgregaEst" onClick={FiltraHabitaciones}>
            <i className="fas fa-filter"></i>
          </button>
          {props.nivel === 1 ? (
            <button
              className="botAgregaEst"
              onClick={setMuestraNuevaHabitacion}
            >
              <i className="fas fa-plus-square"></i>
            </button>
          ) : null}
        </div>
      </div>
      <HabitacionesLista
        nivel={props.nivel}
        editaHabitacion={editaHabitacion}
        setRecargar={setRecargar}
        recargaFoto={recargaFoto}
        setRecargaFoto={setRecargaFoto}
        setDialogoOK={setDialogoOK}
        setMuestraDialogoOK={setMuestraDialogoOK}
        habitacionesLoaded={habitacionesLoaded
          .sort((a, b) => reordenaHabitaciones(a, b))
          .filter((habitacion) => queHabitacionesMostrar(habitacion))}
      />
      {muestraOrdenaHab && (
        <MenuOrdenaHabitaciones
          posicionOrdenaHabitaciones={posFiltroHabitaciones}
          setOrdenaHabitaciones={setOrdenaHabitaciones}
          ordenaHabitaciones={ordenaHabitaciones}
          setMuestraOrdenaHab={setMuestraOrdenaHab}
        />
      )}
      {muestraFiltroHab && (
        <MenuFiltraHabitaciones
          posicionFiltroHabitaciones={posFiltroHabitaciones}
          setFiltroHabitaciones={setFiltroHabitaciones}
          filtroHabitaciones={filtroHabitaciones}
          setMuestraFiltroHab={setMuestraFiltroHab}
        />
      )}
      {muestraNuevaHabitacion && (
        <NuevaHabitacion
          setMostrarNuevo={setMuestraNuevaHabitacion}
          habitacionCompleta={habitacionCompleta}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {muestraEdicionHabitacion && (
        <EdicionHabitacion
          habitacionCompleta={habitacionCompleta}
          setMostrarHabitacion={setMuestraEdicionHabitacion}
          habitacion={datosEdicionHabitacion}
          fotosExtras={fotosExtraHabitacion}
          setRecargaFoto={setRecargaFoto}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
      {muestraEdicionHabitacion && <Backdrop />}
      {muestraFiltroHab && <Backdrop recarga={setMuestraFiltroHab} />}
      {muestraOrdenaHab && <Backdrop recarga={setMuestraOrdenaHab} />}
      {muestraNuevaHabitacion && (
        <Backdrop recarga={setMuestraNuevaHabitacion} />
      )}
    </section>
  );
}

export default TodasLasHabitacionesPage;
