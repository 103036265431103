import { Slide, toast } from "react-toastify";
import {
  EnviaDatosANuevoServicio,
  Formatea1Decimal,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import classes from "./HabitacionItem.module.css";
import { useEffect, useRef, useState } from "react";
import useToken from "../login/useToken";

function HabitacionItem(props) {
  const fotoRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken().token;
  const idUsu = token.Id;

  function respServ(resp) {
    console.log(resp);
    if (resp.Error === "") {
      toast("Habitación eliminada", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.setRecargar(true);
    } else {
      props.setDialogoOK({
        texto: "Error al eliminar: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  }

  useEffect(() => {
    props.setRecargaFoto(false);
    fotoRef.current.src =
      "https://www.copilco.net/expedientes/habitaciones/" +
      props.habitacion.IDHab +
      "_ficha.jpg?" +
      new Date();
  }, [props]);

  const elimina = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const datos = {
      IDHab: props.habitacion.IDHab,
    };
    if (window.confirm("Seguro que quieres eliminar esta habitacion?")) {
      EnviaDatosANuevoServicio(
        "borraHabitacion",
        datos,
        respServ,
        setIsLoading,
        null,
        idUsu
      );
      /*
      const envio = await EnviaDatosAServicio(
        props.habitacion,
        "borrarHabitacion"
      );
      if (envio.ERROR === "") {
        toast("Habitación eliminada", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setRecargar(true);
        
        //props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al eliminar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al eliminar: " + envio.DESCERROR);
      }*/
    }
  };

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <div
      id={props.habitacion.IDHab}
      onClick={(ev) => {
        ev.preventDefault();
        props.editaHabitacion(ev);
      }}
      className={classes.back}
      style={{
        backgroundColor:
          props.habitacion.Nombre !== null
            ? "#fff"
            : props.habitacion.Estatus === "OK"
            ? "#ccc"
            : "#c36770",
      }}
    >
      <img
        ref={fotoRef}
        id={props.habitacion.IDHab}
        className={classes.fotoEstud}
        src={
          "https://www.copilco.net/expedientes/habitaciones/" +
          props.habitacion.IDHab +
          "_ficha.jpg"
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "https://www.copilco.net/expedientes/habitaciones/default.jpg";
        }}
        alt={props.habitacion.Habitacion}
      />
      <div id={props.habitacion.IDHab}>
        <label id={props.habitacion.IDHab} className={classes.nombre}>
          {props.habitacion.Habitacion}
        </label>
        <label id={props.habitacion.IDHab} className={classes.nombre}>
          {props.habitacion.Nombre !== null
            ? props.habitacion.Nombre
            : props.habitacion.Estatus === "OK"
            ? "DESOCUPADA"
            : "EN REMODELACION"}
        </label>
        <label id={props.habitacion.IDHab} className={classes.nombre}>
          Precio: {FormateaCurrency.format(props.habitacion.Precio)}
        </label>
        <label id={props.habitacion.IDHab} className={classes.otros}>
          Tamaño: {props.habitacion.Ancho + " x " + props.habitacion.Largo} (
          {Formatea1Decimal.format(
            parseFloat(props.habitacion.Largo) * props.habitacion.Ancho
          )}{" "}
          m2)
        </label>
        <label id={props.habitacion.IDHab} className={classes.otros}>
          Max Inq: {props.habitacion.MaxInq}
        </label>
        <div id={props.habitacion.IDHab} className={classes.divBotones}>
          {/*<button id={props.habitacion.IDHab} onClick={props.editaHabitacion}>
            {props.nivel === 1 ? (
              <i id={props.habitacion.IDHab} className="fas fa-file-alt"></i>
            ) : (
              <i id={props.habitacion.IDHab} className="fas fa-eye"></i>
            )}
          </button>*/}
          {props.nivel === 1
            ? props.habitacion.Nombre === null && (
                <button id={props.habitacion.IDHab} onClick={elimina}>
                  <i
                    id={props.habitacion.IDHab}
                    className="fas fa-trash-alt"
                  ></i>
                </button>
              )
            : null}
        </div>
      </div>
    </div>
  );
}

export default HabitacionItem;
