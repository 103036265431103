import classes from "./PagosEstudianteFiltroMenu.module.css";
import { useLayoutEffect, useRef, useState } from "react";
import { addDays } from "date-fns";

function FiltroPagosEstudiante(props) {
  const targetRef = useRef();
  const RentaRef = useRef();
  const DepGarRef = useRef();
  const LlavesRef = useRef();
  const FechaIniRef = useRef();
  const FechaFinRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });
  const [cambiarFiltro, setCambiarFiltro] = useState(true);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posFiltroPagos.x - targetRef.current.offsetWidth,
        y: props.posFiltroPagos.y,
      });
    }
  }, [props.posFiltroPagos]);

  function cambioFiltro() {
    if (FechaIniRef.current.value > FechaFinRef.current.value) {
      setCambiarFiltro(false);
      //console.log("FechaIniRef", FechaIniRef.current.value);
      var temp = new Date(FechaIniRef.current.value.substr(0, 10) + " 0:0:0");
      //var temp1 = new Date(FechaFinRef.current.value.substr(0, 10) + " 0:0:0");
      //console.log("temp", temp);
      temp = addDays(temp, 1);
      //console.log("temp", temp.toISOString().substr(0, 10));
      //FechaIniRef.current.value = temp1.toISOString().substr(0, 10);
      FechaFinRef.current.value = temp.toISOString().substr(0, 10);
      setCambiarFiltro(true);
    }
    props.setFiltroPagos({
      Renta: RentaRef.current.checked,
      DepGar: DepGarRef.current.checked,
      Llaves: LlavesRef.current.checked,
      FechaIni: FechaIniRef.current.value,
      FechaFin: FechaFinRef.current.value,
    });
    //console.log("cambiofiltro");
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posFiltroPagos.y : dimensions.y,
        left: dimensions.x === 0 ? props.posFiltroPagos.x : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <div className={classes.contCheckboxes}>
        <label className={classes.labelCheckboxes}>
          <input
            type="checkbox"
            ref={RentaRef}
            defaultChecked={props.filtroPagos.Renta}
            onChange={cambioFiltro}
          ></input>
          Renta
        </label>
        <label className={classes.labelCheckboxes}>
          <input
            type="checkbox"
            ref={DepGarRef}
            defaultChecked={props.filtroPagos.DepGar}
            onChange={cambioFiltro}
          ></input>
          Dep Garantía
        </label>
        <label className={classes.labelCheckboxes}>
          <input
            type="checkbox"
            ref={LlavesRef}
            defaultChecked={props.filtroPagos.Llaves}
            onChange={cambioFiltro}
          ></input>
          Pago Llaves
        </label>
        <div className={classes.labelCheckboxes}>
          <input
            required
            type="date"
            ref={FechaIniRef}
            defaultValue={props.filtroPagos.FechaIni}
            onChange={cambiarFiltro ? cambioFiltro : null}
          ></input>
          al
          <input
            required
            type="date"
            ref={FechaFinRef}
            defaultValue={props.filtroPagos.FechaFin}
            onChange={cambiarFiltro ? cambioFiltro : null}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default FiltroPagosEstudiante;
