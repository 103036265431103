import { Slide, toast } from "react-toastify";
import { useRef } from "react";
import { EnviaDatosAServicio } from "../../Funciones/Funciones";
import classes from "./ProveedorNuevo.module.css";

function NuevoProv(props) {
  const proveedorRef = useRef();
  const observRef = useRef();

  const GuardaProv = async (ev) => {
    ev.preventDefault();
    if (proveedorRef.current.value === "") {
      props.setDialogoOK({
        texto: "por favor llena el nombre del proveedor",
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("por favor llena el nombre del proveedor");
    } else {
      const ProvN = {
        IDProv: "0",
        Proveedor: proveedorRef.current.value,
        Observaciones: observRef.current.value,
      };
      const envio = await EnviaDatosAServicio(ProvN, "nuevoProveedor");
      if (envio.ERROR === "") {
        toast("Proveedor guardado", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setMuestraNuevoProv(false);
        props.setRecargar(true);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }
    }
  };

  function cancelaNuevo(ev) {
    ev.preventDefault();
    props.setMuestraNuevoProv(false);
  }

  return (
    <section className={classes.modal}>
      <h1>Nuevo Proveedor</h1>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>Proveedor:</label>
        <input
          required
          type="text"
          ref={proveedorRef}
          placeholder="Nombre proveedor"
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.contRenglon}>
        <label className={classes.labelP}>Observaciones:</label>
        <input
          required
          type="text"
          ref={observRef}
          placeholder="Algun comentario?"
          className={classes.textareaNE}
        ></input>
      </div>
      <div className={classes.divBotones}>
        <button className={classes.botones} onClick={(ev) => GuardaProv(ev)}>
          <i className="fas fa-save"></i>
        </button>
        <button className={classes.botones} onClick={cancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </section>
  );
}

export default NuevoProv;
