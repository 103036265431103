import { useRef, useState, useLayoutEffect } from "react";
import classes from "./LlavesOrdena.module.css";

function MenuOrdenaLlaves(props) {
  const targetRef = useRef(); 
  const llaveAZRef = useRef();
  const llaveZARef = useRef();
  const estatusAZRef = useRef();
  const estatusZARef = useRef();
  const asignadaAZRef = useRef();
  const asignadaZARef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionOrdenaLlaves.x - targetRef.current.offsetWidth,
        y: props.posicionOrdenaLlaves.y,
      });
    }
  }, [props.posicionOrdenaLlaves]);

  function cambioFiltro(ev) {
    props.setMuestraOrdenaLlav(false);
    llaveAZRef.current.checked = ev.target.id === "llaveAZ";
    llaveZARef.current.checked = ev.target.id === "llaveZA";
    estatusAZRef.current.checked = ev.target.id === "estatusAZ";
    estatusZARef.current.checked = ev.target.id === "estatusZA";
    asignadaAZRef.current.checked = ev.target.id === "asignadaAZ";
    asignadaZARef.current.checked = ev.target.id === "asignadaZA";

    props.setOrdenaLlaves({
      llaveAZ: llaveAZRef.current.checked,
      llaveZA: llaveZARef.current.checked,
      estatusAZ: estatusAZRef.current.checked,
      estatusZA: estatusZARef.current.checked,
      asignadaAZ: asignadaAZRef.current.checked,
      asignadaZA: asignadaZARef.current.checked,
    });
  }

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top:
          dimensions.y === 0
            ? props.posicionOrdenaLlaves.y
            : dimensions.y,
        left:
          dimensions.x === 0
            ? props.posicionOrdenaLlaves.x
            : dimensions.x,
      }}
    >
      <label>
        <input
          type="radio"
          id="llaveAZ"
          ref={llaveAZRef}
          defaultChecked={props.ordenaLlaves.llaveAZ}
          onChange={cambioFiltro}
        ></input>
        Llave <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="llaveZA"
          ref={llaveZARef}
          defaultChecked={props.ordenaLlaves.llaveZA}
          onChange={cambioFiltro}
        ></input>
        Llave <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="estatusAZ"
          ref={estatusAZRef}
          defaultChecked={props.ordenaLlaves.estatusAZ}
          onChange={cambioFiltro}
        ></input>
        Estatus <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="estatusZA"
          ref={estatusZARef}
          defaultChecked={props.ordenaLlaves.estatusZA}
          onChange={cambioFiltro}
        ></input>
        Estatus <i className="fas fa-sort-amount-up"></i>
      </label>
      <label>
        <input
          type="radio"
          id="asignadaAZ"
          ref={asignadaAZRef}
          defaultChecked={props.ordenaLlaves.asignadaAZ}
          onChange={cambioFiltro}
        ></input>
        Asignada a <i className="fas fa-sort-amount-down"></i>
      </label>
      <label>
        <input
          type="radio"
          id="asignadaZA"
          ref={asignadaZARef}
          defaultChecked={props.ordenaLlaves.asignadaZA}
          onChange={cambioFiltro}
        ></input>
        Asignada a <i className="fas fa-sort-amount-up"></i>
      </label>
    </div>
  );
}

export default MenuOrdenaLlaves;
