import classes from "./HabitacionesLista.module.css";
import HabitacionItem from "./HabitacionItem";

function HabitacionesLista(props) {
  //console.log("props", props)
  return (
    <ul className={classes.list}>
      {props.habitacionesLoaded.length === 0 ? (
        <h1>No hay habitaciones que mostrar</h1>
      ) : (
        props.habitacionesLoaded.map((habitacion) => (
          <HabitacionItem
            nivel={props.nivel}
            setRecargar={props.setRecargar}
            key={habitacion.IDHab}
            habitacion={habitacion}
            eliminaHabitacion={props.eliminaHabitacion}
            editaHabitacion={props.editaHabitacion}
            setRecargaFoto={props.setRecargaFoto}
            setDialogoOK={props.setDialogoOK}
            setMuestraDialogoOK={props.setMuestraDialogoOK}
          />
        ))
      )}
    </ul>
  );
}

export default HabitacionesLista;
