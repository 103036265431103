import { useRef, useState, useLayoutEffect } from "react";
import classes from "./EgresosFiltra.module.css";

function MenuFiltraEgresos(props) {
  const targetRef = useRef();
  const EfectivoRef = useRef();
  const BancoRef = useRef();
  const CeCoRef = useRef();
  const ProveedorRef = useRef();
  const ConceptoRef = useRef();
  const ObservacionesRef = useRef();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  function cancelaFiltro() {
    EfectivoRef.current.checked = true;
    BancoRef.current.checked = true;
    CeCoRef.current.value = "";
    ProveedorRef.current.value = "";
    ConceptoRef.current.value = "";
    ObservacionesRef.current.value = "";
    props.setFiltroEgresos({
      ...props.filtroEgresos,
      ...{
        Efectivo: true,
        Banco: true,
        CeCo: "",
        Proveedor: "",
        Concepto: "",
        Observaciones: "",
      },
    });
    props.setMuestraFiltroEgr(false);
  }

  function cambioFiltro() {
    props.setFiltroEgresos({
      ...props.filtroEgresos,
      ...{
        Efectivo: EfectivoRef.current.checked,
        Banco: BancoRef.current.checked,
        CeCo: CeCoRef.current.value,
        Proveedor: ProveedorRef.current.value,
        Concepto: ConceptoRef.current.value,
        Observaciones: ObservacionesRef.current.value,
      },
    });
  }

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        x: props.posicionFiltroEgresos.x - targetRef.current.offsetWidth,
        y: props.posicionFiltroEgresos.y,
      });
    }
  }, [props.posicionFiltroEgresos]);

  return (
    <div
      ref={targetRef}
      className={classes.modal}
      style={{
        top: dimensions.y === 0 ? props.posicionFiltroEgresos.y : dimensions.y,
        left: dimensions.x === 0 ? props.posicionFiltroEgresos.x : dimensions.x,
      }}
    >
      <label className={classes.nombre}>Mostrar:</label>
      <label>
        <input
          type="checkbox"
          ref={EfectivoRef}
          defaultChecked={props.filtroEgresos.Efectivo}
          onChange={cambioFiltro}
        ></input>
        Efectivo
      </label>
      <label>
        <input
          type="checkbox"
          ref={BancoRef}
          defaultChecked={props.filtroEgresos.Banco}
          onChange={cambioFiltro}
        ></input>
        Banco
      </label>
      <label>
        CeCo:{" "}
        <input
          type="text"
          ref={CeCoRef}
          defaultValue={props.filtroEgresos.CeCo}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Proveedor:{" "}
        <input
          type="text"
          ref={ProveedorRef}
          defaultValue={props.filtroEgresos.Proveedor}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Concepto:{" "}
        <input
          type="text"
          ref={ConceptoRef}
          defaultValue={props.filtroEgresos.Concepto}
          onChange={cambioFiltro}
        ></input>
      </label>
      <label>
        Observaciones:{" "}
        <input
          type="text"
          ref={ObservacionesRef}
          defaultValue={props.filtroEgresos.Observaciones}
          onChange={cambioFiltro}
        ></input>
      </label>
      <div className={classes.botok}>
        <button onClick={cancelaFiltro}>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => {
            props.setMuestraFiltroEgr(false);
          }}
        >
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>
  );
}

export default MenuFiltraEgresos;
