import { differenceInCalendarDays, parseISO } from "date-fns";
import {
  FechaParaInputDate,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import classes from "./EstudianteMiniItem.module.css";

function EstudianteMiniItem(props) {
  function VencioContrato(fechaVence) {
    const fecha = FechaParaInputDate(fechaVence);
    return differenceInCalendarDays(parseISO(fecha), new Date());
  }

  function HabitacionDeIDHab(IDHab) {
    for (var i = 0; i < props.habitaciones.length; i += 1) {
      if (props.habitaciones[i].IDHab === IDHab) {
        return props.habitaciones[i].Habitacion;
      }
    }
    return IDHab;
  }

  function RentaDeIDHab(IDHab) {
    for (var i = 0; i < props.habitaciones.length; i += 1) {
      if (props.habitaciones[i].IDHab === IDHab) {
        return "Renta " + FormateaCurrency.format(props.habitaciones[i].Precio);
      }
    }
    return "Renta no encontrada";
  }

  function MostrarAdeudo(ev) {
    ev.preventDefault();
    //buscamos el padre hasta encontrar la clase "back"
    var elem = ev.target.parentElement;
    var x = ev.pageX;
    var y = ev.pageY;
    var w = 0;
    var h = 0;
    //console.log("elem", elem);
    while (elem !== null) {
      if (elem.id === "fondoItem") {
        //console.log("elemEnc", elem);
        x = elem.offsetLeft; //+ ((elem.offsetWidth - elem.offsetLeft)/2);
        y = elem.offsetTop;
        w = elem.offsetWidth;
        h = elem.offsetHeight;
        break;
      }
      elem = elem.parentElement;
    }
    props.preparaAdeudo({
      posX: `${x}px`,
      posY: `${y}px`,
      x: x,
      y: y,
      w: w,
      h: h,
      adeudo: props.estudiante.Adeudo,
    });
  }

  function MontoAdeuda() {
    var total = 0;
    if (props.estudiante.Adeudo.indexOf(",") >= 0) {
      var temp = props.estudiante.Adeudo.split(",");

      //console.log("temp", temp);
      for (var i = 0; i < temp.length; i = i + 1) {
        if (temp[i].indexOf("(") >= 0) {
          var debe = temp[i].substring(temp[i].indexOf("(") + 1);
          debe = debe.substring(0, debe.indexOf(")"));
          total = parseInt(total) + parseInt(debe);
        }
      }
    } else {
      var debe1 = props.estudiante.Adeudo.substring(
        props.estudiante.Adeudo.indexOf("(") + 1
      );
      debe1 = debe1.substring(0, debe1.indexOf(")"));
      total = parseInt(debe1);
    }
    return "Adeuda: " + FormateaCurrency.format(total);
  }
  //console.log("props", props);
  /*************************************************************************/
  /****************************RENDER***************************************/
  /*************************************************************************/

  return (
    <div
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.muestra === "LN"
            ? "#fff"
            : VencioContrato(props.estudiante.VenceCont) < 0
            ? "#f30"
            : VencioContrato(props.estudiante.VenceCont) < 60
            ? "#FF0"
            : "#fff",
      }}
    >
      <img
        className={classes.fotoEstud}
        src={
          "https://www.copilco.net/expedientes/documentos/" +
          props.estudiante.IDEst +
          "_foto.jpg"
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "https://www.copilco.net/expedientes/documentos/default.jpg";
        }}
        alt={props.estudiante.Nombre}
      />
      <div>
        <label className={classes.nombre}>{props.estudiante.Nombre}</label>
        {props.muestra === "LN" ? null : props.estudiante.Adeudo !== "0" ? (
          <label className={classes.otros}>
            Hab {HabitacionDeIDHab(props.estudiante.IDHab)}
          </label>
        ) : (
          <label className={classes.otros}>
            Habitacion {HabitacionDeIDHab(props.estudiante.IDHab)}
          </label>
        )}
        {props.muestra === "LN" ? null : props.estudiante.Adeudo !== "0" ? (
          <label className={classes.otros}>
            {RentaDeIDHab(props.estudiante.IDHab)}
          </label>
        ) : (
          <label className={classes.otros}>
            Habitacion {HabitacionDeIDHab(props.estudiante.IDHab)}
          </label>
        )}
        {props.muestra === "LN" ? null : props.estudiante.Adeudo !== "0" ? (
          <label className={classes.otros}>
            {MontoAdeuda()}
            <button
              id={props.estudiante.IDEst}
              onClick={(ev) => {
                ev.stopPropagation();
                MostrarAdeudo(ev);
              }}
              className={classes.btnAdeuda}
            >
              <i className="fas fa-ellipsis-h"></i>
            </button>
          </label>
        ) : (
          <label className={classes.otros}>
            {RentaDeIDHab(props.estudiante.IDHab)}
          </label>
        )}
        {props.muestra === "LN" ? null : (
          <label className={classes.otros}>
            Día Corte: {props.estudiante.DiaCorte}
          </label>
        )}
        <div className={classes.divBotones}>
          {props.nivel === 1 && props.muestra !== "LN" ? (
            <button
              id={props.estudiante.IDEst}
              onClick={(ev) => {
                ev.stopPropagation();
                props.AgregaPagoHandler(props.estudiante);
              }}
              className={classes.innbtn}
            >
              <i className="fas fa-dollar-sign"></i>
            </button>
          ) : null}
          {props.muestra === "LN" ? (
            <button
              onClick={(ev) => {
                ev.stopPropagation();
                props.AgregaAListaNegra(props.estudiante.IDEst);
              }}
              id={props.estudiante.IDEst}
              className={classes.innbtn}
            >
              <i className="fas fa-plus"></i>
            </button>
          ) : (
            <button
              onClick={(ev) => {
                ev.stopPropagation();
                props.AgregaDescuentoHandler(props.estudiante);
              }}
              id={props.estudiante.IDEst}
              className={classes.innbtn}
            >
              <i className="fas fa-calendar-plus"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EstudianteMiniItem;
