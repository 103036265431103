import { useRef, useState } from "react";
import {
  EnviaDatosANuevoServicio,
  FechaParaInputDate,
} from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./PendientesEdicion.module.css";
import { Slide, toast } from "react-toastify";

function PendienteEdicion(props) {
  const { token } = useToken();
  const descripcionRef = useRef();
  const fechaAsignacionRef = useRef();
  const fechaInicioRef = useRef();
  const materialesRef = useRef();
  const observacionesRef = useRef();
  const fechaTerminoRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [hayCambios, setHayCambios] = useState(false);

  function CancelaEdicion(ev) {
    ev.preventDefault();
    props.setMuestraEdicion(false);
  }

  function respServ(resp) {
    console.log(resp);
    setIsLoading(false);
    if (resp.Error === "SI") {
      props.setDialogoOK({
        texto: "Error al guardar: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    } else {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.PendienteCompleto();
    }
  }

  //console.log(token)
  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      var estatusCalc = props.pendiente.Estatus;
      if (fechaTerminoRef.current.value !== "") estatusCalc = "TERMINADO";
      else if (fechaInicioRef.current.value !== "") estatusCalc = "INICIADO";
      const pendienteN = {
        IDPend: props.pendiente.IDPend,
        IDCasa: props.pendiente.IDCasa,
        IDAsigno: token.Id,
        Orden: props.pendiente.Orden,
        FechaAsigno: props.pendiente.FechaAsigno,
        FechaInicio:
          fechaInicioRef.current.value === ""
            ? ""
            : fechaInicioRef.current.value
                .toString()
                .toUpperCase()
                .replace(/-/gi, ""),
        Descripcion: descripcionRef.current.value,
        Materiales: materialesRef.current.value,
        Observaciones: observacionesRef.current.value,
        FechaTermino:
          fechaTerminoRef.current.value === ""
            ? ""
            : fechaTerminoRef.current.value
                .toString()
                .toUpperCase()
                .replace(/-/gi, ""),
        Estatus: estatusCalc,
        Tipo: props.pendiente.Tipo,
      };
      console.log("pendienteN", pendienteN);
      EnviaDatosANuevoServicio(
        "EdicionPendiente",
        pendienteN,
        respServ,
        setIsLoading,
        null,
        token.Id
      );
      /*
      const envio = await EnviaDatosAServicio(pendienteN, "EdicionPendiente");
      if (envio.ERROR === "") {
        props.setDialogoOK({
          texto: "Guardado OK",
          mostrandoError: false,
          recargar: true,
          cerrarVentana: "PendienteCompleto",
        });
        props.setMuestraDialogoOK(true);
        //alert("Guardado OK");
        //props.PendienteCompleto();
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }*/
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (descripcionRef.current.value === "") {
      respuesta = respuesta + "Descripcion\n";
    }
    return respuesta; // respuesta;
  }

  //console.log("props", props);
  let varcolor = "#0000ff";
  if (props.pendiente.Tipo === "1") {
    varcolor = "#ff0000";
  }

  /***********************************************************************/
  /********************************* RENDER ******************************/
  /***********************************************************************/

  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  //console.log("conserje?", token.nivel, token.nivel === "3");
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {hayCambios && (props.nivel === 1 || props.nivel === 3) && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaEdicion}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>Ficha Pendiente</h1>
      <form className={classes.forma} onSubmit={submitHandler}>
        <div>
          <div>
            <label
              className={classes.tituloP + " " + classes.labelP}
              style={{ color: varcolor }}
            >
              {props.pendiente.DescTipo +
                " " +
                props.pendiente.Orden +
                "   (" +
                props.pendiente.Estatus +
                ")"}
            </label>
          </div>
          <div>
            <textarea
              readOnly={token.nivel === "3"}
              ref={descripcionRef}
              className={classes.textareaDesc}
              defaultValue={props.pendiente.Descripcion}
              onChange={setHayCambios}
            ></textarea>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Fecha Asignación</label>
            <input
              ref={fechaAsignacionRef}
              type="date"
              disabled
              value={FechaParaInputDate(props.pendiente.FechaAsigno)}
            ></input>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Fecha Inicio</label>
            <input
              ref={fechaInicioRef}
              readOnly={props.pendiente.FechaInicio}
              type="date"
              defaultValue={
                !props.pendiente.FechaInicio
                  ? ""
                  : FechaParaInputDate(props.pendiente.FechaInicio)
              }
              onChange={setHayCambios}
            ></input>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Materiales</label>
            <textarea
              ref={materialesRef}
              className={classes.textareaP}
              defaultValue={props.pendiente.Materiales}
              onChange={setHayCambios}
            ></textarea>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Observaciones</label>
            <textarea
              ref={observacionesRef}
              className={classes.textareaP}
              defaultValue={props.pendiente.Observaciones}
              onChange={setHayCambios}
            ></textarea>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Fecha Término</label>
            <input
              ref={fechaTerminoRef}
              type="date"
              defaultValue={
                !props.pendiente.FechaTermino
                  ? ""
                  : FechaParaInputDate(props.pendiente.FechaTermino)
              }
              onChange={setHayCambios}
            ></input>
          </div>
        </div>
      </form>
    </section>
  );
}

export default PendienteEdicion;
