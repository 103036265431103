import { addMonths, compareAsc, differenceInDays } from "date-fns";
import { useState, useEffect, useRef } from "react";
import {
  DateParaInputDate,
  DejaSoloNumerosDeCurrency,
  EnviaDatosANuevoServicio,
  FechaMMMDeDate,
  FechaMMMDeString,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./HabitacionCambia.module.css";
import { Slide, toast } from "react-toastify";

function CambiaHabitacion(props) {
  const fechaCambioRef = useRef();
  const corteAnteriorRef = useRef();
  const diasOcupadoActualRef = useRef();
  const montoOcupadoActualRef = useRef();
  const precioCambiarARef = useRef();
  const rentaDiariaNuevaRef = useRef();
  const montoOcupadoNuevaRef = useRef();
  const diasOcupadoNuevaRef = useRef();
  const nuevaHabitacionRef = useRef();
  const debioPagarLabelRef = useRef();
  const montoPagoRef = useRef();
  const montoPagoLabelRef = useRef();
  const debioPagarRef = useRef();
  const diferenciaPagoLabelRef = useRef();
  const diferenciaPagoRef = useRef();
  const totalPagoLabelRef = useRef();
  const totalPagoRef = useRef();
  const observRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [habitacionesLoaded, setHabitacionesLoaded] = useState([]);
  const [inquilinoLoaded, setInquilinoLoaded] = useState([]);
  const { token } = useToken();
  const idCasa = token.casaSel;
  const idUsu = token.Id;
  const IDEst = props.datosCambiaHabitacion.IDEst;

  function respServ(resp) {
    console.log("respuesta", resp);
    if (resp.Error === "") {
      var inquilino = resp.RInquilinos[0]["Datos"];

      const ultimoPago = resp.UltimoPago[0];
      const mesQPag = ultimoPago ? ultimoPago.MesQPag : "";
      const ultPag = ultimoPago ? ultimoPago.Monto : "";
      inquilino = { ...inquilino, MesQPag: mesQPag, UltPag: ultPag };
      console.log("habitaciones", resp.HabDisp);
      console.log("inquilino", inquilino);
      console.log("ultimoPago", ultimoPago);
      setHabitacionesLoaded(resp.HabDisp);
      setInquilinoLoaded(inquilino);
    } else {
      setIsLoading(false);
      console.log("ERROR", resp.ERRORDESC);
    }
  }

  useEffect(() => {
    setIsLoading(true);

    const datos = {
      IDCasa: idCasa,
      IDEst: IDEst,
    };
    EnviaDatosANuevoServicio(
      "iniciaCambiaHabitacion",
      datos,
      respServ,
      setIsLoading,
      null,
      idUsu
    );
    /*
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "iniciaCambiaHabitacion",
        IDCasa: idCasa,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log("data", data);
        var habitaciones = [];
        var inquilinos = [];
        var ultimosPagos = [];
        if (data.ERROR !== "SI") {
          console.log("data", data);
          for (const key in data) {
            const datos = {
              ...data[key],
            };
            if (key === "HabDisp") {
              habitaciones = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "RInquilinos") {
              inquilinos = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "UltimoPago") {
              ultimosPagos = Object.keys(datos).map((key) => datos[key]);
            }
          }
          var inquilino = inquilinos.filter(
            (inq) => inq.Datos.IDEst === props.datosCambiaHabitacion.IDEst
          )[0].Datos;
          const ultimoPago = ultimosPagos.filter(
            (up) => up.IDEst === props.datosCambiaHabitacion.IDEst
          )[0];
          const mesQPag = ultimoPago ? ultimoPago.MesQPag : "";
          const ultPag = ultimoPago ? ultimoPago.Monto : "";
          inquilino = { ...inquilino, MesQPag: mesQPag, UltPag: ultPag };
          //console.log("habitaciones", habitaciones);
          //console.log("inquilino", inquilino);
          //console.log("ultimoPago", ultimoPago);
          setHabitacionesLoaded(habitaciones);
          setInquilinoLoaded(inquilino);

          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("ERROR", data.ERROR);
        }
      });*/
  }, [idCasa, IDEst, idUsu]);

  function respServGuarda(resp) {
    console.log(resp);
    if (resp.Error === "") {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.setMostrarCambiaHabitacion(false);
      props.setBorrado(true);
    } else {
      props.setDialogoOK({
        texto: "Error al guardar: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    if (DatosValidos === "") {
      const habitacionN = {
        IDEst: props.datosCambiaHabitacion.IDEst,
        IDHabNva: nuevaHabitacionRef.current.value, //en IDLlave se manda la nueva habitacion
        IDHabAnt: inquilinoLoaded.IDHab,
        Observaciones: observRef.current.value,
        FechaIng: fechaCambioRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
      };
      //console.log("habitacionN", habitacionN);
      EnviaDatosANuevoServicio(
        "CambiaHabitacion",
        habitacionN,
        respServGuarda,
        setIsLoading,
        null,
        idUsu
      );
      /*
      const envio = await EnviaDatosAServicio(habitacionN, "CambiaHabitacion");
      if (envio.ERROR === "") {
        toast("Guardado OK", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setMostrarCambiaHabitacion(false);
        //alert("Cambio guardado OK");
        //props.setMostrarCambiaHabitacion(false);
        props.setBorrado(true);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }*/
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + DatosValidos,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (nuevaHabitacionRef.current.value === "0") {
      respuesta = respuesta + "Habitación nueva\n";
    }
    return respuesta; // respuesta;
  }

  function CancelaNuevo(ev) {
    ev.preventDefault();
    props.setMostrarCambiaHabitacion(false);
  }

  function cambioHabitacionNueva() {
    calculaMontos();
  }

  function cambioFechaCambio() {
    calculaMontos();
  }

  function calculaMontos() {
    if (
      fechaCambioRef.current !== undefined &&
      corteAnteriorRef.current !== undefined &&
      diasOcupadoActualRef.current !== undefined
    ) {
      if (
        fechaCambioRef.current !== null &&
        corteAnteriorRef.current !== null &&
        diasOcupadoActualRef.current !== null
      ) {
        corteAnteriorRef.current.value = FechaCorteAnterior();
        const fc = new Date(fechaCambioRef.current.value);
        const ca = new Date(corteAnteriorRef.current.value);
        const dias = differenceInDays(fc, ca);
        diasOcupadoActualRef.current.value = dias;
        const monto = FormateaCurrency.format(
          dias * (inquilinoLoaded.Precio / 30)
        );
        montoOcupadoActualRef.current.value = monto;
        if (nuevaHabitacionRef.current !== undefined) {
          if (nuevaHabitacionRef.current !== null) {
            if (nuevaHabitacionRef.current.value > 0) {
              const hab = habitacionesLoaded.filter(
                (habi) => habi.IDHab === nuevaHabitacionRef.current.value
              )[0];
              precioCambiarARef.current.value = FormateaCurrency.format(
                hab.Precio
              );
              rentaDiariaNuevaRef.current.value = FormateaCurrency.format(
                hab.Precio / 30
              );
              diasOcupadoNuevaRef.current.value =
                30 - parseInt(diasOcupadoActualRef.current.value);
              montoOcupadoNuevaRef.current.value = FormateaCurrency.format(
                DejaSoloNumerosDeCurrency(rentaDiariaNuevaRef.current.value) *
                  diasOcupadoNuevaRef.current.value
              );
            } else {
              precioCambiarARef.current.value = "$0.00";
              rentaDiariaNuevaRef.current.value = "$0.00";
              diasOcupadoNuevaRef.current.value = "0";
              montoOcupadoNuevaRef.current.value = "$0.00";
            }
          }
        }
        CalculaPago();
      }
    }
  }

  function CalculaPago() {
    if (
      debioPagarLabelRef.current !== undefined &&
      corteAnteriorRef.current !== undefined &&
      montoPagoRef.current !== undefined &&
      montoPagoLabelRef.current !== undefined
    ) {
      if (
        debioPagarLabelRef.current !== null &&
        corteAnteriorRef.current !== null &&
        montoPagoRef.current !== null &&
        montoPagoLabelRef.current !== null
      ) {
        const ca = corteAnteriorRef.current.value;
        debioPagarLabelRef.current.innerHTML =
          "Total que debió pagar el " +
          FechaMMMDeString(
            ca.slice(0, 4) + ca.slice(5, 7) + ca.slice(8, 10)
          ).slice(0, 6) +
          "<br> (" +
          montoOcupadoActualRef.current.value +
          " + " +
          montoOcupadoNuevaRef.current.value +
          ")";
        debioPagarRef.current.value = FormateaCurrency.format(
          parseInt(
            DejaSoloNumerosDeCurrency(montoOcupadoActualRef.current.value)
          ) +
            parseInt(
              DejaSoloNumerosDeCurrency(montoOcupadoNuevaRef.current.value)
            )
        );
        montoPagoRef.current.value = FormateaCurrency.format(
          inquilinoLoaded.Precio
        );
        montoPagoLabelRef.current.innerHTML =
          "Monto que pagó el " +
          FechaMMMDeString(
            ca.slice(0, 4) + ca.slice(5, 7) + ca.slice(8, 10)
          ).slice(0, 6);
        const dif =
          DejaSoloNumerosDeCurrency(debioPagarRef.current.value) -
          inquilinoLoaded.Precio;
        if (dif < 0) {
          diferenciaPagoLabelRef.current.innerHTML =
            "Monto a bonificar por diferencia";
        } else {
          diferenciaPagoLabelRef.current.innerHTML =
            "Monto a pagar por diferencia";
        }
        var ca1 = new Date(
          ca.slice(0, 4),
          ca.slice(5, 7) - 1,
          ca.slice(8, 10),
          0,
          0,
          0
        );
        ca1 = addMonths(ca1, 1);
        diferenciaPagoRef.current.value = FormateaCurrency.format(
          Math.abs(dif)
        );
        totalPagoLabelRef.current.innerHTML =
          "Total a pagar el " +
          FechaMMMDeDate(ca1).slice(0, -4) +
          "<br>" +
          precioCambiarARef.current.value +
          (dif < 0 ? " - " : " + ") +
          FormateaCurrency.format(Math.abs(dif));
        console.log(
          "total pago",
          parseFloat(inquilinoLoaded.Precio),
          parseFloat(dif)
        );
        totalPagoRef.current.value = FormateaCurrency.format(
          parseFloat(
            DejaSoloNumerosDeCurrency(precioCambiarARef.current.value)
          ) + parseFloat(dif)
        );
      }
    }
  }

  function FechaCorteAnterior() {
    var fecha = inquilinoLoaded.FechaIng;
    if (inquilinoLoaded.MesQPag !== undefined) fecha = inquilinoLoaded.MesQPag;
    var diaCorte = new Date(
      parseInt(fecha.substr(0, 4)),
      parseInt(fecha.substr(4, 2)),
      parseInt(inquilinoLoaded.DiaCorte),
      0,
      0,
      0,
      0
    );
    if (fechaCambioRef.current !== undefined) {
      if (fechaCambioRef.current !== null) {
        const fc = new Date(fechaCambioRef.current.value);
        while (compareAsc(fc, diaCorte) === -1) {
          diaCorte = addMonths(diaCorte, -1);
        }
        while (differenceInDays(fc, diaCorte) > 30) {
          diaCorte = addMonths(diaCorte, 1);
        }
      }
    }
    return DateParaInputDate(diaCorte);
  }
  console.log(props);
  /******************************************************************************/
  /*********************************** RENDER ***********************************/
  /******************************************************************************/
  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        <button className={classes.botTop} onClick={submitHandler}>
          <i className="fas fa-save"></i>
        </button>
        <button onClick={CancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1>Cambiar habitación</h1>
      <form>
        <div>
          <div>
            <label className={classes.labelP}>Fecha cambio</label>
            <input
              required
              type="date"
              ref={fechaCambioRef}
              defaultValue={DateParaInputDate(new Date())}
              className={classes.textareaNE}
              onChange={cambioFechaCambio}
            ></input>
          </div>
          <div>
            <label className={classes.labelP}>Fecha corte anterior</label>
            <input
              readOnly
              required
              type="date"
              ref={corteAnteriorRef}
              defaultValue={FechaCorteAnterior()}
              className={classes.textareaNE}
            ></input>
          </div>
          <div className={classes.borde}>
            <label className={classes.tituloP + " " + classes.labelP}>
              Habitación actual
            </label>
            <label className={classes.labelP2}>
              {inquilinoLoaded.Habitacion}
            </label>
            <div>
              <label className={classes.labelP}>Precio</label>
              <input
                readOnly
                type="text"
                className={classes.textareaNE}
                defaultValue={FormateaCurrency.format(inquilinoLoaded.Precio)}
              ></input>
            </div>
            <div>
              <label className={classes.labelP}>Renta diaria</label>
              <input
                type="text"
                className={classes.textareaNE}
                defaultValue={FormateaCurrency.format(
                  inquilinoLoaded.Precio / 30
                )}
              ></input>
            </div>
            <div>
              <label className={classes.labelP}>Dias que lo ocupó</label>
              <input
                id="diasOcupadoActual"
                ref={diasOcupadoActualRef}
                type="text"
                className={classes.textareaNE}
                defaultValue={"30"}
              ></input>
            </div>
            <div>
              <label className={classes.labelP}>Monto x días ocupado</label>
              <input
                id="montoOcupadoActual"
                ref={montoOcupadoActualRef}
                type="text"
                className={classes.textareaNE}
                defaultValue={FormateaCurrency.format(inquilinoLoaded.Precio)}
              ></input>
            </div>
          </div>
          <div className={classes.borde}>
            <label className={classes.tituloP + " " + classes.labelP}>
              Cambiar a
            </label>
            <div>
              <select
                ref={nuevaHabitacionRef}
                className={classes.textareaNE}
                onChange={cambioHabitacionNueva}
              >
                <option key="0" value={"0"}>
                  Selecciona la habitación
                </option>
                {habitacionesLoaded.map((habitacion) => {
                  return (
                    <option key={habitacion.IDHab} value={habitacion.IDHab}>
                      {habitacion.Habitacion +
                        " (" +
                        FormateaCurrency.format(habitacion.Precio) +
                        ")"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label className={classes.labelP}>Precio</label>
              <input
                readOnly
                type="text"
                className={classes.textareaNE}
                ref={precioCambiarARef}
                defaultValue={"$0.00"}
              ></input>
            </div>
            <div>
              <label className={classes.labelP}>Renta diaria</label>
              <input
                type="text"
                className={classes.textareaNE}
                ref={rentaDiariaNuevaRef}
                defaultValue={"$0.00"}
              ></input>
            </div>
            <div>
              <label className={classes.labelP}>Dias que lo ocupó</label>
              <input
                id="diasOcupadoNuevo"
                ref={diasOcupadoNuevaRef}
                type="text"
                className={classes.textareaNE}
                defaultValue={"0"}
              ></input>
            </div>
            <div>
              <label className={classes.labelP}>Monto x días ocupado</label>
              <input
                id="montoOcupadoNuevo"
                ref={montoOcupadoNuevaRef}
                type="text"
                className={classes.textareaNE}
                defaultValue={"$0.00"}
              ></input>
            </div>
          </div>
          <div className={classes.borde}>
            <label className={classes.tituloP + " " + classes.labelP}>
              Cálculo montos
            </label>
            <div>
              <label ref={debioPagarLabelRef} className={classes.labelP3}>
                Total que debió pagar
              </label>
              <input
                readOnly
                id="debioPagar"
                ref={debioPagarRef}
                type="text"
                className={classes.textareaNE3}
              ></input>
            </div>
            <div>
              <label ref={montoPagoLabelRef} className={classes.labelP3}>
                Monto que pagó
              </label>
              <input
                readOnly
                id="montoPago"
                ref={montoPagoRef}
                type="text"
                className={classes.textareaNE3}
              ></input>
            </div>
            <div>
              <label ref={diferenciaPagoLabelRef} className={classes.labelP3}>
                Diferencia
              </label>
              <input
                readOnly
                id="diferenciaPago"
                ref={diferenciaPagoRef}
                type="text"
                className={classes.textareaNE3}
              ></input>
            </div>
            <div>
              <label ref={totalPagoLabelRef} className={classes.labelP3}>
                Total a pagar
              </label>
              <input
                readOnly
                id="totalPago"
                ref={totalPagoRef}
                type="text"
                className={classes.textareaNE3}
              ></input>
            </div>
          </div>
          <div>
            <label className={classes.labelP}>Observaciones</label>
            <input
              id="Observaciones"
              ref={observRef}
              type="text"
              className={classes.textareaNE}
            ></input>
          </div>
        </div>
      </form>
    </section>
  );
}

export default CambiaHabitacion;
