import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import OKModal from "../components/dialogos/dialogoOK";
import { EnviaDatosANuevoServicio } from "../Funciones/Funciones";
import Backdrop from "../components/layout/backdrop";
import classes from "./Ajustes.module.css";
import useToken from "../components/login/useToken";

function AjustesPage(props) {
  const { token } = useToken();
  const idUsu = token.Id;
  const [isLoading, setIsLoading] = useState(false);
  const [ajustes, setAjustes] = useState([]);
  const [porcPagoExt, setPorcPagoExt] = useState(ajustes.DefPorcPagoExt);
  const [diasPagoExt, setDiasPagoExt] = useState(ajustes.DefDiasPagoExt);
  const [descEfe, setDescEfe] = useState(ajustes.DefDescPagEfe);
  const [respServ, setRespServ] = useState("");
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [textoModal, setTextoModal] = useState("Guardado OK");
  const [forPag, setForPag] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const formaPagoRef = useRef();

  function cambiaAcceso(ev, usuario) {
    var nuevoAcceso = 1;
    if ((ev.target.value === "Ver")) {
      nuevoAcceso = 2;
    } else if ((ev.target.value === "Conserje")) {
      nuevoAcceso = 3;
    }
    var CyN;
    for (var i = 0; i < usuarios.length; i++) {
      if (usuario.IDUsu === usuarios[i].IDUsu) {
        CyN = JSON.parse(usuarios[i].CasasYNiveles);
        var llaves = Object.keys(CyN);
        for (var j = 0; j < llaves.length; j++) {
          if (llaves[j].toString() === props.casaSel.toString()) {
            CyN[llaves[j]] = nuevoAcceso;
          }
        }
        usuarios[i].CasasYNiveles = JSON.stringify(CyN);
        break;
      }
    }
  }

  //console.log("token", token);
  function NivelCasa(IDCasaT, CasasYNivelesT) {
    const CyN = JSON.parse(CasasYNivelesT);
    var resultado = "error";
    Object.keys(CyN).forEach((key) => {
      if (key.toString() === IDCasaT.toString()) {
        if (CyN[key].toString() === "1") {
          //console.log("Key", key, "nivel", CyN[key]);
          resultado = "Administrador";
        }
        if (CyN[key].toString() === "2") {
          //console.log("Key", key, "nivel", CyN[key]);
          resultado = "Ver";
        }
        if (CyN[key].toString() === "3") {
          //console.log("Key", key, "nivel", CyN[key]);
          resultado = "Conserje";
        }
      }
    });
    return resultado;
  }

  function cambiaFP(ev, IDForPag) {
    //console.log(ev.target.value, IDForPag);

    for (var i = 0; i < forPag.length; i++) {
      if (forPag[i].IDForPag === IDForPag) {
        forPag[i].FormaPago = ev.target.value;
        forPag[i].cambio = "SI";
      }
    }
    //console.log(forPag);
  }

  function respServNFP(respServ) {
    //console.log(respServ);
    if (respServ?.Error === "SI") {
      setTextoModal(respServ.DESCERROR);
      setMuestraDialogoOK(true);
    } else {
      const ajuste = {
        IDCasa: props.casaSel,
      };
      EnviaDatosANuevoServicio(
        "FormasPago",
        ajuste,
        setForPag,
        setIsLoading,
        null,
        idUsu
      );
    }
    setRespServ("");
  }

  function agregaFP(ev) {
    ev.preventDefault();

    if (formaPagoRef.current) {
      if (formaPagoRef.current.value !== "") {
        setIsLoading(true);
        const FP = {
          formaPago: formaPagoRef.current.value,
          IDCasa: props.casaSel,
        };
        EnviaDatosANuevoServicio(
          "NuevaFormaPago",
          FP,
          respServNFP,
          setIsLoading,
          null,
          idUsu
        );
        setIsLoading(false);
      }
    }
  }

  function palomeaOK() {
    setMuestraDialogoOK(false);
  }

  useEffect(() => {
    if (props.casaSel > 0) {
      setIsLoading(true);
      const ajuste = {
        IDCasa: props.casaSel,
      };
      EnviaDatosANuevoServicio(
        "ajustes",
        ajuste,
        setAjustes,
        setIsLoading,
        null,
        idUsu
      );
      EnviaDatosANuevoServicio(
        "FormasPago",
        ajuste,
        setForPag,
        setIsLoading,
        null,
        idUsu
      );
      EnviaDatosANuevoServicio(
        "UsuariosCasa",
        ajuste,
        setUsuarios,
        setIsLoading,
        null,
        idUsu
      );
    } else {
      setIsLoading(false);
    }
  }, [idUsu, props.casaSel]);

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const DatosValidos = ValidaDatos();
    //console.log("fp", forPag);
    if (DatosValidos === "") {
      const ajuste = {
        PorcExt: porcPagoExt,
        DiasExt: diasPagoExt,
        DescEfe: descEfe,
        IDCasa: props.casaSel,
        FormasPago: forPag,
        Usuarios: usuarios,
      };
      EnviaDatosANuevoServicio(
        "guardaDefaults",
        ajuste,
        setRespServ,
        setIsLoading,
        null,
        idUsu
      );
    } else {
      setTextoModal("Revisa los siguientes datos:\n" + DatosValidos);
      setMuestraDialogoOK(true);
      //alert("Revisa los siguientes datos:\n" + DatosValidos);
    }
  };

  function ValidaDatos() {
    var respuesta = "";

    if (porcPagoExt > 100 || porcPagoExt < 0) {
      respuesta = respuesta + "Porcentaje para pago extemporaneo\n";
    }
    if (diasPagoExt > 30 || diasPagoExt < 0) {
      respuesta = respuesta + "Dias para pago extemporaneo\n";
    }
    //console.log(descEfe, respuesta);
    if (descEfe < 0) {
      respuesta = respuesta + "Descuento por pago en efectivo\n";
    }
    for (var i = 0; i < forPag.length; i++) {
      if (forPag[i].FormaPago === "") {
        respuesta = respuesta + "No puede haber Formas de Pago vacías\n";
      }
    }
    return respuesta;
  }

  if (respServ !== "") {
    //console.log(respServ);
    if (respServ?.Error === "SI") {
      setTextoModal(respServ.DESCERROR);
      setMuestraDialogoOK(true);
    } else {
      setTextoModal("Guardado OK");
      setMuestraDialogoOK(true);
    }
    setRespServ("");
  }
  //console.log("FP", forPag);
  //console.log("Usuarios", usuarios);
  if (isLoading) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }

  return (
    <form onSubmit={submitHandler}>
      <section className={classes.centrarElem}>
        <div className={classes.TituloBoton}>
          <div className={classes.TitEst}>
            <h1>Ajustes</h1>
            <label className={classes.subtitulo}>Valores default</label>
          </div>
        </div>
        <div className={classes.TitEst}>
          <div>
            <label className={classes.labelP}>% de pago extemporaneo</label>
            <NumericFormat
              placeholder="5%"
              className={classes.textareaNE}
              //getInputRef={porcPagoExt}
              suffix={"%"}
              value={ajustes.DefPorcPagoExt}
              onValueChange={(values) => {
                setPorcPagoExt(values.floatValue);
              }}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>
              días para pago extemporaneo
            </label>
            <NumericFormat
              placeholder="5 días"
              className={classes.textareaNE}
              suffix={" días"}
              onValueChange={(values) => {
                setDiasPagoExt(values.floatValue);
              }}
              value={ajustes.DefDiasPagoExt}
            ></NumericFormat>
          </div>
          <div>
            <label className={classes.labelP}>
              Descuento por pago en efectivo
            </label>
            <NumericFormat
              placeholder="$100.00"
              className={classes.textareaNE}
              onValueChange={(values) => {
                setDescEfe(values.floatValue);
              }}
              prefix={"$"}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              decimalScale={2}
              value={ajustes.DefDescPagEfe}
            ></NumericFormat>
          </div>
          <div className={classes.contBotones}>
            <label className={classes.subtitulo}>Formas de Pago</label>
          </div>
          {forPag.length > 0 ? (
            forPag.map((formaPago) => (
              <input
                type="text"
                key={formaPago.IDForPag}
                defaultValue={formaPago.FormaPago}
                className={classes.textareaNE}
                onChange={(ev) => cambiaFP(ev, formaPago.IDForPag)}
              ></input>
            ))
          ) : (
            <label className={classes.labelFP}>Sin Formas de pago</label>
          )}
          <div>
            {props.nivel === 1 ? (
              <label className={classes.labelP}>Nueva forma de pago:</label>
            ) : null}
            {props.nivel === 1 ? (
              <input
                type="text"
                ref={formaPagoRef}
                placeholder="Efectivo"
                className={classes.textareaNE}
              ></input>
            ) : null}
            {props.nivel === 1 ? (
              <button className={classes.botAgregar} onClick={agregaFP}>
                <i className="fas fa-plus"></i>
              </button>
            ) : null}
          </div>
          <div className={classes.contBotones}>
            <label className={classes.subtitulo}>Usuarios con acceso</label>
          </div>
          {usuarios.length > 0 ? (
            usuarios.map((usuTemp) => (
              <div key={usuTemp.IDUsu}>
                <label className={classes.labelFP}>{usuTemp.Nombre}</label>
                <select
                  defaultValue={NivelCasa(props.casaSel, usuTemp.CasasYNiveles)}
                  onChange={(ev) => {
                    cambiaAcceso(ev, usuTemp);
                  }}
                >
                  <option key="1" value={"Administrador"}>
                    Administrador
                  </option>
                  <option key="2" value={"Ver"}>
                    Solo Ver
                  </option>
                  <option key="3" value={"Conserje"}>
                    Conserje
                  </option>
                </select>
              </div>
            ))
          ) : (
            <label className={classes.labelFP}>Sin usuarios</label>
          )}

          {props.nivel === 1 ? (
            <button className={classes.botones}>Guardar</button>
          ) : null}
        </div>
        {muestraDialogoOK && <OKModal texto={textoModal} oculta={palomeaOK} />}
        {muestraDialogoOK && <Backdrop zindex={49} />}
      </section>
    </form>
  );
}

export default AjustesPage;
