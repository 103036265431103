import { differenceInCalendarDays, parseISO } from "date-fns";
import { useEffect, useRef } from "react";
import {
  FechaParaInputDate,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import classes from "./EstudianteItem.module.css";

function EstudianteItem(props) {
  const fotoRef = useRef();
  //console.log("propsestMini", props);
  function EnviaWhats(ev) {
    ev.preventDefault();
    let url = `https://api.whatsapp.com/send?phone=${props.estudiante.TelMovil}`;
    window.open(url);
  }

  function VencioContrato(fechaVence) {
    const fecha = FechaParaInputDate(fechaVence);
    /*console.log(
      parseISO(fecha),
      new Date(),
      differenceInCalendarDays(parseISO(fecha), new Date())
    );*/
    //console.log(compareAsc(parseISO(fecha), new Date()), fecha);
    return differenceInCalendarDays(parseISO(fecha), new Date());
    //if (compareAsc(parseISO(fecha), new Date()) < 0) return true;
    //return false;
  }

  useEffect(() => {
    props.setRecargaFoto(false);
    fotoRef.current.src =
      "https://www.copilco.net/expedientes/documentos/" +
      props.estudiante.IDEst +
      "_foto.jpg?" +
      new Date();
  }, [props]);

  function HabitacionDeIDHab(IDHab) {
    for (var i = 0; i < props.habitaciones.length; i += 1) {
      if (props.habitaciones[i].IDHab === IDHab) {
        return props.habitaciones[i].Habitacion;
      }
    }
    return IDHab;
  }

  function RentaDeIDHab(IDHab) {
    for (var i = 0; i < props.habitaciones.length; i += 1) {
      if (props.habitaciones[i].IDHab === IDHab) {
        return "Renta " + FormateaCurrency.format(props.habitaciones[i].Precio);
      }
    }
    return "Renta no encontrada";
  }

  function MostrarAdeudo(ev) {
    ev.preventDefault();
    //buscamos el padre hasta encontrar la clase "back"
    var elem = ev.target.parentElement;
    var x = ev.pageX;
    var y = ev.pageY;
    var w = 0;
    var h = 0;
    //console.log("elem", elem);
    while (elem !== null) {
      if (elem.id === "fondoItem") {
        //console.log("elemEnc", elem);
        x = elem.offsetLeft; //+ ((elem.offsetWidth - elem.offsetLeft)/2);
        y = elem.offsetTop;
        w = elem.offsetWidth;
        h = elem.offsetHeight;
        break;
      }
      elem = elem.parentElement;
    }
    props.preparaAdeudo({
      posX: `${x}px`,
      posY: `${y}px`,
      x: x,
      y: y,
      w: w,
      h: h,
      adeudo: props.estudiante.Adeudo,
    });
  }

  function subMenuClickHandler(ev) {
    ev.preventDefault();
    props.setDatosSubMenu({
      posX: `${ev.pageX}px`,
      posY: `${ev.pageY}px`,
      estudiante: props.estudiante,
    });
    props.setMuestraSubMenu(true);
  }

  function MontoAdeuda() {
    var total = 0;
    if (props.estudiante.Adeudo.indexOf(",") >= 0) {
      var temp = props.estudiante.Adeudo.split(",");

      //console.log("temp", temp);
      for (var i = 0; i < temp.length; i = i + 1) {
        if (temp[i].indexOf("(") >= 0) {
          var debe = temp[i].substring(temp[i].indexOf("(") + 1);
          debe = debe.substring(0, debe.indexOf(")"));
          total = parseInt(total) + parseInt(debe);
        }
      }
    } else {
      var debe1 = props.estudiante.Adeudo.substring(
        props.estudiante.Adeudo.indexOf("(") + 1
      );
      debe1 = debe1.substring(0, debe1.indexOf(")"));
      total = parseInt(debe1);
    }
    return "Adeuda: " + FormateaCurrency.format(total);
  }

  /******************************************************************************************** */
  /***************************************** RENDER ******************************************* */
  /******************************************************************************************** */

  //console.log(props);
  return (
    <div
      onClick={(ev) => {
        ev.preventDefault();
        if (props.muestra !== "LN") {
          props.EditaEstudianteHandler(props.estudiante);
        }
      }}
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.muestra === "LN"
            ? "#fff"
            : props.estudiante.Adeudo === "0"
            ? "#fff"
            : "#c36770",
        cursor: props.muestra === "LN" ? "default" : "pointer",
      }}
    >
      <img
        ref={fotoRef}
        className={classes.fotoEstud}
        src={
          "https://www.copilco.net/expedientes/documentos/" +
          props.estudiante.IDEst +
          "_foto.jpg"
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src =
            "https://www.copilco.net/expedientes/documentos/default.jpg";
        }}
        alt={props.estudiante.Nombre}
      />
      <div
        style={{
          backgroundColor:
            props.muestra === "LN"
              ? "#fff"
              : VencioContrato(props.estudiante.VenceCont) < 0
              ? "#f30"
              : VencioContrato(props.estudiante.VenceCont) < 60
              ? "#FF0"
              : props.estudiante.Adeudo === "0"
              ? "#fff"
              : "#c36770",
        }}
      >
        {props.muestra !== "LN" ? (
          VencioContrato(props.estudiante.VenceCont) < 0 ? (
            <label className={classes.contratovenc}>CONTRATO VENCIDO</label>
          ) : VencioContrato(props.estudiante.VenceCont) < 60 ? (
            <label className={classes.contratovenc60}>
              CONTR VENCE EN {VencioContrato(props.estudiante.VenceCont)} DÍAS
            </label>
          ) : null
        ) : null}
        <label className={classes.nombre}>{props.estudiante.Nombre}</label>
        {props.muestra !== "LN" ? (
          props.estudiante.Adeudo !== "0" ? (
            <label className={classes.otros}>
              Hab {HabitacionDeIDHab(props.estudiante.IDHab)} (
              {RentaDeIDHab(props.estudiante.IDHab)})
            </label>
          ) : (
            <label className={classes.otros}>
              Habitacion {HabitacionDeIDHab(props.estudiante.IDHab)}
            </label>
          )
        ) : (
          <label>{props.estudiante.Observaciones}</label>
        )}
        {props.muestra !== "LN" ? (
          props.estudiante.Adeudo !== "0" ? (
            <label className={classes.otros}>
              {MontoAdeuda()}
              <button
                id={props.estudiante.IDEst}
                onClick={(ev) => {
                  ev.stopPropagation();
                  MostrarAdeudo(ev);
                }}
                className={classes.btnAdeuda}
              >
                <i className="fas fa-ellipsis-h"></i>
              </button>
            </label>
          ) : (
            <label className={classes.otros}>
              {RentaDeIDHab(props.estudiante.IDHab)}
            </label>
          )
        ) : null}

        {props.muestra === "LN" ? null : (
          <label className={classes.otros}>
            {props.estudiante.TelMovil}
            {props.nivel === 1 ? (
              <button
                id={props.estudiante.TelMovil}
                onClick={(ev) => {
                  ev.stopPropagation();
                  EnviaWhats(ev);
                }}
                className={classes.btnAdeuda}
              >
                <i className="fab fa-whatsapp"></i>
              </button>
            ) : null}
          </label>
        )}
        {props.muestra === "LN" ? null : (
          <label className={classes.otros}>
            Día Corte: {props.estudiante.DiaCorte} de c/mes
          </label>
        )}
        {props.muestra === "LN" ? null : (
          <div className={classes.divBotones}>
            {props.nivel === 1 ? (
              <button
                id={props.estudiante.IDEst}
                onClick={(ev) => {
                  ev.stopPropagation();
                  props.AgregaPagoHandler(props.estudiante);
                }}
                className={classes.innbtn}
              >
                <i className="fas fa-dollar-sign"></i>
              </button>
            ) : null}
            <button
              onClick={(ev) => {
                ev.stopPropagation();
                props.AgregaDescuentoHandler(props.estudiante);
              }}
              id={props.estudiante.IDEst}
              className={classes.innbtn}
            >
              <i className="fas fa-calendar-plus"></i>
            </button>
            {
              //se puso -1 para evitar que se viera en lo que decido si queda bien el cambio o no
              //se puso un boton dentro de la ficha para el borrado
              props.nivel === 1 ? (
                <button
                  onClick={(ev) => {
                    ev.stopPropagation();
                    props.EliminaEstudianteHandler(props.estudiante);
                  }}
                  id={props.estudiante.IDEst}
                  className={classes.innbtn}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              ) : null
            }
            <button
              onClick={(ev) => {
                ev.stopPropagation();
                subMenuClickHandler(ev);
              }}
              id={props.estudiante.IDEst}
              className={classes.innbtn}
            >
              <i className="fas fa-ellipsis-h"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EstudianteItem;
