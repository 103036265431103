/********************** ENVIOS *****************************************************/

export const EnviaDatosAServicio = async (ObjN, accionR) => {
  try {
    const response = await fetch(
      "https://www.copilco.net/inc/funciones.React.php",
      {
        method: "POST",
        body: JSON.stringify({ accion: accionR, objt: ObjN }),
        headers: {
          "content-type": "application/json",
        },
      }
    );
    const json = await response.json();
    //console.log("json", json);
    //const json = await response.json();
    return json;
  } catch (error) {
    console.error("error GP ", error);
    const ERR = {
      ERROR: "SI",
      DESCERROR: "Respuesta no entendida",
    };
    return ERR;
  }
};

export const EnviaFotoAServicio = async (foto, nombreFoto, accion) => {
  try {
    //accion ="cargaFotoDocumentos" para las fotos de estudiantes
    //var formData = new FormData();
    //formData.append("image", foto, nombreFoto);
    //formData.set("accion", accion);
    //formData.set("NombreFoto", nombreFoto);
    const response = await fetch(
      "https://www.copilco.net/inc/funciones.React.php",
      {
        method: "POST",
        body: JSON.stringify({ img: foto, nombreFoto, accion: "CargaFoto" }),
        //body: formData,
        headers: {
          "content-type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("error GP ", error);
    const ERR = {
      ERROR: "SI",
      DESCERROR: "Respuesta no entendida",
    };
    return ERR;
  }
};

export const EnviaArchivoAServicio = async (foto, nombreFoto, accion) => {
  try {
    //accion ="cargaFotoDocumentos" para las fotos de estudiantes
    var formData = new FormData();
    formData.append("image", foto, nombreFoto);
    formData.set("accion", accion);
    formData.set("NombreFoto", nombreFoto);
    const response = await fetch(
      "https://www.copilco.net/inc/funciones.React.php",
      {
        method: "POST",
        //body: JSON.stringify({img: foto, IDEst: idEst, accion: "CargaFotoEstudiante"}),
        body: formData,
        //headers: {
        //  "content-type": "application/json",
        //},
      }
    );
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("error GP ", error);
    const ERR = {
      ERROR: "SI",
      DESCERROR: "Respuesta no entendida",
    };
    return ERR;
  }
};

export const EnviaDatosANuevoServicio = async (
  accion,
  Datos,
  setData,
  setIsLoading,
  image,
  idUsu
) => {
  try {
    var formData = new FormData();
    formData.set("objt", JSON.stringify(Datos));
    formData.set("accion", accion);
    formData.set("usuario", idUsu);
    if (image !== undefined && image !== null) {
      //console.log(image);
      formData.append("image", image, "nombreFoto");
    }
    //console.log(formData);
    await fetch("https://www.copilco.net/inc/servicioNuevo.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        //console.log("response",response);
        return response.json();
      })
      .then((data) => {
        //console.log("data", data);
        setData(data);
        setIsLoading(false);
      });
    //const json = await response.json();
    //return json;
  } catch (error) {
    console.error("error EnviaDatosANuevoServicio ", error);
  }
};

/********************** FECHAS *****************************************************/

export const FechaDeString = (fechaStr) => {
  var respuesta = fechaStr.slice(4, 6) + "/" + fechaStr.slice(0, 4);
  if (fechaStr.length === 8) {
    respuesta = fechaStr.slice(6, 8) + "/" + respuesta;
  }
  return respuesta;
};

export const DateParaInputDate = (fecha) => {
  if (fecha === undefined) fecha = new Date();
  var day = ("0" + fecha.getDate()).slice(-2);
  var month = ("0" + (fecha.getMonth() + 1)).slice(-2);
  var today = fecha.getFullYear() + "-" + month + "-" + day;
  //console.log("today", today);
  return today;
};

export const FechaParaInputDate = (fechaStr) => {
  if (fechaStr === undefined) {
    const fechaD = new Date();
    var day = ("0" + fechaD.getDate()).slice(-2);
    var month = ("0" + (fechaD.getMonth() + 1)).slice(-2);
    fechaStr = fechaD.getFullYear() + month + day;
  }
  var respuesta = fechaStr.slice(0, 4) + "-" + fechaStr.slice(4, 6);
  if (fechaStr.length === 8) {
    respuesta = respuesta + "-" + fechaStr.slice(6, 8);
  }
  //console.log("FechaParaInputDate", fechaStr, respuesta);
  return respuesta;
};

export const FechaMMMDeDate = (fecha) => {
  if (fecha === undefined) fecha = new Date();
  var day = ("0" + fecha.getDate()).slice(-2);
  var month = ("0" + (fecha.getMonth() + 1)).slice(-2);
  var today = day + " " + NumeroAMes(month) + " " + fecha.getFullYear();
  //console.log("today", fecha, today);
  return today;
};

export const FechaMMMDeString = (fechaStr) => {
  if (fechaStr !== "error") {
    //console.log("fechaStr", fechaStr);
    var respuesta =
      NumeroAMes(fechaStr.slice(4, 6)) + " " + fechaStr.slice(0, 4);
    //console.log("FechaMMMDeString1", respuesta);
    if (fechaStr.length === 8) {
      respuesta = fechaStr.slice(6, 8) + " " + respuesta;
    }
    //console.log("FechaMMMDeString2", respuesta);
    return respuesta;
  } else if (fechaStr === "error") {
    return "N/A";
  } else {
    console.log("error FechaMMMDeString fechaStr undefined");
  }
};

export const YYYYMMDDdeDate = (fecha) => {
  //console.log("Date", fecha);
  if (fecha) {
    var respuesta = fecha.getFullYear().toString();
    respuesta =
      respuesta +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    respuesta =
      respuesta +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    return respuesta;
  }

  return "";
};

export const YYYYMMDeAñoMes = (añoMes) => {
  //llega como JUL 2021 tiene que salir como 202107
  var respuesta = añoMes.toString().substr(4, 4); //respuesta = 2021
  var mes = añoMes.toString().substr(0, 3).toUpperCase(); //mes = JUL
  if (mes === "ENE") respuesta = respuesta + "01";
  else if (mes === "FEB") respuesta = respuesta + "02";
  else if (mes === "MAR") respuesta = respuesta + "03";
  else if (mes === "ABR") respuesta = respuesta + "04";
  else if (mes === "MAY") respuesta = respuesta + "05";
  else if (mes === "JUN") respuesta = respuesta + "06";
  else if (mes === "JUL") respuesta = respuesta + "07";
  else if (mes === "AGO") respuesta = respuesta + "08";
  else if (mes === "SEP") respuesta = respuesta + "09";
  else if (mes === "OCT") respuesta = respuesta + "10";
  else if (mes === "NOV") respuesta = respuesta + "11";
  else if (mes === "DIC") respuesta = respuesta + "12";
  else respuesta = "error";
  return respuesta;
};

/********************** FORMATO *****************************************************/

export var FormateaCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export var FormateaPorcentaje = new Intl.NumberFormat("en-US", {
  style: "percent",
});

export var Formatea1Decimal = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 1,
});

export const FuncionValidaDatos = (event, tipoValidacion) => {
  if (event.target.selectionEnd > event.target.selectionStart) {
    event.target.value =
      event.target.value.slice(0, event.target.selectionStart) +
      event.target.value.slice(event.target.selectionEnd);
  }
  var key = "";
  if (event.type === "paste") {
    key = event.clipboardData.getData("text/plain");
  } else if (event.type === "keypress") {
    key = event.key;
  } else {
    key = event;
  }
  var regex;
  if (tipoValidacion === "numeros") {
    regex = /[0-9]/;
  } else if (tipoValidacion === "currency") {
    //regex = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
    regex = /[0-9]|,|\.|\$/;
  } else if (tipoValidacion === "porcentaje") {
    //regex = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
    regex = /[0-9]|\.|%/;
  } else if (tipoValidacion === "decimales") {
    //regex = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
    regex = /[0-9]|\./;
  } else {
    regex = /.*/;
  }
  if (!regex.test(key)) {
    event.returnValue = false;
    if (event.preventDefault) event.preventDefault();
  } else {
    if (tipoValidacion === "decimales") {
      if (key === ".") {
        if (event.target.value.indexOf(".") >= 0) {
          event.returnValue = false;
          if (event.preventDefault) event.preventDefault();
        }
      }
    }
    if (tipoValidacion === "currency") {
      if (key === "$" && event.target.value !== "") {
        event.returnValue = false;
        if (event.preventDefault) event.preventDefault();
      } else if (key === ",") {
        if (
          !(event.target.value + key)
            .slice(-5)
            .match(/^(\$|,?)(?:[0-9]{1,3},)?$/)
        ) {
          event.returnValue = false;
          if (event.preventDefault) event.preventDefault();
        }
      } else if (key === ".") {
        if (!(event.target.value + key).slice(-2).match(/^(?:[0-9]{1}\.)?$/)) {
          event.returnValue = false;
          if (event.preventDefault) event.preventDefault();
        }
      } else {
        //aqui solo quedan numeros. verificar que si hay un punto dos caracteres antes, ya no permitir
        const ct = event.target.value.slice(-3);
        if (ct.slice(0, 1) === ".") {
          event.returnValue = false;
          if (event.preventDefault) event.preventDefault();
        }
      }
    }
  }
};

export const DejaSoloNumerosDeCurrency = (curr) => {
  var respuesta = curr;
  while (respuesta.toString().indexOf("$") >= 0) {
    respuesta = respuesta.toString().replace("$", "", "gi");
  }
  while (respuesta.toString().indexOf(",") >= 0) {
    respuesta = respuesta.toString().replace(",", "", "gi");
  }
  return respuesta;
};

/********************** OTROS *****************************************************/

export const NumeroAMes = (num) => {
  const numero = parseInt(num);
  if (numero === 1) {
    return "ene";
  } else if (numero === 2) {
    return "feb";
  } else if (numero === 3) {
    return "mar";
  } else if (numero === 4) {
    return "abr";
  } else if (numero === 5) {
    return "may";
  } else if (numero === 6) {
    return "jun";
  } else if (numero === 7) {
    return "jul";
  } else if (numero === 8) {
    return "ago";
  } else if (numero === 9) {
    return "sep";
  } else if (numero === 10) {
    return "oct";
  } else if (numero === 11) {
    return "nov";
  } else if (numero === 12) {
    return "dic";
  } else {
    return "err";
  }
};

export const NumeroALetras = (numSolicitado) => {
  // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
  function Unidades(num) {
    switch (num) {
      case 1:
        return "UN";
      case 2:
        return "DOS";
      case 3:
        return "TRES";
      case 4:
        return "CUATRO";
      case 5:
        return "CINCO";
      case 6:
        return "SEIS";
      case 7:
        return "SIETE";
      case 8:
        return "OCHO";
      case 9:
        return "NUEVE";
      default:
        return "";
    }
  } //Unidades()

  function Decenas(num) {
    let decena = Math.floor(num / 10);
    let unidad = num - decena * 10;

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return "DIEZ";
          case 1:
            return "ONCE";
          case 2:
            return "DOCE";
          case 3:
            return "TRECE";
          case 4:
            return "CATORCE";
          case 5:
            return "QUINCE";
          default:
            return "DIECI" + Unidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0:
            return "VEINTE";
          default:
            return "VEINTI" + Unidades(unidad);
        }
      case 3:
        return DecenasY("TREINTA", unidad);
      case 4:
        return DecenasY("CUARENTA", unidad);
      case 5:
        return DecenasY("CINCUENTA", unidad);
      case 6:
        return DecenasY("SESENTA", unidad);
      case 7:
        return DecenasY("SETENTA", unidad);
      case 8:
        return DecenasY("OCHENTA", unidad);
      case 9:
        return DecenasY("NOVENTA", unidad);
      case 0:
        return Unidades(unidad);
      default:
        return Unidades(unidad);
    }
  } //Unidades()

  function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0) return strSin + " Y " + Unidades(numUnidades);

    return strSin;
  } //DecenasY()

  function Centenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - centenas * 100;

    switch (centenas) {
      case 1:
        if (decenas > 0) return "CIENTO " + Decenas(decenas);
        return "CIEN";
      case 2:
        return "DOSCIENTOS " + Decenas(decenas);
      case 3:
        return "TRESCIENTOS " + Decenas(decenas);
      case 4:
        return "CUATROCIENTOS " + Decenas(decenas);
      case 5:
        return "QUINIENTOS " + Decenas(decenas);
      case 6:
        return "SEISCIENTOS " + Decenas(decenas);
      case 7:
        return "SETECIENTOS " + Decenas(decenas);
      case 8:
        return "OCHOCIENTOS " + Decenas(decenas);
      case 9:
        return "NOVECIENTOS " + Decenas(decenas);
      default:
        return Decenas(decenas);
    }
  } //Centenas()

  function Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let letras = "";

    if (cientos > 0)
      if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
      else letras = strSingular;

    if (resto > 0) letras += "";

    return letras;
  } //Seccion()

  function Miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = Centenas(resto);

    if (strMiles === "") return strCentenas;

    return strMiles + " " + strCentenas;
  } //Miles()

  function Millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
    let strMiles = Miles(resto);

    if (strMillones === "") return strMiles;

    return strMillones + " " + strMiles;
  } //Millones()

  function NumeroALetras(num, currency) {
    currency = currency || {};
    let data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
      letrasCentavos: "",
      letrasMonedaPlural: currency.plural || "PESOS", //'PESOS', 'Dólares', 'Bolívares', 'etcs'
      letrasMonedaSingular: currency.singular || "PESO", //'PESO', 'Dólar', 'Bolivar', 'etc'
      letrasMonedaCentavoPlural: currency.centPlural || "CENTAVOS",
      letrasMonedaCentavoSingular: currency.centSingular || "CENTAVO",
    };

    if (data.centavos > 0) {
      data.letrasCentavos =
        "CON " +
        (function () {
          if (data.centavos === 1)
            return (
              Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular
            );
          else
            return (
              Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural
            );
        })();
    } else {
      data.letrasCentavos = "00/100 MN";
    }

    if (data.enteros === 0)
      return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros === 1)
      return (
        Millones(data.enteros) +
        " " +
        data.letrasMonedaSingular +
        " " +
        data.letrasCentavos
      );
    else
      return (
        Millones(data.enteros) +
        " " +
        data.letrasMonedaPlural +
        " " +
        data.letrasCentavos
      );
  }
  const resultado = NumeroALetras(numSolicitado);
  return resultado;
};

export const DaFormatoAdeudos = (MesQPag) => {
  var respuesta = [];

  if (MesQPag.indexOf(",") >= 0) {
    var temp = MesQPag.split(",");
    //console.log("temp", temp);
    for (var i = 0; i < temp.length; i = i + 1) {
      if (temp[i].indexOf("(") >= 0) {
        temp[i] = MesAMesYDebe(temp[i]);
      }
      respuesta.push(temp[i]);
    }
  } else {
    respuesta.push(MesAMesYDebe(MesQPag));
  }

  return respuesta;
};

function MesAMesYDebe(MesYCant) {
  var Temp = MesYCant.substring(0, MesYCant.indexOf("(")).trim();
  var Fecha =
    NumeroAMes(Temp.substring(4, 6)) + " " + parseInt(Temp.substring(0, 4));
  var debe = MesYCant.substring(MesYCant.indexOf("(") + 1);
  debe = debe.substring(0, debe.indexOf(")"));

  if (debe > 0) debe = FormateaCurrency.format(debe);
  else debe = "$0.00";
  return Fecha + " (" + debe + ")";
}

export const NumeroAMoneda = (value) =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(value);
