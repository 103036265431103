import { useEffect, useState } from "react";
import classes from "./EstudianteListaNegra.module.css";
import { EnviaDatosANuevoServicio } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import Backdrop from "../layout/backdrop";
import EstudianteNuevoListaNegra from "./EstudianteNuevoLN";
import OKModal from "../dialogos/dialogoOK";
import EstudianteItem from "./EstudianteItem";

function ListaNegra(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [listaNegraLoaded, setListaNegraLoaded] = useState([]);
  const [listaBajaLoaded, setListaBajaLoaded] = useState([]);
  const [muestraNuevoBloqueado, setMuestraNuevoBloqueado] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const token = useToken().token;
  const idUsu = token.Id;
  const idCasa = token.casaSel;
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });

  function OrdenaRecurrentes() {}

  function FiltraRecurrentes() {}

  function oculta() {
    setMuestraDialogoOK(false);
  }
  function AgregaBloqueado() {
    setMuestraNuevoBloqueado(true);
  }

  function RespServALN(resp) {
    //console.log(resp);
    if (resp.Error === "") {
      setMuestraNuevoBloqueado(false);
      setRecargar(true);
    } else {
      setDialogoOK({
        mostrandoError: true,
        texto: resp.DESCERROR,
        recargar: false,
      });
      setMuestraDialogoOK(true);
    }
  }

  function AgregaAListaNegra(estudiante) {
    const datos = {
      IDEst: estudiante,
    };
    EnviaDatosANuevoServicio(
      "AgregaLN",
      datos,
      RespServALN,
      setIsLoading,
      null,
      idUsu
    );
  }

  function Cierra() {
    props.setMuestraListaNegra(false);
  }

  function respServ(resp) {
    //console.log(resp);
    if (resp.Error === "") {
      setListaNegraLoaded(resp.ListaNegra);
      setListaBajaLoaded(resp.Baja);
    } else {
      console.log(resp.DESCERROR);
    }
  }

  useEffect(() => {
    setRecargar(false);
    setIsLoading(true);
    const datos = {
      IDUsu: idUsu,
      IDCasa: idCasa,
    };
    //console.log(datos);
    EnviaDatosANuevoServicio(
      "ListaNegra",
      datos,
      respServ,
      setIsLoading,
      null,
      idUsu
    );
  }, [idUsu, idCasa, recargar]);

  /***************************************************************************/
  /************************* RENDER ******************************************/
  /***************************************************************************/
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  console.log(listaNegraLoaded);
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        <button className={classes.botCancela} onClick={Cierra}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1>Lista Negra</h1>
      <section>
        <div className={classes.TituloBoton}>
          {listaNegraLoaded.length === 0 ? (
            <h1 className="TitEst">No hay nadie en lista negra</h1>
          ) : null}
          <div className="contBotones">
            {listaNegraLoaded.length > 0 ? (
              <button
                className={classes.botAgregaEst1}
                onClick={OrdenaRecurrentes}
              >
                <i className="fas fa-sort-amount-up-alt"></i>
              </button>
            ) : null}
            {listaNegraLoaded.length > 0 ? (
              <button
                className={classes.botAgregaEst1}
                onClick={FiltraRecurrentes}
              >
                <i className="fas fa-filter"></i>
              </button>
            ) : null}
            {props.nivel === 1 ? (
              <button
                className={classes.botAgregaEst1}
                onClick={AgregaBloqueado}
              >
                <i className="fas fa-plus-square"></i>
              </button>
            ) : null}
          </div>
        </div>
        <div className={classes.list}>
          {listaNegraLoaded.map((estudiante) => (
            <EstudianteItem
              setRecargaFoto={props.setRecargaFoto}
              estudiante={estudiante}
              muestra={"LN"}
            />
          ))}
        </div>
        {muestraNuevoBloqueado && (
          <EstudianteNuevoListaNegra
            estudiantes={listaBajaLoaded}
            nivel={props.nivel}
            muestra={"LN"}
            setMuestraNuevoBloqueado={setMuestraNuevoBloqueado}
            AgregaAListaNegra={AgregaAListaNegra}
          />
        )}
        {muestraNuevoBloqueado && <Backdrop zindex={10} />}
        {muestraDialogoOK && (
          <OKModal texto={dialogoOK.texto} oculta={oculta} />
        )}
        {muestraDialogoOK && <Backdrop zindex={49} />}
      </section>
    </section>
  );
}

export default ListaNegra;
