import ItemResumen from "./ResumenItem";
import classes from "./ResumenesLista.module.css";
import ItemResumenFormaPago from "./ResumenItemFormaPago";

function ListaResumenes(props) {
  //console.log("props", props);
  return (
    <ul className={classes.list}>
      {props.flujos.length === 0 ? (
        <h1>No hay flujos que mostrar</h1>
      ) : (
        props.flujos.map((flujo, index) => {
          if (props.fechas) {
            var titulo = "Balance";
            if (props.tipo !== "balance") {
              if (index === 0) {
                titulo = "Ingresos";
              } else {
                titulo = "Egresos";
              }
            }
            return (
              <ItemResumenFormaPago
                key={index}
                flujo={flujo}
                tipo={titulo}
                muestraDetalle={props.muestraDetalle}
                setMuestraResumenDepGar={props.setMuestraResumenDepGar}
              />
            );
          } else {
            return (
              <ItemResumen
                key={index}
                flujo={flujo}
                muestraDetalle={props.muestraDetalle}
                setMuestraResumenDepGar={props.setMuestraResumenDepGar}
              />
            );
          }
        })
      )}
    </ul>
  );
}

export default ListaResumenes;
