import classes from "./PendienteNuevo.module.css";
import useToken from "../login/useToken";
import {
  DateParaInputDate,
  EnviaDatosANuevoServicio,
} from "../../Funciones/Funciones";
import { useRef, useState } from "react";
import { Slide, toast } from "react-toastify";

function NuevoPendiente(props) {
  const descripcionRef = useRef();
  const fechaAsignacionRef = useRef();
  const materialesRef = useRef();
  const tipoInputRef = useRef();
  const observacionesRef = useRef();
  const token = useToken().token;
  const idCasa = token.casaSel;
  const idAsigno = token.Id;
  const [isLoading, setIsLoading] = useState(false);

  function CancelaNuevo() {
    props.setMostrarNuevo(false);
  }

  function respServ(resp) {
    console.log(resp);
    if (resp.Error === "SI") {
      props.setDialogoOK({
        texto: "Error: " + resp.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    } else {
      toast("Guardado OK", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      props.setMostrarNuevo(false);
      props.setRecarga(true);
    }
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    console.log("TIPO", props.pendiente.Tipo);
    var datosOK = "";
    if (descripcionRef.current.value === "") {
      datosOK = "Descripcion del pendiente\n";
      //alert("Por lo menos debe haber una descripcion del pendiente");
    }
    if (
      props.pendiente.Tipo === undefined &&
      tipoInputRef.current.value === "0"
    ) {
      datosOK = datosOK + "Tipo de pendiente\n";
    }
    if (datosOK !== "") {
      datosOK = "Error en los siguientes textos:\n" + datosOK;
      props.setDialogoOK({
        texto: datosOK,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    } else {
      var TipoP = props.pendiente.Tipo;
      if (
        TipoP === undefined &&
        (tipoInputRef.current.value === "1" ||
          tipoInputRef.current.value === "2" ||
          tipoInputRef.current.value === "3")
      ) {
        TipoP = tipoInputRef.current.value;
      }
      const pendienteN = {
        IDPend: "",
        IDCasa: idCasa,
        IDAsigno: idAsigno,
        Orden: props.pendiente.Orden,
        FechaAsigno: fechaAsignacionRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Descripcion: descripcionRef.current.value,
        Materiales: materialesRef.current.value,
        Observaciones: observacionesRef.current.value,
        Estatus: "Asignado",
        TipoPend: TipoP,
      };
      console.log("pendienteN", pendienteN);

      EnviaDatosANuevoServicio(
        "AgregaPendiente",
        pendienteN,
        respServ,
        setIsLoading,
        null,
        idAsigno
      );
      /*
      const envio = await EnviaDatosAServicio(pendienteN, "AgregaPendiente");
      if (envio.ERROR === "") {
        props.setDialogoOK({
          texto: "Guardado OK",
          mostrandoError: false,
          recargar: true,
          cerrarVentana: "mostrarNuevo",
        });
        props.setMuestraDialogoOK(true);
        //alert("Guardado OK");
        //props.setMostrarNuevo(false);
      } else {
        props.setDialogoOK({
          texto: "Error al guardar: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("Error al guardar: " + envio.DESCERROR);
      }*/
    }
  };
  console.log(props.pendiente.Tipo);
  /********************************************************************************/
  /******************************** RENDER ****************************************/
  /********************************************************************************/
  if (isLoading) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      {props.pendiente.Tipo === "1" ? (
        <h1 className={classes.h1loc}>Nueva Prioridad</h1>
      ) : (
        <h1 className={classes.h1loc}>Nuevo {props.pendiente.DescTipo}</h1>
      )}
      <form className={classes.forma}>
        <div>
          <div>
            <textarea
              required
              ref={descripcionRef}
              className={classes.textareaDesc}
            ></textarea>
          </div>
          {props.pendiente.Tipo === undefined ? (
            <div className={classes.renglonpendiente}>
              <label className={classes.labelP}>Tipo</label>
              <select
                id="tipo"
                ref={tipoInputRef}
                className={classes.textareaNE}
              >
                <option key="0" value={"0"}>
                  Tipo de pendiente
                </option>
                <option key="1" value={"1"}>
                  Prioridad
                </option>
                <option key="2" value={"2"}>
                  Pendiente
                </option>
                <option key="3" value={"3"}>
                  Administración
                </option>
              </select>
            </div>
          ) : null}
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Fecha Asignación</label>
            <input
              required
              ref={fechaAsignacionRef}
              type="date"
              defaultValue={DateParaInputDate(new Date())}
            ></input>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Materiales</label>
            <textarea
              ref={materialesRef}
              className={classes.textareaP}
            ></textarea>
          </div>
          <div className={classes.renglonpendiente}>
            <label className={classes.labelP}>Observaciones</label>
            <textarea
              ref={observacionesRef}
              className={classes.textareaP}
            ></textarea>
          </div>
        </div>
      </form>
    </section>
  );
}

export default NuevoPendiente;
