import { addMonths } from "date-fns";
import {
  FechaMMMDeString,
  FormateaCurrency,
  NumeroALetras,
  EnviaDatosAServicio,
} from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import classes from "./ReciboPago.module.css";
import { Slide, toast } from "react-toastify";

function AgregaMes(fechaStr) {
  //console.log("fecha origen", fechaStr)
  var Fecha = new Date(
    parseInt(fechaStr.slice(0, 4)),
    parseInt(fechaStr.slice(4, 6)) - 1,
    parseInt(fechaStr.slice(6, 8))
  );
  //console.log("fecha creada", Fecha)
  Fecha = addMonths(Fecha, 1);
  //console.log("fecha creada mas un mes", Fecha)
  const mes = Fecha.getMonth() + 1;
  const temp =
    Fecha.getFullYear().toString() +
    (mes.toString().length === 1 ? "0" + mes : mes) +
    (Fecha.getDate().toString().length === 1
      ? "0" + Fecha.getDate()
      : Fecha.getDate());
  //console.log("temp", temp);
  return temp;
}

function ReciboPago(props) {
  const token = useToken().token;

  const EnviaRecibo = async (ev) => {
    ev.preventDefault();
    props.datosRecibo.usuario = token.nombre;
    const envio = await EnviaDatosAServicio(props.datosRecibo, "enviaRecibo");
    if (envio.ERROR === "") {
      toast("Recibo enviado", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        transition: Slide,
      });
      /*props.setDialogoOK({
        texto: "enviado OK",
        mostrandoError: false,
        recargar: true,
        cerrarVentana: "pagoNuevo",
      });
      props.setMuestraDialogoOK(true);*/
      props.TerminaRecibo();
      //props.setRecargar(true);
      //alert("enviado OK");
    } else {
      props.setDialogoOK({
        texto: "ERROR al enviar: " + envio.DESCERROR,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
      //alert("Error al enviar: " + envio.DESCERROR);
    }
  };

  return (
    <section className={classes.modal}>
      <div className={classes.marco}>
        <div className={classes.contNumRecibo}>
          <label>Recibo {props.datosRecibo.IDIng}</label>
          <label>{FormateaCurrency.format(props.datosRecibo.Monto)}</label>
        </div>
        <div className={classes.contFechaEmision}>
          <label>CDMX a {FechaMMMDeString(props.datosRecibo.FechaPag)}</label>
        </div>
        <div className={classes.contTextoNombre}>
          <label>
            Recibí de{" "}
            <span className={classes.nombre}> {props.datosRecibo.Nombre}</span>
          </label>
        </div>
        <div className={classes.contTextoCantidad}>
          <label>
            La cantidad de{" "}
            <span className={classes.textoCantidad}>
              {"(" + NumeroALetras(props.datosRecibo.Monto) + ")"}
            </span>
          </label>
        </div>
        <div className={classes.contConcepto}>
          <label>
            Por concepto de{" "}
            <span className={classes.textConcepto}>
              {props.datosRecibo.Concepto +
                (props.datosRecibo.Habitacion
                  ? " habitación " + props.datosRecibo.Habitacion
                  : "")}
            </span>
          </label>
        </div>
        {props.datosRecibo.Concepto === "Renta" && (
          <div className={classes.contPeriodo}>
            <label>
              periodo del{" "}
              <span className={classes.textoPeriodo}>
                {FechaMMMDeString(
                  props.datosRecibo.MesQPag +
                    (props.datosRecibo.DiaCorte.length === 1
                      ? "0" + props.datosRecibo.DiaCorte
                      : props.datosRecibo.DiaCorte)
                )}
              </span>
              al
              <span className={classes.textoPeriodo}>
                {FechaMMMDeString(
                  AgregaMes(
                    props.datosRecibo.MesQPag +
                      (props.datosRecibo.DiaCorte.length === 1
                        ? "0" + props.datosRecibo.DiaCorte
                        : props.datosRecibo.DiaCorte)
                  )
                )}
              </span>
            </label>
          </div>
        )}
      </div>
      <div className={classes.divBotones}>
        <button className={classes.botones} onClick={props.TerminaRecibo}>
          <i className="fas fa-check"></i>
        </button>
        <button
          className={classes.botones}
          onClick={(ev) => {
            EnviaRecibo(ev);
          }}
        >
          <i className="fas fa-envelope"></i>
        </button>
      </div>
    </section>
  );
}

export default ReciboPago;
