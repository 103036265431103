import { FechaMMMDeString, FormateaCurrency } from "../../Funciones/Funciones";
import classes from "./DepGarItem.module.css";

function DepGarItem(props) {
  function QuitaAmpers(observ) {
    if (observ) {
      while (observ.indexOf("&&&") >= 0) {
        observ = observ.substr(observ.indexOf("&&&") + 3);
      }

      return observ;
    }
    return "";
  }

  return (
    <div
      className={classes.back}
      id={"fondoItem"}
      style={{
        backgroundColor:
          props.ingreso.Concepto === "Renta"
            ? "#fff"
            : props.ingreso.Concepto === "Deposito en garantia"
            ? "#4af"
            : "#1fc",
      }}
    >
      <label className={classes.nombre}>{props.ingreso.Nombre}</label>
      <label className={classes.otros}>
        Fecha pago: {FechaMMMDeString(props.ingreso.FechaPag)}
      </label>
      <label className={classes.otros}>
        Forma pago: {props.ingreso.FormaPago}
      </label>
      <label className={classes.otros}>
        Monto: {FormateaCurrency.format(props.ingreso.Monto)}
      </label>
      <label className={classes.otros}>
        {QuitaAmpers(props.ingreso.Observaciones)}
      </label>
    </div>
  );
}

export default DepGarItem;
